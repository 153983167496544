import config from '@/utils/config'
const { glesRoot = 'gles-server' } = config
import _ from 'lodash'
// gles/base/workshop/listWorkshopByPage?currentPage=2&pageSize=3
/**
 * 基础数据：工厂、车间、仓库、区域、库区、产线、设备、库位、货位相关接口
 */
export const apis = {
  // 自动编码生成
  getSerialBizCode: {
    method: 'post',
    url: `${glesRoot}/base/factory/serialBizCode`,
    describe: '自动生成业务编码',
    isNeedLoading: false
  },
  // 查询字典表
  queryDictByCode: {
    method: 'post',
    url: `${glesRoot}/base/baseDict/queryDictByCode`,
    describe: '获取数据字典',
    isNeedLoading: false
  },
  getDictList: {
    method: 'post',
    url: `${glesRoot}/base/baseDict/queryDictPage`,
    describe: '数据字典列表',
    isNeedLoading: false
  },
  queryDictDetailByDictCode: {
    method: 'post',
    url: `${glesRoot}/base/baseDictDetail/queryDictDetailByDictCode`,
    describe: '数据字典明细',
    isNeedLoading: false
  },
  addDictDetail: {
    method: 'post',
    url: `${glesRoot}/base/baseDictDetail/addDictDetail`,
    describe: '添加数据字典明细',
    isNeedLoading: false
  },
  changeDetailStatus: {
    method: 'post',
    url: `${glesRoot}/base/baseDictDetail/changeDetailStatus`,
    describe: '明细禁用',
    isNeedLoading: false
  },
  querySystemConfigByCodeOrNamePage: {
    method: 'post',
    url: `${glesRoot}/base/systemConfig/querySystemConfigByCodeOrNamePage`,
    describe: '参数列表',
    isNeedLoading: false
  },
  addSystemConfig: {
    method: 'post',
    url: `${glesRoot}/base/systemConfig/addSystemConfig`,
    describe: '添加系统参数',
    isNeedLoading: false
  },
  updateSystemConfig: {
    method: 'post',
    url: `${glesRoot}/base/systemConfig/updateSystemConfig`,
    describe: '更新系统参数',
    isNeedLoading: false
  },
  // 工厂 增删改查
  getFactoryList: {
    method: 'post',
    url: `${glesRoot}/base/factory/list/page`,
    describe: '查询工厂列表',
    isNeedLoading: true
  },
  insertFactory: {
    method: 'post',
    url: `${glesRoot}/base/factory/addFactory`,
    describe: '新增工厂',
    isNeedLoading: true
  },
  deleteFactory: {
    method: 'post',
    url: `${glesRoot}/base/factory/deleteFactory`,
    describe: '删除工厂',
    isNeedLoading: true
  },
  updateFactory: {
    method: 'post',
    url: `${glesRoot}/base/factory/updateFactory`,
    describe: '编辑工厂',
    isNeedLoading: true
  },
  getFactoryDictList: {
    method: 'post',
    url: `${glesRoot}/base/factory/listAllFactory`,
    describe: '查询所属工厂下拉列表',
    isNeedLoading: true
  },
  // 车间 增删改查
  getWorkshopList: {
    method: 'post',
    url: `${glesRoot}/base/workshop/listWorkshopByPage`,
    describe: '查询车间列表',
    isNeedLoading: true
  },
  getWorkshopListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/workshop/listWorkshopByCodeOrNamePage`,
    describe: '查询车间列表',
    isNeedLoading: true
  },
  insertWorkshop: {
    method: 'post',
    url: `${glesRoot}/base/workshop/insertWorkshop`,
    describe: '新增车间',
    isNeedLoading: true
  },
  deleteWorkshop: {
    method: 'post',
    url: `${glesRoot}/base/workshop/deleteWorkshop`,
    describe: '删除车间',
    isNeedLoading: true
  },
  updateWorkshop: {
    method: 'post',
    url: `${glesRoot}/base/workshop/updateWorkshop`,
    describe: '编辑车间',
    isNeedLoading: true
  },
  getWorkshopDictList: {
    method: 'post',
    url: `${glesRoot}/base/workshop/listWorkshopByParam`,
    describe: '查询所属车间下拉列表',
    isNeedLoading: true
  },
  // 仓库增删改查
  // gles/base/warehouse/listWarehouseByPage?currentPage=2&pageSize=3
  getWarehouseList: {
    method: 'post',
    url: `${glesRoot}/base/warehouse/listWarehouseByPage`,
    describe: '查询仓库列表',
    isNeedLoading: true
  },
  getWarehouseListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/warehouse/listWarehouseByCodeOrNamePage`,
    describe: '查询仓库列表',
    isNeedLoading: true
  },
  insertWarehouse: {
    method: 'post',
    url: `${glesRoot}/base/warehouse/insertWarehouse`,
    describe: '新增仓库',
    isNeedLoading: true
  },
  deleteWarehouse: {
    method: 'post',
    url: `${glesRoot}/base/warehouse/deleteWarehouse`,
    describe: '删除仓库',
    isNeedLoading: true
  },
  updateWarehouse: {
    method: 'post',
    url: `${glesRoot}/base/warehouse/updateWarehouse`,
    describe: '编辑仓库',
    isNeedLoading: true
  },
  getWarehouseDictList: {
    method: 'post',
    url: `${glesRoot}/base/warehouse/listWarehouseByParam`,
    describe: '查询所属仓库下拉列表',
    isNeedLoading: true
  },
  // 区域增删改查
  // base/area/listAreaByPage?currentPage=2&pageSize=3
  getAreaList: {
    method: 'post',
    url: `${glesRoot}/base/area/listAreaByPage`,
    describe: '查询区域列表',
    isNeedLoading: true
  },
  getAreaListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/area/listAreaByCodeOrNamePage`,
    describe: '查询区域列表',
    isNeedLoading: true
  },
  insertArea: {
    method: 'post',
    url: `${glesRoot}/base/area/insertArea`,
    describe: '新增区域',
    isNeedLoading: true
  },
  deleteArea: {
    method: 'post',
    url: `${glesRoot}/base/area/deleteArea`,
    describe: '删除区域',
    isNeedLoading: true
  },
  updateArea: {
    method: 'post',
    url: `${glesRoot}/base/area/updateArea`,
    describe: '编辑区域',
    isNeedLoading: true
  },
  getAreaDictList: {
    method: 'post',
    url: `${glesRoot}/base/area/listAreaByParam`,
    describe: '查询所属区域下拉列表',
    isNeedLoading: true
  },
  // 库区增删改查
  // warehouseArea/listWarehouseAreaByPage?currentPage=2&pageSize=3
  getWarehouseAreaList: {
    method: 'post',
    url: `${glesRoot}/base/warehouseArea/listWarehouseAreaByPage`,
    describe: '查询区域列表',
    isNeedLoading: true
  },
  getWarehouseAreaListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/warehouseArea/listWarehouseAreaByCodeOrNamePage`,
    describe: '查询区域列表',
    isNeedLoading: true
  },
  insertWarehouseArea: {
    method: 'post',
    url: `${glesRoot}/base/warehouseArea/insertWarehouseArea`,
    describe: '新增区域',
    isNeedLoading: true
  },
  deleteWarehouseArea: {
    method: 'post',
    url: `${glesRoot}/base/warehouseArea/deleteWarehouseArea`,
    describe: '删除区域',
    isNeedLoading: true
  },
  updateWarehouseArea: {
    method: 'post',
    url: `${glesRoot}/base/warehouseArea/updateWarehouseArea`,
    describe: '编辑区域',
    isNeedLoading: true
  },
  getWarehouseAreaDictList: {
    method: 'post',
    url: `${glesRoot}/base/warehouseArea/listWarehouseAreaByParam`,
    describe: '查询所属库区下拉列表',
    isNeedLoading: true
  },
  // 产线增删改查
  // productionLine/listProductionLineByPage?currentPage=2&pageSize=3
  // base/productionLine/listProductionLineByPage?currentPage=2&pageSize=3
  getProductionLineList: {
    method: 'post',
    url: `${glesRoot}/base/productionLine/listProductionLineByPage`,
    describe: '查询产线列表',
    isNeedLoading: true
  },
  getProductionLineListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/productionLine/listProductionLineByCodeOrNamePage`,
    describe: '查询产线列表',
    isNeedLoading: true
  },
  insertProductionLine: {
    method: 'post',
    url: `${glesRoot}/base/productionLine/insertProductionLine`,
    describe: '新增产线',
    isNeedLoading: true
  },
  deleteProductionLine: {
    method: 'post',
    url: `${glesRoot}/base/productionLine/deleteProductionLine`,
    describe: '删除产线',
    isNeedLoading: true
  },
  updateProductionLine: {
    method: 'post',
    url: `${glesRoot}/base/productionLine/updateProductionLine`,
    describe: '编辑产线',
    isNeedLoading: true
  },
  getProductionLineDictList: {
    method: 'post',
    url: `${glesRoot}/base/productionLine/listProductionLineByParam`,
    describe: '查询所属库区下拉列表',
    isNeedLoading: true
  },
  // 设备增删改查
  // productionLine/listProductionLineByPage?currentPage=2&pageSize=3
  getDeviceList: {
    method: 'post',
    url: `${glesRoot}/base/device/queryDeviceByPage`,
    describe: '查询设备列表',
    isNeedLoading: true
  },
  getDeviceListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/device/queryDeviceByCodeOrNamePage`,
    describe: '查询设备列表',
    isNeedLoading: true
  },
  getDeviceDictList: {
    method: 'post',
    url: `${glesRoot}/base/device/getCodeAndName`,
    describe: '查询设备下拉列表',
    isNeedLoading: true
  },
  queryDeviceDetail: {
    method: 'post',
    url: `${glesRoot}/base/device/queryDeviceDetail`,
    describe: '查询设备列表',
    isNeedLoading: true
  },
  insertDevice: {
    method: 'post',
    url: `${glesRoot}/base/device/insertBaseDevice`,
    describe: '新增设备',
    isNeedLoading: true
  },
  deleteDeviceAssert: {
    method: 'post',
    url: `${glesRoot}/base/device/deleteDeviceAssert`,
    describe: '判断是否可删除设备',
    isNeedLoading: true
  },
  deleteDevice: {
    method: 'post',
    url: `${glesRoot}/base/device/deleteDeviceById`,
    describe: '删除设备',
    isNeedLoading: true
  },
  updateDevice: {
    method: 'post',
    url: `${glesRoot}/base/device/updateBaseDevice`,
    describe: '编辑设备',
    isNeedLoading: true
  },
  // 货位增删改查
  // productionLine/listProductionLineByPage?currentPage=2&pageSize=3
  getGoodsPositionList: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/queryGoodsPositionByPage`,
    describe: '查询货位列表',
    isNeedLoading: true
  },
  getGoodsPositionListByCodeOrNamePage: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/queryGoodsPositionByCodeOrNamePage`,
    describe: '查询货位列表',
    isNeedLoading: true
  },
  insertGoodsPosition: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/addGoodsPosition`,
    describe: '新增货位',
    isNeedLoading: true
  },
  deleteGoodsPosition: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/deleteGoodsPositionById`,
    describe: '删除货位',
    isNeedLoading: true
  },
  deleteGoodsPositionByIds: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/deleteGoodsPositionByIds`,
    describe: '批量删除货位',
    isNeedLoading: true
  },
  updateGoodsPosition: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/updateGoodsPosition`,
    describe: '编辑货位',
    isNeedLoading: true
  },
  exportGoodsPosition: {
    method: 'get',
    url: `${glesRoot}/base/goods/position/exportGoodsPosition`,
    describe: '导出货位',
    isNeedLoading: true
  },
  getGoodsPositionListByType: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/getCodeAndType`,
    describe: '查询货位下拉列表根据类型',
    isNeedLoading: true
  },
  // getDeviceGoodsPositionList: {
  //   method: 'post',
  //   url: `${glesRoot}/base/goods/position/getFixGoodsPositionByWarehouseAreaId`,
  //   describe: '查询固定货位下拉列表根据库区',
  //   isNeedLoading: true
  // },
  getFixGoodsPositionByWarehouseAreaId: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/getFixGoodsPositionByWarehouseAreaId`,
    describe: '查询固定货位下拉列表根据库区',
    isNeedLoading: true
  },
  // 库位增删改查
  getFactoryPositionList: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/queryFactoryPositionByPage`,
    describe: '查询库位列表',
    isNeedLoading: true
  },
  getFactoryPositionDictList: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/queryFactoryPositionProperty`,
    describe: '查询库位列表',
    isNeedLoading: true
  },
  getNoUseFactoryPositionDictList: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/queryFactoryPositionPropertyNoUse`,
    describe: '查询库位列表',
    isNeedLoading: true
  },
  getFactoryPositionListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/queryFactoryPositionByCodeOrNamePage`,
    describe: '查询库位列表',
    isNeedLoading: true
  },
  insertFactoryPosition: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/addFactoryPosition`,
    describe: '新增库位',
    isNeedLoading: true
  },
  deleteFactoryPosition: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/deleteFactoryPositionById`,
    describe: '删除库位',
    isNeedLoading: true
  },
  updateFactoryPosition: {
    method: 'post',
    url: `${glesRoot}/base/factory/position/updateFactoryPosition`,
    describe: '编辑库位',
    isNeedLoading: true
  },
  exportFactoryPosition: {
    method: 'get',
    url: `${glesRoot}/base/factory/position/exportFactoryPosition`,
    describe: '导出库位',
    isNeedLoading: true
  },
  // 容器类型 增删改查
  // container/type/queryContainerTypeByPage?currentPage=1&pageSize=3
  getContainerTypeList: {
    method: 'post',
    url: `${glesRoot}/base/container/type/queryContainerTypeByPage`,
    describe: '查询容器列表',
    isNeedLoading: true
  },
  getContainerTypeListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/container/type/queryContainerTypeByCodeOrNamePage`,
    describe: '查询容器列表',
    isNeedLoading: true
  },
  getContainerTypeDictList: {
    method: 'post',
    url: `${glesRoot}/base/container/type/getCodeAndName`,
    describe: '查询容器列表',
    isNeedLoading: true
  },
  getContainerDictList: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/getCodeAndName`,
    describe: '查询所有容器编码',
    isNeedLoading: true
  },
  queryContainerTypeDetail: {
    method: 'post',
    url: `${glesRoot}/base/container/type/queryContainerTypeDetail `,
    describe: '查询容器列表',
    isNeedLoading: true
  },
  insertContainerType: {
    method: 'post',
    url: `${glesRoot}/base/container/type/addBaseContainerType`,
    describe: '新增容器',
    isNeedLoading: true
  },
  deleteContainerTypeAssert: {
    method: 'post',
    url: `${glesRoot}/base/container/type/deleteContainerTypeAssert`,
    describe: '删除容器',
    isNeedLoading: true
  },
  deleteContainerType: {
    method: 'post',
    url: `${glesRoot}/base/container/type/deleteContainerType`,
    describe: '删除容器',
    isNeedLoading: true
  },
  deleteContainerTypeById: {
    method: 'post',
    url: `${glesRoot}/base/container/type/deleteContainerTypeById`,
    describe: '删除容器',
    isNeedLoading: true
  },
  updateContainerType: {
    method: 'post',
    url: `${glesRoot}/base/container/type/updateBaseContainerType`,
    describe: '编辑容器',
    isNeedLoading: true
  },
  queryContainerTypeFloor: {
    method: 'post',
    url: `${glesRoot}/base/container/type/queryContainerTypeFloor`,
    describe: '根据容器面查询楼层信息',
    isNeedLoading: true
  },
  queryContainerTypeGoodsPosition: {
    method: 'post',
    url: `${glesRoot}/base/container/type/queryContainerTypeGoodsPosition`,
    describe: '根据容器面查询货位信息',
    isNeedLoading: true
  },
  getOrderNumber: {
    method: 'post',
    url: `${glesRoot}/base/container/type/getOrderNumber`,
    describe: '查询自增编码',
    isNeedLoading: true
  },
  getContainerArchivesList: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/queryContainerArchivesPage`,
    describe: '查询容器档案列表信息',
    isNeedLoading: true
  },
  getContainerArchivesListByCodeOrType: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/queryContainerArchivesByCodeOrTypePage`,
    describe: '查询容器档案列表信息',
    isNeedLoading: true
  },
  queryContainerArchivesById: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/queryContainerArchivesById`,
    describe: '查询容器档案详情信息',
    isNeedLoading: true
  },
  insertContainerArchives: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/addContainerArchives`,
    describe: '新增容器档案',
    isNeedLoading: true
  },
  updateContainerArchives: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/updateContainerArchives`,
    describe: '更新容器档案',
    isNeedLoading: true
  },
  deleteContainerArchivesById: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/deleteContainerArchivesById`,
    describe: '删除容器档案',
    isNeedLoading: true
  },
  deleteContainerArchivesByIdBatch: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/deleteContainerArchivesByIdBatch`,
    describe: '批量删除容器档案',
    isNeedLoading: true
  },
  getExecTemplateList: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/getExecTemplate`,
    describe: '获取机器人执行模板列表',
    isNeedLoading: false
  },
  QueryingSystemSwitches: {
    method: 'post',
    url: `${glesRoot}/base/systemConfig/querySystemConfigByCode`,
    describe: '查询系统开关',
    isNeedLoading: false
  },
  queryDmpCallbackRecord: {
    method: 'post',
    url: `${glesRoot}/base/dmp_callback/queryCallbackRecord`,
    describe: '查询Dmp回调结果',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
