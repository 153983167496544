// 主要功能简单配置axios的拦截器 + 自动loading;
/**
 *
 *  @prop { apiStore: Array }                 所有请求API
 *  @prop { requestInterceptor: Function}     处理request拦截器
 *  @prop { responeInterceptor: Function}     处理respone拦截器
 *  @prop { authentication: Boolean}          是否需要鉴权
 *  @prop { authenticationRespone: Boolean}   鉴权处理
 *
 *  功能：
 *     1. 简易化axios拦截器
 *     2. 根据配置文件自动增加全局loading/增加局部loading;
 *     3. post请求头有4中，会针对4中进行自动处理， axios只支持application/json
 *     4. 预处理了接口报错处理
 *     5. 预处理了国际化返回值；
 *     6. 预处理分页bug，后台从0开始，修正为1
 *
 *  数据：
 *     apiStore:[{
            method: "post", // 结构请求的方式
            url: `${root}/api/coreresource/auth/user/addUser/v1`, // 接口URL
            describe: "新增用户", // 标注
            isNeedLoading: false, // 是否需要loading //key是 fullLoading；
            storeKey: "authDialogLoad", // 局部刷新key
            config: {} // 配置axios的config
          }]

 *  备注：
 *       1. 使用权限这块，必须接入金云组件、
 *          组件封装的code :  0：表示请求成功 ，  2：表示未登录 3：未授权；
 *       2. 注意调用顺序
 */
import axios from "axios";
import vm from "vue";
import { Message } from "element-ui";
import qs from "qs";
import { merge } from "lodash";
import { translateMessage } from "../utils/help";

const myState = vm.observable({ fullLoading: false, storeKeyArr: [] });

// 默认常量
const successCode = "0";
const logout = "2";
const unPermission = "3";

/**  注册computed */
const mapLoad = key => {
  const res = {};
  let keyArr = null;
  if (key && Array.isArray(key)) {
    keyArr = key;
  } else if (key && typeof key === "string") {
    keyArr = [key];
  } else {
    error(new Error("请输入要获取的storeKey"));
  }
  keyArr.forEach(item => {
    res[item] = function mappedLoad() {
      if (item === "fullLoading") {
        return myState[item];
      }
      return !!~myState.storeKeyArr.findIndex(i => String(i) === String(item));
    };
  });
  return res;
};

// 默认的配置项；
const defaultOptionsConfig = {
  // 预处理拦截器
  requestInterceptor(config) {
    return config;
  }, // 请求拦截器
  responeInterceptor(respone) {
    return respone;
  }, // 请求拦截器
  // 权限相关处理
  authentication: true, // 是否需要开启鉴权；
  authenticationRespone(code, next) {
    next();
  }, // 开启鉴权后的默认处理，必填；
};

const error = error => {
  console.error("gaxios报错 >");
  console.error(error);
};

class Gaxios {
  constructor(options) {
    this.orginAxiosObject = axios;
    this.options = Object.assign(defaultOptionsConfig, options);
    // 记录当前loadingArr;
    this.loadingArr = [];
  }

  // 获取未封装的Axios对象；
  getAxios() {
    return axios;
  }

  /**
   * update，myState
   *
   * @param { String } storeKey
   * @param { Boolean } value
   * @memberof Gaxios
   */
  updateMyState(storeKey, value) {
    if (storeKey === "full") {
      myState.fullLoading = value;
    } else {
      if (value) {
        myState.storeKeyArr.push(storeKey);
      } else {
        const index = myState.storeKeyArr.findIndex(i => String(i) === String(storeKey));
        if (!!~index) {
          myState.storeKeyArr.splice(index, 1);
        }
      }
    }
  }

  // 全局优先策略
  hasFullLoading(arv) {
    if (this.loadingArr.length < 1) return true;
    const newLoading = this.loadingArr.concat(arv);
    let fullScene = newLoading.find(item => !item.extendConfig.storeKey);
    if (fullScene) {
      newLoading.forEach(item => {
        const { storeKey } = item.extendConfig || {};
        if (storeKey) {
          this.updateMyState(storeKey, false);
        } else {
          this.updateMyState("full", true);
        }
      });
      this.loadingArr.push({ ...arv, extendConfig: { ...arv.extendConfig, storeKey: null } });
      return false;
    }
    return true;
  }

  /**
   * 设置当前的loading
   * @param { Object } config
   * @memberof Gaxios
   */
  setLoading(config) {
    const { isNeedLoading = null, storeKey = "" } = config.extendConfig || {};
    if (isNeedLoading && this.hasFullLoading(config)) {
      this.updateMyState(storeKey ? storeKey : "full", true);
      this.loadingArr.push(config);
    }
  }

  /**
   * 取消loading
   * @param { String } url
   * @returns
   * @memberof Gaxios
   */
  cancelLoading(url) {
    const index = this.loadingArr.findIndex(item => item.url === url);
    if (!~index) return;
    const obj = this.loadingArr[index];
    const { isNeedLoading = null, storeKey = "" } = obj.extendConfig || {};
    if (isNeedLoading) {
      // 设置loading
      this.updateMyState(storeKey ? storeKey : "full", false);
      // 清楚数据；
      this.loadingArr.splice(index, 1);
    }
  }

  /**
   * 允许用户修改axios的默认配置；
   * 建议不使用，会受执行顺序影响
   * @param {*} [options={}]
   * @memberof Gaxios
   */
  changeAxiosAttribute(options = {}) {
    const defaultValue = { timeout: 60000, withCredentials: true };
    axios.defaults = merge({}, axios.defaults, defaultValue, options);
  }
  /**
   * 注册用户request拦截器
   * @param { Function } callBack
   * @memberof Gaxios
   */
  registerRequestIntercepor(callBack) {
    this.options.requestInterceptor = callBack;
  }
  // 注册request拦截器
  _addRequestInterceptor() {
    axios.interceptors.request.use(
      config => {
        let nconfig = config;
        const isGetMethod = config.method === "get";
        const { extendConfig = null, ...params } =
          (isGetMethod ? config.params : config.data) || {};
        if (extendConfig) {
          // 增加全局loading；
          this.setLoading({ extendConfig, url: config.url });
        }
        nconfig[isGetMethod ? "params" : "data"] = params;
        // post请求包含4中请求头
        // application/x-www-form-urlencoded,
        if (!isGetMethod) {
          const header = config.headers["Content-Type"];
          if (header === "application/x-www-form-urlencoded") {
            nconfig.data = qs.stringify(nconfig.data);
          } else if (header === "multipart/form-data") {
            const formData = new FormData();
            Object.keys(nconfig.data).forEach(key => {
              formData.append(key, nconfig.data[key]);
            });
            nconfig.data = formData;
          }
        }
        // 开放request拦截接口；
        nconfig = this.options.requestInterceptor(nconfig);
        return nconfig;
      },
      error => {
        error.message += `\n requestUrl: ${error.request.config ? error.request.config.url : ""}`;
        return Promise.reject(error);
      },
    );
  }
  /**
   * 注册用户respone拦截器
   * @param { Function } callBack
   * @memberof Gaxios
   */
  registerResponeIntercepor(callBack) {
    this.options.responeInterceptor = callBack;
  }
  _addResponeInterceptor() {
    const successFn = respone => {
      // 预处理分页请求
      const next = () => {
        if (respone.data.data && respone.data.data.currentPage === 0) {
          respone.data.data.currentPage = 1;
        }
      };
      const cancelLoading = () => {
        // clean loading记录
        setTimeout(() => {
          this.cancelLoading(respone.config.url);
        }, 500);
      };
      // 如果没有回传object,则不做逻辑判断；
      if (typeof respone.data === "object" && respone.data.hasOwnProperty("code")) {
        if (String(respone.data.code) !== String(successCode)) {
          this.options.messageFn("error", translateMessage(respone.data.msg));
        }
        // 处理Respone的拦截器
        if (
          this.options.authentication &&
          [logout, unPermission].includes(String(respone.data.code))
        ) {
          this.options.authenticationRespone(respone.data, next);
        } else {
          next();
        }
        cancelLoading();
        return this.options.responeInterceptor(respone);
      }else{
        cancelLoading();
        return this.options.responeInterceptor(respone);
      }
    };
    const errorFn = error => {
      if (Object.prototype.toString.call(error) === "[object Object]") {
        this.cancelLoading(error.response.config.url);
      }
      error.message = `${error.message}\n requestUrl: ${
        error.response.config ? error.response.config.url : ""
      }`;
      return Promise.reject(error);
    };
    axios.interceptors.response.use(successFn, errorFn);
  }

  /** 注册起始函数 */
  _main() {
    // 配置全局axios的默认值；
    this.changeAxiosAttribute();
    // 配置Requst拦截器
    this._addRequestInterceptor();
    // 配置Repone拦截器
    this._addResponeInterceptor();
  }
}

/**
 * @param { Object } vue
 * @param { Object } options
 *
 * apiStore:{
      method: "get", // 结构请求的方式
      url: `${root}/api/coreresource/i18n/getLangItems/v1`, // 接口URL
      describe: "根据code获取字典表", // 标注  // 可选
      isNeedLoading: false, // 是否需要loading
      storeKey: null, // 可选  fullLoading / 其他用户自定义 key
      config: {} // 同axios的config;
    }
 */
const defaultPluginConfig = {
  messageFn(type, msg) {
    if (type === "error") {
      Message.error(msg);
    }
  },
  apiStore: "",
  // 预处理拦截器
  requestInterceptor(config) {
    return config;
  }, // 请求拦截器
  responeInterceptor(respone) {
    if (typeof respone.data === "object" && "code" in respone.data) {
      return respone.data;
    }
    return respone;
  }, // 请求拦截器
  // 权限相关处理
  authentication: true, // 是否需要开启鉴权；
  authenticationRespone(code, next) {
    next();
  }, // 开启鉴权后的默认处理，必填
};

// 封装请求函数httpService；
const $api = (apiStore = {}, query = {}) => {
  if (!apiStore || JSON.stringify(apiStore) === "{}") return Promise.reject();
  const { url, method, config = {}, ...extendConfig } = apiStore;
  const params = { ...query, extendConfig };
  switch (method.toLocaleLowerCase()) {
    case "get":
      return axios.get(url, { params, ...config });
    case "delete":
      return axios.delete(url, { params }, config);
    case "put":
      return axios.put(url, { ...params }, config);
    default:
      return axios.post(url, params, config);
  }
};

const plugin = {};

plugin.gAxios = null;

plugin.httpService = null;

plugin.mapLoad = mapLoad;

plugin.install = (vue, options = {}) => {
  const myOptions = Object.assign(defaultPluginConfig, options);
  // 必填apiStore;
  plugin.gAxios = new Gaxios(myOptions);
  plugin.gAxios._main();
  // 扩展loadStore
  vue.prototype.$loadStore = mapLoad;
  // 扩展apiStore
  if (myOptions.apiStore) {
    vue.prototype.$apiStore = myOptions.apiStore;
  }
  // 增加httpService
  vue.prototype.$httpService = $api;
};

let gAxios = plugin.gAxios;
let httpService = $api;

export { mapLoad, gAxios, httpService };

export default plugin;
