<template>
  <div class="ui-mpage">
    <slot />
  </div>
</template>

<script>
/**
 *
 *  @description { 目前只支持处理MForm、MTable逻辑 }
 *  @description { MForm代理 reset, search事件 }
 *  @description { MTable 代理， pageChange事件， 以及其他事情}
 *
 *  @param { isPagination } 是否分页
 *  @param { formatterSearchParams } 格式化searchParams;
 *  @param { searchSync } 传入是否初次加载， 搜索内容， next函数， next函数必须再次调用；
 *
 *  @refEvent {  renderTable } 再次渲染table；
 *
 */

export default {
  name: "MPage",
  componentName: "MPage",
  props: {
    isPagination: Boolean,
    searchSync: {
      type: Function,
      required: true,
    },
    formatterSearchParams: Function,
  },
  data() {
    return {
      searchParam: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0,
      },
    };
  },
  provide() {
    return {
      rootPage: this,
    };
  },
  created() {
    // 注册reset事件
    this.$on("mpage:reset", this.resetForm);
    // 注册search事件
    this.$on("mpage:submit", this.searchList);
    // 注册table的分页改变
    this.$on("mpage:pageChange", this.pageChange);
    // 注册再次渲染table
    this.$on("mpage:renderTable", this.renderTable);
    // 首次加载渲染
    this.getResultList(this.pageData, true);
  },
  methods: {
    resetForm(val) {
      this.searchParam = val;
    },
    searchList(val) {
      this.searchParam = this.formatterSearchParams ? this.formatterSearchParams(val) : val;
      const pageData = this.isPagination ? { ...this.pageData, currentPage: 1 } : null;
      this.getResultList({ ...this.searchParam, pageSize: this.pageData.pageSize }, false);
    },
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData };
      this.getResultList({ ...this.searchParam, ...this.pageData }, false);
    },
    renderTable() {
      this.getResultList({ ...this.searchParam, ...this.pageData }, false);
    },
    getResultList(params, isFirst) {
      const next = ({ currentPage, pageSize, recordCount }) => {
        this.pageData = { currentPage, pageSize, recordCount };
      };
      this.searchSync({ params, isFirst }, next);
    },
  },
};
</script>
