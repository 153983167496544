// 定制 elementUI 预设值
export { DEFAULT_SIZE } from './default'

// 定制 element Input组件 / ElInput
import customElInput from './el-input'
import customElSelect from './el-select'
export const Input = parseComponent(customElInput)
export const Select = parseComponent(customElSelect)

export function parseComponent(componentObj) {
  const install = Vue => Vue.component(componentObj.name, componentObj)
  return { install }
}
