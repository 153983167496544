import zhCNLocale from "element-ui/lib/locale/lang/zh-CN";

const cn = {
  ...zhCNLocale,
  libsSz: {
    key1: "用户名",
    key2: "密码",
    key3: "登录",
    key4: "退出登录",
    key5: "抱歉，你无权访问该页面",
    key6: "返回上一页",
    key7: "抱歉，你访问的页面不存在",
    key8: "展开",
    key9: "收起",
    key10: "查询",
    key11: "重置",
    key12: "序号",
    key13: "操作",
    key14: "取消",
    key15: "确定",
    key16: "加载中...",
    key17: "修改密码",
    key18: "角色名称",
    key19: "角色说明",
    key20: "权限类型",
    key21: "授权子系统",
    key22: "请输入",
    key23: "请选择",
    key24: "页面权限",
    key25: "关联用户",
    key26: "请输入关键字进行过滤",
    key27: "姓名",
    key28: "电话",
    key29: "启用权限",
    key30: "禁用权限",
    key31: "请输入必填项！",
    key32: "请选择需要授权的子系统",
    key33: "分配",
    key34: "禁止输入特殊字符",
    key35: "请输入必填项！",
    key36: "最大限制输入{0}字符",
    key37: "请输入合法IP地址",
    key38: "请输入正整数",
    key39: "保存",
    key40: "编辑",
    key41: "存在未保存的编辑项，请先保存在编辑！",
    key42: "是否确定取消本行编辑",
    key43: "请输入自然数",
    key44: "请输入正确手机号",
    key45: "暂无数据",
    key46: "请输入{0}字符",
    key47: "未选择",
    key48: "已选择",
    key49: "意见反馈",
    key50: "下载至本地",
    key51: "图片加载失败",
    key52: "导航器",
    key53: "撤销",
    key54: "重做",
    key55: "删除",
    key56: "放大",
    key57: "缩小",
    key58: "适应画布",
    key59: "实际尺寸",
    key60: "层级后置",
    key61: "层级前置",
    key62: "多选",
    key63: "画布",
    key64: "网格对齐",
    key65: "仅能输入数字或英文字符",
    key66: "仅能输入1-65535正整数",
    key67: "请输入合法的子网掩码",
    key68: "请输入合法的网关地址",
    key69: "下载",
    key70: "将文件拖到此处，或",
    key71: "点击上传",
    key72: "正在上传...",
    key73: "确定上传",
    key74: "重新上传",
    key75: "请上传文件~",
    key76: "上传文件大小不能超过{0}",
    key77: "上传失败",
    key78: "限制上传{0}个文件",
    key79: "为保证条码识别准确率，请上传图片后先剪裁图片至仅有条码大小",
    key80: "上传并剪裁条码",
    key81: "检测是否开启摄像头...",
    key82: "返回",
    key83: "1、浏览器地址栏输入chrome://flags， 搜索 unsafely-treat-insecure-origin-as-secure 选项，设置为Enabled，在选项输入框添加需要开启摄像头的域名或IP，修改之后重启浏览器。",
    key84: "2、浏览器开启了摄像头扫码，摄像头被禁止，点击浏览器地址栏安全警示标记，按照浏览器引导开启摄像头。",
    key85: "选择图片",
    key86: "左旋转",
    key87: "右旋转",
    key88: "放大",
    key89: "缩小",
    key90: "您的浏览器未开启摄像头扫码，请使用上传图片进行解析条码",
    key91: "请先上传正确的条码图片",
    key92: "上传并剪裁条码",
    key93: "上传图片",
    key94: "仅能支持数字",
    key95: "请输入数字，多个时用逗号分隔"
  }
};

export default cn;
