<template>
  <div>
    <div @click="visibleScan = true">
      <slot name="scanBtn">
        <i class="icon iconfont icon-saoma" />
      </slot>
    </div>
    <m-dialog
      :visible="visibleScan"
      :fullscreen="true"
      :is-need-footer="false"
      @closed="close"
    >
      <div v-loading="loading" class="scan-box" :element-loading-text="$t('libsSz.key91')">
        <!-- 摄像头扫码 -->
        <stream-barcode-reader
          v-if="cameraScan"
          class="stramScan"
          @decode="(val) => onDecode(val)"
          @loaded="(v) => onLoaded(v)"
        />
        <!-- 上传图片解析条码 -->
        <div v-if="scanOption.imageScanCode" class="imageScan tc mt50">
          <image-barcode-reader @decode="(text, e) => onImageDecode(text, e)" @previewImg="(e) => previewImg(e)" />
          <p>{{ imgUploadText }}</p>
          <el-button type="primary" class="mt50 w160" @click="submitInfo">
            {{ $t("libsSz.key15") }}
          </el-button>
        </div>
        <!-- 未开启摄像头的chrome设置页 -->
        <div v-if="openCameraSetting">
          <setting />
          <el-button type="primary" class="mt10 w160 scanSetting-btn" @click="close">
            {{ $t("libsSz.key82") }}
          </el-button>
        </div>
      </div>
    </m-dialog>
  </div>
</template>
<script>
import StreamBarcodeReader from "./components/StreamBarcodeReader";
import ImageBarcodeReader from "./components/ImageBarcodeReader";
import MDialog from "../MDialog/MDialog";
import setting from "./components/setting";

/**  条码解析
 *
 *   功能：
 *     1. 支持解析一维码、二维码
 *     2. 支持摄像头扫码后自动关闭弹框 返回参数值
 *     3. 由于解析图片质量要求较高，上传图片解析时，必须剪切图片后上传
 *     4. 仅设置摄像头扫码时，浏览器未开启摄像头，会展示chrome浏览器设置页面
 *
 *  具体参数：
 *     @props { Object } extendScanOption; 配置解析类型
 *     @props { Boolean } cameraScanCode; 开启摄像头扫码解析条码；
 *     @props { Boolean } imageScanCode; 上传图片解析条码
 *
 */

const defaultScanOption = {
  cameraScanCode: true, // 摄像头扫码
  imageScanCode: true, // 上传文件解析条码
};

export default {
  name: "MScan",
  components: { StreamBarcodeReader, ImageBarcodeReader, MDialog, setting },
  props: {
    extendScanOption: {
      type: Object,
      default() {
        return defaultScanOption;
      },
    },
  },
  data() {
    return {
      text: "",
      id: null,
      msg: "", // 当前没有开启摄像头的提示
      loading: true,
      isOpenScan: true, // 是否为摄像头扫码
      imgUploadText: "", // 上传图片解析之后的编码
      visibleScan: false,
      scanOption: { ...defaultScanOption, ...this.extendScanOption },
    };
  },
  computed: {
    // 浏览器扫码框
    cameraScan() {
      return this.scanOption.cameraScanCode;
    },
    // 浏览器扫码 未开启摄像头的设置提示
    openCameraSetting() {
      return !this.isOpenScan && !this.scanOption.imageScanCode;
    },
  },
  watch: {
    visibleScan: {
      handler(nv) {
        if (nv && this.openCameraSetting) {
          this.msg = this.$message({
            duration: 0,
            type: "warning",
            message: this.$t("libsSz.key90"),
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 摄像头扫码时，加载完成，判断是否开启摄像头
    onLoaded(val) {
      this.loading = false;
      if (val) {
        this.isOpenScan = false;
        console.log(this.isOpenScan, this.scanOption.imageScanCode);
      }
    },
    // 摄像头扫码不需要确定按钮，直接返回值
    onDecode(val) {
      this.text = val;
      if (this.text) {
        this.$emit("getScanInfo", val);
        if (this.id) clearTimeout(this.id);
        this.close();
      }
      if (this.id) clearTimeout(this.id);
      this.id = setTimeout(() => {
        if (this.text === val) {
          this.text = "";
        }
      }, 5000);
    },

    // 上传图片解码
    onImageDecode(text) {
      this.imgUploadText = text;
    },
    // 关闭弹框
    close() {
      this.visibleScan = false;
      this.msg && this.msg.close();
    },
    // 上传图片返回值
    submitInfo() {
      if (!this.imgUploadText) {
        this.$message.error(this.$t("libsSz.key91"));
        return;
      }
      this.$emit("getScanInfo", this.imgUploadText);
      this.close();
    },
  },
};
</script>
<style lang="scss" scope>
.scan-box {
  min-height: 80vh;
  height: 100%;
}
.stramScan {
  margin: 0 auto;
  width: 60vw;
  video {
    width: 100%;
    height: 100%;
  }
}
.ml120 {
  margin-left: 120px;
}
.mt50 {
  margin-top: 50px;
}
@media screen and(min-width: 1024px) {
  .stramScan {
    margin: 0 auto;
    width: 50vw;
  }
}
@media screen and(min-width: 1800px) {
  .stramScan {
    margin: 0 auto;
    width: 70vw;
  }
}
</style>
