import base from './base'
import containerManage from './containerManage'
import materialManage from './materialManage'
import strategyManage from './strategyManage'
import inventoryList from './inventoryList'
import coreresource from './coreresource'
import common from './common'
import workflow from './workflow'
import orderManage from './orderManage'
import interfaces from './interface'
import monitorManage from './monitorManage'
export default {
  base,
  containerManage,
  materialManage,
  strategyManage,
  inventoryList,
  coreresource,
  common,
  workflow,
  orderManage,
  interfaces,
  monitorManage
}
