<template>
  <div class="mt30 scanSetting">
    <div>{{ $t("libsSz.key83") }}</div>
    <img :src="padScanSettingImg" alt="">
    <div>{{ $t("libsSz.key84") }}</div>
    <img :src="chromForbidScanImg" alt="">
  </div>
</template>
<script>
import padScanSetting from "../assets/padScanSetting.png";
import chromForbidScan from "../assets/chromeForbidScan.png";

export default {
  data() {
    return {
      padScanSettingImg: padScanSetting,
      chromForbidScanImg: chromForbidScan,
    };
  },
};
</script>
<style lang="scss" scope>
.scanSetting {
  padding: 30px 150px 0;
}
.scanSetting img {
  width: 100%;
  height: 70%;
  margin: 10px auto;
  display: block;
}
.scanSetting-btn {
  margin: 10px auto;
  display: block;
  text-align: center;
}
</style>
