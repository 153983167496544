<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import '@/assets/iconfont/iconfont.css'
import { getViewSize } from '@/utils/os'
export default {
  name: 'App',
  data() {
    return {
      loadLanguage: true
    }
  },
  computed: {},
  created() {},
  mounted() {
    /* setTimeout(() => {
      const timer = setInterval(() => {
        if (document.body.getAttribute("loadLanguage")) {
          this.loadLanguage = true;
        } else {
          this.loadLanguage = false;
          clearInterval(timer);
        }
      }, 100);
    }, 500);

    this.resize();
    window.addEventListener("resize", this.resize);

    // 屏幕尺寸
    document.body.setAttribute("viewSize", getViewSize()); */
  },
  methods: {
    resize() {
      let fontSize = (document.body.offsetWidth / 1280) * 14
      // font-size 应该在 7 ~ 16 之间
      fontSize = fontSize < 7 ? 7 : fontSize
      fontSize = fontSize > 16 ? 16 : fontSize
      document.documentElement.style.fontSize = `${fontSize}px`
      document.body.setAttribute('viewSize', getViewSize())
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/quicklayoutCss.scss';
@import '@/assets/scss/default.scss';
@import '@/assets/scss/common.scss';
@import '@/assets/scss/base.scss';
</style>
