<template>
  <el-select
    ref="hSelect"
    v-el-select-loadmore="loadmore"
    class="AvaSelect"
    :value="value"
    :filter-method="filterMethod"
    :placeholder="placeholder"
    :multiple="multiple"
    :clearable="!multiple"

    :filterable="autoFilterable"
    v-bind="$attrs"

    v-on="$listeners"
    @focus="selFocus"

    @change="change"
  >
    <el-option-group v-if="queryBackFillList.length" label="选中的数据">
      <!-- 已选的内容, 始终展示, 用于回填 -->
      <el-option
        v-for="item in queryBackFillList"
        :key="`sel-${item[valueText]}`"
        :label="String(item[labelText])"
        :value="item[valueText]"
      >
        <slot />
      </el-option>
    </el-option-group>

    <el-option-group v-if="queryStorageList.length">
      <el-option
        v-for="item in queryStorageList"
        :key="`noSel-${item[valueText]}`"
        :label="item[labelText]"
        :value="item[valueText]"
      >
        <slot />
      </el-option>
    </el-option-group>

    <div v-if="isQueryLoading" class="loadSty">
      <i class="el-icon-loading" /> loading...
    </div>
  </el-select>
</template>

<script>
/**
  仅适用于Geek + avalon-fe 项目的下拉选择
  如果设置多选 multiple 自动设置 clearable 为false
  如果设置单选 multiple 自动设置 clearable 为true
  默认可搜索 filterable

  对于大数据量进行了优化
 */
// 分页查询
import $mixin_page from './data/mixin_page'
import i18n from '@/plugins/LoadLang'

export default {
  mixins: [$mixin_page],
  props: {
    options: { // 列表数据
      type: Array,
      default: () => []
    },
    value: [String, Number, Array], // 选择的数据
    labelText: { type: String, default: () => 'label' },
    valueText: { type: String, default: () => 'value' },
    multiple: Boolean, // 多选, Element UI 属性
    placeholder: {
      type: String,
      default: () => i18n.t('lang.gles.common.pleaseSelect')
    }
  },
  data() {
    return {}
  },
  computed: {
    autoFilterable() {
      const { filterable } = this.$attrs
      if (typeof filterable === 'boolean') {
        return filterable
      }

      return this.options.length > 5
    },
    // 翻页数据
    selPageList() {
      const { options, valueText, backFillList } = this

      if (options.length) {
        return options.filter(optItem => {
          return !backFillList.find(backFillItme => {
            return String(optItem[valueText]) === String(backFillItme[valueText])
          })
        })
      }

      return []
    },
    // 回填数据
    backFillList() {
      const { value, options, valueText } = this

      if (options.length) {
        // 数组, 多选
        if (value && typeof value === 'object' && value.length) {
          const vals = value.map((valueItem) => {
            return options.find(optItem => String(optItem[valueText]) === String(valueItem))
          })
          // 如果没有查询到数据, 或者是空, null undefined等, 就删除
          return vals.filter(val => !!val)
        }

        // 单选
        const selItem = options.find(optItem => String(optItem[valueText]) === String(value))
        return selItem ? [selItem] : []
      }

      return []
    }
  },
  methods: {
    selFocus() {
      this.$emit('focus')
      this.$nextTick(() => {
        // 查询所有
        this.filterMethod('')
      })
    },
    change(values) {
      let val = values
      if (values && typeof values === 'object') {
        val = values[values.length - 1]
      }

      if (val) {
        const { valueText, labelText } = this
        const queryOptInfo = this.options.find(item => item[valueText] === val)
        this.queryText = queryOptInfo[labelText]
      } else {
        this.queryText = ''
      }

      this.$emit('input', values)
      this.$emit('change', values)
    }
  }
}
</script>

<style lang="less">
.el-select-group__wrap {
  // max-width: 400px !important;
}
</style>

<style scoped lang="less">
.AvaSelect {
  width: 100%;
}

.loadSty {
  text-align: center;
  line-height: 60px;
}

.divider {
  display: block;
  height: 1px;
  width: 100%;
  background-color: #dcdfe6;
  position: relative;
  margin: 10px 0;
}
</style>
