<template>
  <div>
    <input
      v-if="upload"
      type="file"
      name="image"
      accept="image/*"
      capture="environment"
      @change="onChangeInput"
    >
    <button
      slot="open"
      type="default"
      class="cutBtn mb10"
      @click="changeCutter"
    >
      {{ $t("libsSz.key93") }}
    </button>
    <p>{{ $t("libsSz.key79") }}</p>

    <img
      v-if="imgUrl"
      id="image"
      :src="imgUrl"
      alt=""
      class="w160 mt50"
    >

    <el-dialog
      :title="$t('libsSz.key92')"
      :visible.sync="visible"
      width="950px"
      center
      append-to-body
    >
      <img-cropper @cutDown="(e) => cutDown(e)" />
    </el-dialog>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";
import imgCropper from "../../MImageCropper/MImageCropper";

/**
 *  上传图片
 *   注：由于解析图片质量要求较高，上传图片解析时，必须剪切图片后上传
 *
 *   @param { Boolean } upload; 上传图片按钮，暂时默认false，直接使用剪切图片img-cropper的上传
 */

export default {
  name: "ImageBarcodeReader",
  props: {
    upload: {
      type: Boolean,
      default: false,
    }, // 上传图片解析
  },
  components: { imgCropper },
  data() {
    return {
      codeReader: new BrowserMultiFormatReader(),
      imgUrl: "", // 上传图片url
      visible: false, // 是否显示图片剪裁
    };
  },
  destroyed() {
    this.codeReader.reset();
  },

  methods: {
    cutDown(e) {
      this.visible = false;
      this.onChangeInput(e);
    },
    changeCutter() {
      this.imgUrl = "";
      this.visible = true;
    },
    onChangeInput(e) {
      this.imgUrl = window.URL.createObjectURL(e);

      const files = e;
      if (!files) return;

      let reader = null;
      reader = new FileReader();
      reader.onload = this.processFile;
      reader.readAsDataURL(files);
    },

    // 解析条码
    processFile(e) {
      // this.$el.innerHTML += `<img id="image" src="${e.target.result}"/>`;
      this.codeReader
        .decodeFromImage("image")
        .then(result => {
          this.$emit("decode", result.text);
        })
        .catch(error => {
          this.$emit("error", error);
        });
    },
  },
};
</script>
<style lang="scss">
.cutBtn {
  padding: 6px 35px;
  border: 1px solid #333;
  background: #f7f7f7;
}
</style>
