export const excludeNullParams = (query) => {
  const newParams = {}
  Object.keys(query).forEach((item) => {
    if (item !== 'recordCount' && query[item]) {
      newParams[item] = query[item]
    }
  })
  return newParams
}

// 转数据类型 {key:value} => {label:value,value:key}
export const objectToArray = (data) => {
  const init = Object.keys(data || {})
  if (!init.length) return []
  return init.map((item) => ({ label: data[String(item)], value: item }))
}

// 前端生成UUid
export const uuid = () => {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'
  return s.join('')
}

// 数据类型转换 { code: xxxx, name:xxxxx} => {label:code, value:name};
export const transformForOptions = ({ data = [], label, value }) => {
  if (!data || !data.length) return false
  try {
    return data.map((item) => ({ label: item[label], value: item[value] }))
  } catch (error) {
    return false
  }
}

export const recursion = ({
  data,
  childrenKey = 'children',
  pid = '-1',
  formatter = (row) => row
}) => {
  let newArr = []
  data.map((item, index) => {
    const newItem = item
    const expandItems = {
      id: uuid(),
      pid
    }
    const { [childrenKey]: child, ...others } = newItem

    // 设置父类
    newArr.push({ ...expandItems, ...formatter(others) })

    // 再次循环child
    if (child && child.length > 0) {
      newArr = newArr.concat(
        recursion({
          data: child,
          childrenKey,
          pid: expandItems.id,
          formatter
        })
      )
    }
  })
  return newArr
}
