// 元组件
import MConfirmBtn from "./components/MConfirmBtn";
import MDialog from "./components/MDialog";
import MDrawer from "./components/MDrawer";
// import MAdvice from "./components/MAdvice";
import MEditTable from "./components/MEditTable";
import MForm from "./components/MForm";
import MHeader from "./components/MHeader";
import MMenu from "./components/MMenu";
import MSelectTransfer from "./components/MSelectTransfer";
import MSpin from "./components/MSpin";
import MTable from "./components/MTable";
import MTransformPage from "./components/MTransformPage";
import MTransition from "./components/MTransition";
import MAuth from "./components/MAuth";
import MEmpty from "./components/MEmpty";
import MExportDialog from "./components/MExportDialog";
import MDragItem from "./components/MDragItem";
import MDragWrap from "./components/MDragWrap";
import MScan from "./components/MScan";
import MImageCropper from "./components/MImageCropper";
import MVirtualTree from "./components/MVirtualTree";

import locale from "./locale";

import $Clipboard from "./utils/Clipboard";
import dialogdrag from "./directives/v-dialogdrag";

let prototypes = {
  $Clipboard,
};

// 基于元组件扩展
import MPage from "./view/MPage";

const components = {
  MAuth,
  MConfirmBtn,
  MDialog,
  MDrawer,
  MEditTable,
  MForm,
  MHeader,
  MMenu,
  MSelectTransfer,
  MSpin,
  MTable,
  MTransformPage,
  MPage,
  MEmpty,
  MTransition,
  MExportDialog,
  MDragItem,
  MDragWrap,
  MScan,
  // MAdvice,
  MImageCropper,
  MVirtualTree,
};

const install = function (Vue, opts = {}) {
  opts.i18n && locale.i18n(opts.i18n);

  // 注册Vue指令
  Vue.directive("dialogdrag", dialogdrag);

  Object.keys(prototypes).forEach((key) => {
    Vue.prototype[key] = prototypes[key];
  });

  Object.keys(components).forEach((key) => {
    Vue.component(components[key].name, components[key]);
  });
};

/* istanbul ignore if */
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}

export default {
  install,
  ...components,
};
