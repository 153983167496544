<template>
  <el-select
    :value="value"
    filterable
    :multiple="multiple"
    :placeholder="placeholder"
    :filter-method="filterMethod"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
    @clear="clear"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item[labelText]"
      :value="item.value"
      :disabled="item.disabled"
    />
  </el-select>
</template>

<script>
import i18n from '@/plugins/LoadLang'
import { mapState } from 'vuex'
export default {
  props: {
    data: { // 列表数据
      type: Array,
      default: () => []
    },
    value: [String, Number, Array], // 选择的数据
    labelText: { type: String, default: () => 'label' },
    valueText: { type: String, default: () => 'value' },
    multiple: Boolean, // 多选, Element UI 属性
    placeholder: {
      type: String,
      default: () => i18n.t('lang.gles.common.pleaseSelect')
    },
    queryType: {
      type: String,
      default: 'fpCodeList'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dataOptions: {
      type: Object,
      default: () => ({})
    },
    showLabel: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      options: [],
      loading: false,
      queryStr: ''
    }
  },
  computed: {
    ...mapState('moreData', ['fpCodeList', 'usableFactoryPositionList', 'goodsPositionList', 'moveGoodsPositionList', 'moveContainerCodeList'])
  },
  watch: {
    dataOptions: {
      handler(row) {
        row[this.showLabel] && (this.queryStr = row[this.showLabel])
      },
      deep: true,
      immediate: true
    },
    fpCodeList: {
      handler(newVal) {
        if (newVal && newVal.length && this.queryType === 'fpCodeList') {
          this.filterMethod(this.queryStr)
        }
      },
      deep: true,
      immediate: true
    },
    usableFactoryPositionList: {
      handler(newVal) {
        if (newVal && newVal.length && this.queryType === 'usableFactoryPositionList') {
          this.filterMethod(this.queryStr)
        }
      },
      deep: true,
      immediate: true
    },
    goodsPositionList: {
      handler(newVal) {
        if (newVal && newVal.length && this.queryType === 'goodsPositionList') {
          this.filterMethod(this.queryStr)
        }
      },
      deep: true,
      immediate: true
    },
    moveGoodsPositionList: {
      handler(newVal) {
        if (newVal && newVal.length && this.queryType === 'moveGoodsPositionList') {
          this.filterMethod(this.queryStr)
        }
      },
      deep: true,
      immediate: true
    },
    moveContainerCodeList: {
      handler(newVal) {
        if (newVal && newVal.length && this.queryType === 'moveContainerCodeList') {
          this.filterMethod(this.queryStr)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.resetList()
  },
  beforeUpdate() {
    if (!this[this.queryType]?.length) {
      this.options = []
    }
  },
  methods: {
    resetList() {
      this.$store.dispatch('moreData/addFpCodeList', [])
      this.$store.dispatch('moreData/addUsableFactoryPositionList', [])
      this.$store.dispatch('moreData/addGoodsPositionList', [])
      this.$store.dispatch('moreData/addMoveGoodsPositionList', [])
      this.$store.dispatch('moreData/addMoveContainerCodeList', [])
      this.options = []
    },
    filterMethod(query) {
      // if (!this.dialogVisible) return
      let list = []
      switch (this.queryType) {
        case 'goodsPositionList':
          list = this.goodsPositionList
          break
        case 'moveGoodsPositionList':
          list = this.moveGoodsPositionList
          break
        case 'moveContainerCodeList':
          list = this.moveContainerCodeList
          break
        case 'usableFactoryPositionList':
          list = this.usableFactoryPositionList
          break
        default:
          list = this.fpCodeList
          break
      }
      if (this.value && typeof this.value === 'number') {
        query = list.find(t => t.value === this.value)?.label ?? ''
      }
      if (query === '' || query === undefined) {
        this.options = list.slice(0, 100)
      } else {
        let result = []
        setTimeout(() => {
          result = list.filter(item => {
            console.log('labelText:', this.labelText)
            return item[this.labelText].toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          })
          this.options = result.slice(0, 100)
        }, 200)
      }
    },
    clear() {
      this.filterMethod()
    }
  }
}
</script>

<style lang="less">
.el-select-dropdown__wrap {
  // max-width: 400px !important;
}
</style>
