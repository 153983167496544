import { t } from '@/libs_sz/locale'
export default {
  namespaced: true,
  state: {
    // 接口类型
    interfaceTypeList: [],
    // 请求体
    reqeustBodyList: [],
    // 执行接口
    execInterfaceList: [],
    // 执行命令
    execInstructionList: [],
    // 通讯状态
    requestStatusList: [
      {
        label: t('lang.gles.common.success'),
        value: 0
      },
      {
        label: t('lang.gles.common.failed'),
        value: 1
      }
    ],
    // 通讯状态
    taskStatusList: [
      {
        label: t('lang.gles.interface.normal'),
        value: 0
      },
      {
        label: t('lang.gles.interface.abnormal'),
        value: 1
      }
    ],
    // 单据状态
    receiptStatusList: [
      {
        label: t('lang.gles.interface.normal'),
        value: 0
      },
      {
        label: t('lang.gles.interface.abnormal'),
        value: 1
      }
    ],
    // 应答代码
    responseCodeList: []
  },
  mutations: {
    changeState(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map((item) => {
        state[item] = payLoad[item]
        return true
      })
    }
  },
  actions: {
    /**
     * 接口类型
     * @param {*} params
     */
    getInterfaceTypeList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'interfaceType' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          interfaceTypeList: list
        })
      })
    },
    /**
     * 请求主体
     * @param {*} param
     * @param {*} payLoad
     */
    getReqeustBodyList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'reqeustBody' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          reqeustBodyList: list
        })
      })
    },
    /**
     * 执行接口
     * @param {*} param
     * @param {*} payLoad
     */
    getExecInterfaceList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'execInterface' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          execInterfaceList: list
        })
      })
    },
    /**
     * 执行命令
     * @param {*} param
     * @param {*} payLoad
     */
    getExecInstructionList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'execInstruction' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          execInstructionList: list
        })
      })
    },
    /**
     * 通讯状态
     * @param {*} param
     * @param {*} payLoad
     */
    getRequestStatusList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'requestStatus' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          requestStatusList: list
        })
      })
    },
    /**
     * 单据状态
     * @param {*} param
     * @param {*} payLoad
     */
    getReceiptStatusList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'receiptStatus' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          receiptStatusList: list
        })
      })
    },
    /**
     * 应答状态
     * @param {*} param
     * @param {*} payLoad
     */
    getResponseCodeList({ dispatch, commit, state }, params) {
      dispatch('getSelectOptions', {
        apiName: 'queryDictByCode',
        params: { dictCode: 'responseCode' }
      }, { root: true }).then((list) => {
        commit('changeState', {
          responseCodeList: list
        })
      })
    }
  }
}

