<template>
  <div>
    <!-- 过滤关键字  -->
    <el-input
      v-model="importantKey"
      :placeholder="$t('libsSz.key26')"
      size="small"
      class="mt20 mb20 pct33"
      prefix-icon="el-icon-search"
    />
    <m-table
      ref="myTable"
      :table-data="myTableData"
      :extend-config="extendTableConfig"
      :table-item="tableItem"
      :page-data="null"
      @select="handleSelect"
      @select-all="handleSelectAll"
      v-on="$listeners"
    />
  </div>
</template>
<script>
import MTable from "../../MTable/MTable";

export default {
  props:{
    tableData:{
      type: Array,
      required:true,
    },
    tableItem:{
      type: Array,
      required:true,
    },
    // 默认的check
    userCheckVal:{
      type: Array,
      default(){
        return []
      },
    },
  },
  data(){
    return {
      extendTableConfig:{
        checkBox:true,
        sortNum: false, // 是否排序
        operate: false, // 需要操作按钮
      },
      importantKey:"",
      checkArr: [],
    }
  },
  mounted(){
    // 回填默认选择的

  },
  watch:{
    userCheckVal(newVal, oldVal){
      this.checkArr = this.tableData.filter(item => newVal.includes(item.userId));
      this.updateSelection();
    },
  },
  updated(){
    // 数据更新完则更新勾选
    this.updateSelection();
  },
  methods:{
    // 更新已经勾选的项
    updateSelection(){
      const that = this;
      const newVal = this.checkArr;
      if(newVal && newVal.length>0 ){
        newVal.map(item => {
          that.$refs.myTable.getTable().toggleRowSelection(item);
        });
      }
    },
    // 处理勾选
    handleSelect(selection, row){
      const add = selection.find(item => item.userId === row.userId);
      // 新的新增
      if(add){
        row && this.checkArr.push(row);
        // 取消掉
      }else{
        const index = this.checkArr.findIndex(item => item.userId === row.userId);
        this.checkArr.splice(index,1);
      }
    },
    // 全选/全取消
    handleSelectAll(selection){
      this.checkArr = selection;
    },
    getAllSelect(){
      return this.checkArr.map(item => item.userId);
    },
  },
  computed:{
    myTableData(){
      return this.importantKey ? this.tableData.filter(item=> [item.realName, item.userName, item.phone].some(c=> !!~(String(c)||"").indexOf(this.importantKey))): this.tableData;
    },
  },
  components:{
    MTable,
  },
}
</script>
