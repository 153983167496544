<template>
  <div
    draggable
    :class="`ui-mDragItem js-mDragItem ${className}`"
    @dragstart.stop="_handleDragStart"
    @dragend.stop="_handleDragEnd"
    @dragenter.stop="_handleDragEnter"
  >
    <!-- 内容区 -->
    <div class="ui-mDragItem__content">
      <slot />
    </div>
    <!-- 可以拖到此处 -->
    <div v-if="!disabled && showDragTips" class="ui-mDragItem_canDrag">拖到此处</div>
  </div>
</template>
<script>
/**
 *  Attribute
 *  @param { className } 包裹元素class
 *  @param { disabled } 是否不可点击  -- 暂时未实现
 *
 *  功能点：
 *  1. 支持【拖到此处样式】
 *  2. 外层不可以包裹元素
 */

import emitter from "element-ui/src/mixins/emitter";

export default {
  name: "MDragItem",
  componentName: "MDragItem",
  mixins: [emitter],
  props: {
    className: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDragTips: false,
    };
  },
  mounted() {
    this.dispatch("MDragWrap", "add:child", this.$el);
  },
  destroyed() {
    this.dispatch("MDragWrap", "remove:child", this.$el);
  },
  methods: {
    dragStart(el) {
      if (el !== this.$el) {
        this.showDragTips = true;
      }
    },
    dragEnd() {
      this.showDragTips = false;
    },
    _handleDragStart() {
      this.$el.style.opacity = "0.3";
      this.dispatch("MDragWrap", "drag:start", this.$el);
    },
    _handleDragEnd() {
      this.$el.style.opacity = "1";
      this.dispatch("MDragWrap", "drag:end");
    },
    _handleDragEnter() {
      this.dispatch("MDragWrap", "drag:enter", this.$el);
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes shake {
  0% {
    transform: translate3d(-10%, 0, 0);
  }
  50% {
    transform: translate3d(10%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.ui-mDragItem {
  position: relative;
  display: inline-block;
  animation: shake 0.3s;
  height: 100%;
  .ui-mDragItem__content {
    height: 100%;
  }
  .ui-mDragItem_canDrag {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(255, 255, 255, 063);
    border: 2px dashed #409eff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #409eff;
    opacity: 0.7;
    z-index: 2;
  }
}
</style>
