/**
 * G-LES main.js
 * ✨这里的顺序请不要变更, 可能会导致国际化或样式问题✨
 */

import Vue from 'vue'
// 加载模板
import App from '@/App'

/** 加载Plugin 这里的顺序不要换
 * @/plugins/ElementUi 中做了一些定制, 需要在libs_sz之后加载, 否则会被覆盖
 * @/libs_sz/theme/index.scss 需要最后加载, 否则会被elementUI的css样式覆盖
 */
import '@/i18n/i18n'
import i18n from '@/plugins/LoadLang'
import Libs from '@/libs_sz'
import '@/plugins/ElementUi'
import '@/libs_sz/theme/index.scss'

/** 加载工具相关 */
import _ from 'lodash'
import tool from '@/utils/tool'

/** vue全家桶 */
import store from '@/store'
import router from '@/router'

/** routerBefore */
import * as types from '@/store/mutationTypes'
import { isGetSessionId, getNoAuthSessionId } from '@/utils/getSessionId'
import { IGNORE_ROUTING_GUARD } from './../gles.vue.config'
// 加载自定义指令
import Directives from './directives'
Vue.use(Directives)

// 加载网络请求
import '@/assets/icons'
import '@/plugins/httpService'
import AvaSelect from '@/components/AvaSelect'
import remoteSelect from '@/components/remoteSelect'

Vue.use(AvaSelect)
Vue.use(remoteSelect)

Vue.use(Libs)
Vue.prototype._ = _
Vue.prototype.$tool = tool

/** 下一步操作 */
const successNext = (to, next) => {
  // 修改标题
  store.commit(types.ROUTE_INFO, to)
  next()
}

/** 注册beforeRouter守卫 */
router.beforeEach((to, from, next) => {
  // 如果第一次载入有语言则，设置默认语言
  const { language } = to.query
  if (router.app && language) {
    router.app.$setLangs(language)
  }

  // 是否绕开路由守卫, 如果权限接口超时严重或有其他接口问题, 可以暂时绕开
  // 仅在本地npm start时生效
  if (IGNORE_ROUTING_GUARD && process.env.NODE_ENV === 'development') {
    return getNoAuthSessionId().then(() => {
      successNext(to, next)
    })
  }

  // 过滤不存在的URL
  if (to.matched.length === 0) {
    from.name ? next({ name: from.name }) : next('/home')
  } else if (
    to.matched.some(
      (record) =>
        // eslint-disable-next-line no-prototype-builtins
        !Object.assign({}, record.meta).hasOwnProperty('isValiLogin') ||
        record.meta.isValiLogin
    )
  ) {
    isGetSessionId().then(() => {
      successNext(to, next)
    })
  } else {
    successNext(to, next)
  }
})

/**
 * 这里是为了解决pad模式下, 某些浏览器版本, 点击输入框无法呼出虚拟键盘的处理
 */
document.body.addEventListener('click', ({ target }) => {
  if (target.nodeName === 'INPUT') {
    target.focus()
  }
})

Vue.prototype.$EventBus = new Vue()
window.vm = new Vue({
  el: '#app',
  router,
  i18n,
  store,
  components: { App },
  template: '<App/>'
})
