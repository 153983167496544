import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'

/**
 * 上架策略, 应用配置/策略配置
 * 命中策略, 应用配置/策略配置
 * 货位策略, 策略配置
 * 理货策略,策略配置
 */
const apis = {
  addStrategyConfig: {
    method: 'post',
    url: `${glesRoot}/strategy/config/addStrategyConfig`,
    describe: '新增配置',
    isNeedLoading: false
  },
  enableOrDisableConfig: {
    method: 'post',
    url: `${glesRoot}/strategy/config/enableOrDisableConfig`,
    describe: '启用/停用接口',
    isNeedLoading: false
  },
  queryStrategyConfigByPage: {
    method: 'post',
    url: `${glesRoot}/strategy/config/queryStrategyConfigByPage`,
    describe: '查询配置接口',
    isNeedLoading: false
  },
  updateConfig: {
    method: 'post',
    url: `${glesRoot}/strategy/config/updateConfig`,
    describe: '更新配置',
    isNeedLoading: false
  },
  deleteConfig: {
    method: 'post',
    url: `${glesRoot}/strategy/config/deleteConfig`,
    describe: '删除配置',
    isNeedLoading: false
  },
  queryConfigDetail: {
    method: 'post',
    url: `${glesRoot}/strategy/config/queryConfigById`,
    describe: '应用配置详情',
    isNeedLoading: false
  },
  // 上架策略 -- 策略配置
  queryShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/listPage`,
    describe: '查询',
    isNeedLoading: false
  },
  queryShelfOptions: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/getList`,
    describe: '查询',
    isNeedLoading: false
  },
  detailShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/detail`,
    describe: '详情',
    isNeedLoading: false
  },
  addShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/add`,
    describe: '新增',
    isNeedLoading: false
  },
  updateShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/update`,
    describe: '更新',
    isNeedLoading: false
  },
  copyShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/copy`,
    describe: '复制',
    isNeedLoading: false
  },
  deleteShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/delete`,
    describe: '删除',
    isNeedLoading: false
  },
  enableShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/enable`,
    describe: '启用上架策略接口',
    isNeedLoading: false
  },
  disableShelf: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/disable`,
    describe: '禁用上架策略接口',
    isNeedLoading: false
  },
  getContainerTypeAndCode: {
    method: 'post',
    url: `${glesRoot}/strategy/v1/shelf/getContainerTypeListByTargetType`,
    describe: '查询所有容器类型数据',
    isNeedLoading: false
  },
  getGoodsPositionNum: {
    method: 'post',
    url: `${glesRoot}/base/container/type/goods/position/getGoodPosNumByContainerType`,
    describe: '根据容器类型查询货位序号',
    isNeedLoading: false
  },
  // 命中策略 -- 应用配置
  addHitStrategyConfig: {
    method: 'post',
    url: `${glesRoot}/hitStrategy/config/addStrategyConfig`,
    describe: '新增配置',
    isNeedLoading: false
  },
  enableOrDisableHitConfig: {
    method: 'post',
    url: `${glesRoot}/hitStrategy/config/enableOrDisableConfig`,
    describe: '启用/停用接口',
    isNeedLoading: false
  },
  queryHitStrategyConfigByPage: {
    method: 'post',
    url: `${glesRoot}/hitStrategy/config/queryStrategyConfigByPage`,
    describe: '查询配置接口',
    isNeedLoading: false
  },
  updateHitConfig: {
    method: 'post',
    url: `${glesRoot}/hitStrategy/config/updateConfig`,
    describe: '更新配置',
    isNeedLoading: false
  },
  deleteHitConfig: {
    method: 'post',
    url: `${glesRoot}/hitStrategy/config/deleteConfig`,
    describe: '删除配置',
    isNeedLoading: false
  },
  queryHitConfigDetail: {
    method: 'post',
    url: `${glesRoot}/hitStrategy/config/queryConfigById`,
    describe: '应用配置详情',
    isNeedLoading: false
  },
  // 命中策略 -- 策略配置
  queryHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/listPage`,
    describe: '查询',
    isNeedLoading: false
  },
  queryHitOptions: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/list`,
    describe: '查询',
    isNeedLoading: false
  },
  detailHit: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/detail`,
    describe: '详情',
    isNeedLoading: false
  },
  addHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/add`,
    describe: '新增',
    isNeedLoading: false
  },
  updateHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/update`,
    describe: '更新',
    isNeedLoading: false
  },
  copyHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/copy`,
    describe: '复制',
    isNeedLoading: false
  },
  deleteHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/delete`,
    describe: '删除',
    isNeedLoading: false
  },
  enableHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/enable`,
    describe: '启用命中策略接口',
    isNeedLoading: false
  },
  disableHitStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/hit/v1/disable`,
    describe: '禁用命中策略接口',
    isNeedLoading: false
  },
  // 货位策略 -- 策略配置
  addGoodsPositionStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/robot/addStrategy`,
    describe: '新增配置',
    isNeedLoading: false
  },
  enableOrDisableGoodsPositionStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/robot/enableOrDisable`,
    describe: '启用/停用接口',
    isNeedLoading: false
  },
  queryGoodsPositionStrategyByPage: {
    method: 'post',
    url: `${glesRoot}/strategy/robot/listByPage`,
    describe: '查询配置接口',
    isNeedLoading: false
  },
  updateGoodsPositionStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/robot/updateStrategy`,
    describe: '更新',
    isNeedLoading: false
  },
  deleteGoodsPositionStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/robot/deleteStrategy`,
    describe: '删除',
    isNeedLoading: false
  },
  queryGoodsPositionStrategyDetail: {
    method: 'post',
    url: `${glesRoot}/strategy/robot/queryStrategyById`,
    describe: '详情',
    isNeedLoading: false
  },
  // 理货策略 -- 策略配置
  queryTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/listPage`,
    describe: '查询',
    isNeedLoading: false
  },
  // queryShelfOptions: {
  //   method: 'post',
  //   url: `${glesRoot}/strategy/v1/shelf/getList`,
  //   describe: '查询',
  //   isNeedLoading: false
  // },
  detailTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/detail`,
    describe: '详情',
    isNeedLoading: false
  },
  addTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/add`,
    describe: '新增',
    isNeedLoading: false
  },
  updateTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/update`,
    describe: '更新',
    isNeedLoading: false
  },
  deleteTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/delete`,
    describe: '删除',
    isNeedLoading: false
  },
  enableTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/enable`,
    describe: '启用理货策略接口',
    isNeedLoading: false
  },
  disableTallyStrategy: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/disable`,
    describe: '禁用理货策略接口',
    isNeedLoading: false
  },
  getTallyDictList: {
    method: 'post',
    url: `${glesRoot}/strategy/tally/getIdAndName`,
    describe: '获取理货策略下拉列表',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
