import jaLocale from "element-ui/lib/locale/lang/ja";

const ja = {
  ...jaLocale,
  libsSz: {
    key1: "ユーザー名",
    key2: "パスワード",
    key3: "登録",
    key4: "ログアウト",
    key5:
      "申し訳ございませんが、あなたはこのページにアクセスする権限がありません",
    key6: "前のページに戻ります",
    key7: "申し訳ございませんが、あなたがアクセスしたページは存在しません",
    key8: "開く",
    key9: "綴じる",
    key10: "照会",
    key11: "リセット",
    key12: "No",
    key13: "操作",
    key14: "キャンセル",
    key15: "確認",
    key16: "ロード中…",
    key17: "パスワードの変更",
    key18: "ロール名称",
    key19: "ロール説明",
    key20: "権限のタイプ",
    key21: "権限サブシステム",
    key22: "入力してください",
    key23: "選択してください",
    key24: "ページの権限",
    key25: "関連ユーザー",
    key26: "キーワードを入力してフィルタリングを行ってください",
    key27: "氏名",
    key28: "電話",
    key29: "権限の有効",
    key30: "権限の無効",
    key31: "必須記入項目を入力してください",
    key32: "请选择需要授权的子系统",
    key33: "割り当て",
    key34: "禁止输入特殊字符",
    key35: "必須記入項目を入力してください",
    key36: "最大制限入力{0}文字",
    key37: "合法的なIPアドレスを入力してください",
    key38: "整数を入力してください",
    key39: "保存",
    key40: "編集",
    key41: "保存に失敗",
    key42: "この行の編集をキャンセルするかどうか決定します",
    key43: "自然数を入力してください",
    key44: "正しい携帯番号を入力してください",
    key45: "データがありません",
    key46: "{0}文字を入力してください",
    key47: "選択されていません",
  },
};

export default ja;
