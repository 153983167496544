<template>
  <div v-show="!isLoading" class="scanner-container">
    <video ref="scanner" poster="data:image/gif,AAAA" />
    <div class="overlay-element" />
    <div class="laser" />
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from "@zxing/library";

export default {
  name: "StreamBarcodeReader",
  data() {
    return {
      timer: null,
      isLoading: true,
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices,
    };
  },
  mounted() {
    if (!this.isMediaStreamAPISupported) {
      this.$emit("loaded", this.isLoading);
    }
    this.start();
    this.openScan();
    this.$refs.scanner.oncanplay = event => {
      this.isLoading = false;
      this.$emit("loaded", this.isLoading);
    };
  },
  beforeDestroy() {
    this.codeReader.reset();
    this.timer && window.clearTimeout(this.timer);
  },
  methods: {
    async openScan() {
      this.timer = setTimeout(() => {
        this.$emit("loaded", this.isLoading);
        this.timer && window.clearTimeout(this.timer);
      }, 8000);
    },
    start() {
      this.codeReader.decodeFromVideoDevice(undefined, this.$refs.scanner, (result, err) => {
        if (result) this.$emit("decode", result.text);
        if (err) console.warn(err);
      });
    },
  },
};
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
}
.scanner-container {
  position: relative;
}

.overlay-element {
  position: absolute;
  top: 0;
  width: 100%;
  height: 99%;
  background: rgba(30, 30, 30, 0.5);
  -webkit-clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
}

.laser {
  width: 60%;
  margin-left: 20%;
  background-color: tomato;
  height: 1px;
  position: absolute;
  top: 40%;
  z-index: 2;
  box-shadow: 0 0 4px red;
  -webkit-animation: scanning 2s infinite;
  animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
@keyframes scanning {
  50% {
    -webkit-transform: translateY(75px);
    transform: translateY(75px);
  }
}
</style>
