var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"el-tree",class:{
    'el-tree--highlight-current': _vm.highlightCurrent,
    'is-dragging': !!_vm.dragState.draggingNode,
    'is-drop-not-allow': !_vm.dragState.allowDrop,
    'is-drop-inner': _vm.dragState.dropType === 'inner',
  },attrs:{"role":"tree"}},[(_vm.height)?_c('virtual-list',{ref:"virtualList",style:({ 'max-height': _vm.height, 'overflow-y': 'auto' }),attrs:{"data-key":_vm.getNodeKey,"data-sources":_vm.visibleList,"data-component":_vm.itemComponent,"extra-props":{
      renderAfterExpand: _vm.renderAfterExpand,
      showCheckbox: _vm.showCheckbox,
      renderContent: _vm.renderContent,
      onNodeExpand: _vm.handleNodeExpand,
    }}}):_vm._l((_vm.root.childNodes),function(child){return _c('m-tree-node',{key:_vm.getNodeKey(child),attrs:{"node":child,"props":_vm.props,"render-after-expand":_vm.renderAfterExpand,"show-checkbox":_vm.showCheckbox,"render-content":_vm.renderContent},on:{"node-expand":_vm.handleNodeExpand}})}),(_vm.isEmpty)?_c('div',{staticClass:"el-tree__empty-block"},[_c('span',{staticClass:"el-tree__empty-text"},[_vm._v(_vm._s(_vm.emptyText))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dragState.showDropIndicator),expression:"dragState.showDropIndicator"}],ref:"dropIndicator",staticClass:"el-tree__drop-indicator"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }