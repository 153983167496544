import { t } from "../locale";
import { httpService } from "../plugins/httpService";
export const isPromise = obj =>
  !obj && (typeof obj === "object" || typeof obj === "function") && typeof obj.then === "function";


/** 处理国际化
 *  @param { String } str
 */
export const translateMessage = str => {
  if (!str) return str;
  if (typeof str === "string" && str.startsWith("lang.")) return t(str);
  let item;
  // 判断如果不是Object直接返回；
  try {
    item = JSON.parse(str);
    if (Object.prototype.toString.call(item) !== "[object Object]") {
      return str;
    }
  } catch (error) {
    return str;
  }
  if (item.p) {
    return t(
      item.c,
      item.p.map(x => translateMessage(x)),
    );
  }
  return t(item.c);
};

/**
 * 导出文件通用方法 / 防止以后加权限；
 * @param {*} apiStore， 必须传入对象，调用httpService实现
 * @param {*} success 成功
 * @param {*} fail fail回调。
 */
export const downLoadFile =async ({ apiStore, params, fileName }, fail, success) => {
  if(typeof apiStore !== "object"){
    throw new Error("libs -> downLoadFile，必须传入apistore对象");
  }
  try{
    const { data:res, headers } = await httpService({ ...apiStore, config: { responseType:"blob" } }, params || {} );
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(res);
    const filename = fileName || headers["content-disposition"].split(";")[1].split("=")[1];
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    success && success();
  }catch(e){
    fail && fail();
  };
};
