import deLocale from "element-ui/lib/locale/lang/de";

const de = {
  ...deLocale,
  libsSz: {
    key1: "Name des Benutzers",
    key2: "Passwort",
    key3: "Melden Sie sich",
    key4: "Loggen Sie sich",
    key5: "Leider haben Sie keinen Zugriff auf diese Seite",
    key6: "Zurück zur vorherigen Seite",
    key7: "Sorry, die Seite, die du besucht hast, existiert nicht",
    key8: "geöffnet",
    key9: "Leg es weg.",
    key10: "Abfrage",
    key11: "Zurücksetzen",
    key12: "Laufende Nummer",
    key13: "Betrieb",
    key14: "Stornieren",
    key15: "bestimmen",
    key16: "Laden...",
    key17: "Passwort ändern",
    key18: "Name der Rolle",
    key19: "Beschreibung der Rolle",
    key20: "Typ der Genehmigung",
    key21: "Teilsystem Zulassung",
    key22: "Bitte geben Sie ein",
    key23: "Bitte wählen",
    key24: "Zugriffsrechte auf Seiten",
    key25: "Assoziierter Benutzer",
    key26: "Bitte geben Sie Schlüsselwörter ein, um zu filtern",
    key27: "vollständiger Name",
    key28: "Telefon",
    key29: "Berechtigungen aktivieren",
    key30: "Deaktivieren von Berechtigungen",
    key31: "Bitte geben Sie die gewünschten Artikel ein!",
    key32: "Bitte wählen Sie das Teilsystem, das eine Genehmigung benötigt",
    key33: "Verteilung",
    key34: "Geben Sie keine Sonderzeichen ein",
    key35: "Bitte geben Sie die gewünschten Artikel ein!",
    key36: "Die maximale Grenze ist {0} Zeichen",
    key37: "Bitte geben Sie Ihre IP-Adresse ein",
    key38: "Bitte geben Sie eine positive Ganzzahl ein",
    key39: "Erhaltung",
    key40: "edit",
    key41: "Es gibt nicht gespeicherte Editierelemente. Bitte speichern Sie sie zuerst!",
    key42: "Sind Sie sicher, die Bearbeitung dieser Zeile zu stornieren",
    key43: "Bitte geben Sie natürliche Nummer ein",
    key44: "Bitte geben Sie die richtige Mobiltelefonnummer ein",
    key45: "Keine Daten verfügbar",
    key46: "Bitte geben Sie das {0} Zeichen ein",
    key47: "Nicht ausgewählt",
    key48: "Ausgewählte",
    key49: "Feedback",
    key50: "Zum lokalen Download",
    key51: "Laden der Bilder fehlgeschlagen",
    key52: "NavigatorName",
    key53: "Widerruf",
    key54: "Wiederherstellen",
    key55: "Löschen",
    key56: "vergrößern",
    key57: "schmal",
    key58: "Anpassung an Leinwand",
    key59: "Tatsächliche Größe",
    key60: "Nach der Hierarchie",
    key61: "Vorne Ebene",
    key62: "Mehrere Auswahl",
    key63: "Leinwand",
    key64: "Ausrichtung des Gitters",
    key65: "Nur Zahlen oder englische Zeichen können eingegeben werden",
    key66: "Nur 1-65535 positive Ganzzahlen können eingegeben werden",
  },
};

export default de;
