import config from '@/utils/config'
const { lesRoot } = config
/**
 * 基础数据：工厂、车间、仓库、区域、库区、产线、设备、库位、货位相关接口
 */
const apis = {
  // 车间 增删改查
  getWorkshopList: {
    method: 'post',
    url: `${lesRoot}/base/workshop/listWorkshopByPage`,
    describe: '查询车间列表',
    isNeedLoading: true
  },
  insertWorkshop: {
    method: 'post',
    url: `${lesRoot}/base/workshop/insertWorkshop`,
    describe: '新增车间',
    isNeedLoading: true
  },
  deleteWorkshop: {
    method: 'get',
    url: `${lesRoot}/base/workshop/deleteWorkshop`,
    describe: '删除车间',
    isNeedLoading: true
  },
  updateWorkshop: {
    method: 'post',
    url: `${lesRoot}/base/workshop/updateWorkshop`,
    describe: '编辑车间',
    isNeedLoading: true
  }
}

export default function(api, params, extendedUrl) {
  if (!apis[api]) return {}

  let queryStr = '?'
  Object.keys(params).forEach((key) => {
    queryStr += `${key}=${params[key]}`
  })
  if (extendedUrl !== undefined && extendedUrl !== null) {
    apis[api].url = `${apis[api].url}/${extendedUrl}`
  }
  if (queryStr) {
    apis[api].url = `${apis[api].url}${queryStr}`
  }
  return apis[api]
}
