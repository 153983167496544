import { t } from '@/libs_sz/locale'
export default {
  namespaced: true,
  state: {
    factoryList: [],
    workshopList: [],
    warehouseList: [],
    warehouseTypeList: [],
    warehouseAreaList: [],
    areaList: [],
    productionLineList: [],
    statusList: [],
    storeInStatusList: [],
    storeOutStatusList: [],
    storeInTypeList: [],
    robotWorkTypeList: [],
    taskStatusList: [],
    storeOutTypeList: [],
    materialsBillReceiptTypeList: [],
    receiptTypeList: [], // 合并后的单据类型
    stockAdjustOrderStatusList: [],
    materialsBillStatusList: [],
    operatingModeList: [],
    tallyReceiptStatusList: [],
    receiptStatusList: [],
    flagList: [
      {
        label: t('lang.gles.common.yes'),
        value: 0
      },
      {
        label: t('lang.gles.common.no'),
        value: 1
      }
    ],
    // 容器形态字典表
    containerTypePatternList: [],
    directionList: [
      {
        label: t('lang.gles.base.oneSide'),
        value: 'F'
      },
      {
        label: t('lang.gles.base.twoSides'),
        value: 'FB'
      },
      {
        label: t('lang.gles.base.fourSides'),
        value: 'FBLR'
      }
    ],
    containerDirectionList: [],
    // 容器类型列表
    containerTypeList: [],
    // 容器列表
    containerCodeList: [],
    // 26位大写字母
    floorNumList: [...new Array(26)].map((e, index) =>
      String.fromCharCode(index + 65)
    ),
    // 容器形态类型字典表
    containerTypePatternTypeList: [],
    mapNodeList: [
      {
        label: '地图节点1',
        value: 1
      },
      {
        label: '地图节点1',
        value: 2
      }
    ],
    // 库位列表
    factoryPositionList: [],
    factoryPositionTypeList: [],
    // 启用/禁用状态
    statusFlagList: [],
    // 容器状态 正常、占用、在途占用
    containerStatusList: [],
    freezeFlagList: [
      {
        label: t('lang.gles.base.normal'),
        value: 0
      },
      {
        label: t('lang.gles.base.occupy'),
        value: 1
      }
    ],
    gradeStatusList: [],
    deviceList: [],
    deviceGoodsPositionList: [], // 设备货位
    // 系统管理-系统分组
    systemParamGroupList: [],
    // 货位类型
    goodsPositionTypeList: [],
    // 基础货位列表
    baseGoodsPositionTypeList: [],
    // 作业节点类型  上料、下料、取料...
    subTaskTypeList: [],
    // A/B 轴 （A轴、B轴）
    abAxisList: [],
    // A/B 轴 （是、否）
    abAxisConnectList: []
  },
  mutations: {
    changeState(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map((item) => {
        state[item] = payLoad[item]
        return true
      })
    }
  },
  actions: {
    /**
     * 获取工厂列表
     */
    async getFactoryList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getFactoryDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          factoryList: list
        })
      })
    },
    /**
     * 获取仓库分类列表
     */
    async getWarehouseTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'warehouseType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          warehouseTypeList: list
        })
      })
    },
    /**
     * 获取车间列表
     */
    getWorkshopList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getWorkshopDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          workshopList: list
        })
      })
    },
    /**
     * 获取仓库列表
     */
    getWarehouseList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getWarehouseDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          warehouseList: list
        })
      })
    },
    /**
     * 获取库区列表
     */
    getWarehouseAreaList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getWarehouseAreaDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          warehouseAreaList: list
        })
      })
    },
    /**
     * 获取区域列表
     */
    getAreaList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getAreaDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          areaList: list
        })
      })
    },
    /**
     * 获取产线列表
     */
    getProductionLineList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getProductionLineDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          productionLineList: list
        })
      })
    },
    /**
     * 获取产线列表
     */
    getDeviceList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getDeviceDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          deviceList: list
        })
      })
    },
    /**
     * 获取库位列表
     */
    getFactoryPositionList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getFactoryPositionDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          factoryPositionList: list
        })
      })
    },
    /**
     * 获取库位类型列表
     */
    async getFactoryPositionTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'factoryPositionType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          factoryPositionTypeList: list
        })
      })
    },
    /**
     * 获取设备货位列表
     */
    getDeviceGoodsPositionList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getGoodsPositionListByType',
          params: {
            goodsPositionType: 'deviceGoodsPositionSec'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          deviceGoodsPositionList: list
        })
      })
    },
    /**
     * 获取容器形态列表
     * @param {*} params
     */
    async getContainerTypePatternList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'containerTypePattern'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          containerTypePatternList: list
        })
      })
    },
    /**
     * 获取容器面列表
     * @param {*} params
     */
    async getContainerDirectionList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'containerTypeDirection'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          containerDirectionList: list
        })
      })
    },
    /**
     * 获取容器面列表
     * @param {*} params
     */
    async getContainerTypePatternTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'containerTypePatternType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          containerTypePatternTypeList: list
        })
      })
    },
    /**
     * 获取容器类型列表
     * @param {*} params
     */
    getContainerTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getContainerTypeDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          containerTypeList: list
        })
      })
    },
    /**
     * 获取容器类型列表
     * @param {*} params
     */
    getContainerCodeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'getContainerDictList',
          params
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          containerCodeList: list
        })
      })
    },
    /**
     * 获取容器类型列表
     * @param {*} params
     */
    async getContainerStatusList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'containerStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          containerStatusList: list
        })
      })
    },
    /**
     * 获取启用状态
     * @param {*} params
     */
    async getStatusFlagList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'statusFlag'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          statusFlagList: list
        })
      })
    },
    /**
     * 获取等级状态
     * @param {*} params
     */
    getGradeStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'gradeStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          gradeStatusList: list
        })
      })
    },
    /**
     * 获取入库单据状态
     * @param {*} params
     */
    getStoreInStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'storeInStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          storeInStatusList: list
        })
      })
    },
    /**
     * 获取出库单据状态
     * @param {*} params
     */
    getStoreOutStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'storeOutStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          storeOutStatusList: list
        })
      })
    },
    /**
     * 获取理货单单据状态
     * @param {*} params
     */
    getTallyReceiptStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'tallyReceiptStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          tallyReceiptStatusList: list
        })
      })
    },
    /**
     * 获取单据监控合并单据状态
     * @param {*} params
     */
    getReceiptStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'receiptStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          receiptStatusList: list
        })
      })
    },
    /**
     * 获取入库单据类型
     * @param {*} params
     */
    getStoreInTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'storeInType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          storeInTypeList: list
        })
      })
    },
    /**
     * 任务作业类型
     * @param {*} params
     */
    getRobotWorkTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'robotWorkType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          robotWorkTypeList: list
        })
      })
    },
    /**
     * 作业节点类型（取料、放料、设备上料...）
     * @param {*} params
     */
    getSubTaskTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'subTaskType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          subTaskTypeList: list
        })
      })
    },
    /**
     * 任务状态
     * @param {*} params
     */
    getTaskStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'taskStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          taskStatusList: list
        })
      })
    },
    /**
     * 获取出库单据类型
     * @param {*} params
     */
    getStoreOutTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'storeOutType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          storeOutTypeList: list
        })
      })
    },
    /**
     * 获取上下料单单据类型
     * @param {*} params
     */
    getMaterialsBillReceiptTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'materialsBillReceiptType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          materialsBillReceiptTypeList: list
        })
      })
    },
    /**
     * 获取合并后单据类型
     * @param {*} params
     */
    getReceiptTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'receiptType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          receiptTypeList: list
        })
      })
    },
    /**
     * 获取上下料单单据状态
     * @param {*} params
     */
    getMaterialsBillStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'materialsBillStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          materialsBillStatusList: list
        })
      })
    },
    /**
     * 获取作业类型
     * @param {*} params
     */
    getOperatingModeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'operatingMode'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          operatingModeList: list
        })
      })
    },
    /**
     * 获取作业类型
     * @param {*} params
     */
    getStockAdjustOrderStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'stockAdjustOrderStatus'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          stockAdjustOrderStatusList: list
        })
      })
    },
    /**
     * 获取系统分组
     * @param {*} params
     */
    async getSystemParamGroupList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'systemParamGroup'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          systemParamGroupList: list
        })
      })
    },
    /**
     * 获取货位类型
     * @param {*} params
     */
    getGoodsPositionTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'baseGoodsPositionType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          goodsPositionTypeList: list
        })
      })
    },
    /**
     * 获取基础货位类型
     * @param {*} params
     */
    async getBaseGoodsPositionTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'baseGoodsPositionType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          baseGoodsPositionTypeList: list
        })
      })
    },
    /**
     * AB轴 是/否
     * @param {*} params
     */
    async getAbAxisConnectList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'abAxisConnect'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          abAxisConnectList: list
        })
      })
    },
    /**
     * AB轴 A轴/B轴
     * @param {*} params
     */
    async getAbAxisList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'abAxis'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          abAxisList: list
        })
      })
    }
  }
}
