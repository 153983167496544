<template>
  <el-form ref="myForm_c" :model="formModel" class="myEditTableForm">
    <m-table
      v-bind="$attrs"
      ref="myForm_medittable"
      :table-item="editTableItem"
      :extend-config="editExtendConfig"
      :table-data="editTableData"
      v-on="$listeners"
      @mEditTableEditRow="mEditTableEditRow"
      @mEditTableSaveRow="mEditTableSaveRow"
      @mEditTableCancelRow="mEditTableCancelRow"
    >
      <!-- 传递expand扩展 -->
      <template #expand="scope">
        <slot name="expand" v-bind="scope" />
      </template>
      <!-- 传递expand扩展 -->
      <template #tableAppend>
        <slot name="tableAppend" />
      </template>
      <!-- 传递封装后的Cell的Slot -->
      <template v-for="(item, index) in editTableItem" #[item.slotName]="scope">
        <template v-if="!item.hasOwnProperty('slotName') || !item.slotName" />
        <template v-else>
          <!-- 设置当前的cell   -->
          <el-form-item
            v-if="showComponents(scope, item)"
            :key="index"
            class="mb1"
            :inline-message="true"
            :prop="`${item.formData.name}_${scope.$index}`"
            :rules="item.formData.rules"
          >
            <!-- 动态生成当前的form组件   -->
            <component
              v-bind="getComponents(item.formData)"
              :is="getComponentName(item.formData.component, scope)"
              v-model.trim="formModel[`${item.formData.name}_${scope.$index}`]"
              v-on="item.formData"
              @change="val => formChange(val, item, scope.$index, scope.row)"
            >
              <!-- 支持所有element组件能支持的slot -->
              <template
                v-for="slotKey in Object.keys(item.formData.slotName || {})"
                #[item.formData.slotName[slotKey]]
              >
                <slot v-if="slotKey" :name="slotKey" />
              </template>
              <!-- 针对select组件 -->
              <template v-if="getComponentName(item.formData.component, scope) === 'elSelect' && item.formData.data">
                <el-option
                  v-for="children in item.formData.data"
                  :key="children.value"
                  v-bind="children"
                  :label="children.label"
                  :value="children.value"
                />
              </template>
              <!-- 针对checkboxgroup组件 -->
              <template v-else-if="item.formData.component === 'elCheckboxGroup'">
                <el-checkbox
                  v-for="children in item.formData.data"
                  v-bind="children"
                  :key="children.value"
                  :label="children.value"
                >
                  {{ children.label }}
                </el-checkbox>
              </template>
              <!-- 针对radiogroup组件 -->
              <template v-else-if="item.formData.component === 'elRadioGroup'">
                <el-radio
                  v-for="children in item.formData.data"
                  v-bind="children"
                  :key="children.value"
                  :label="children.value"
                >
                  {{ children.label }}
                </el-radio>
              </template>
            </component>
          </el-form-item>
          <template v-else>{{ formatterCellValue(item, scope) }}</template>
        </template>
      </template>
    </m-table>
  </el-form>
</template>
<script>
/**
 *   继承了MTable组件，扩展了Form处理
 *
 *  @prop { tableData } 当前的tableData数据, 会代理slotName
 *  @prop { tableItem } 当前的table的column配置项
 *  @prop { afterSaveRowFn } 每次保存行之后的行为；
 *  @prop { beforeSaveRowFn } 每次保存行之前的行为，可以允许控制是否保存；
 *  @prop { beforeEditRowFn } 每次点击编辑前，但是要调用callBack方法
 *  @prop { formData } 配置需要替换成form表单的项，name和prop对应
 *  @prop { extendConfig } 同MTable的传参
 *  @prop { addRowTemplate }
 *
 *   1. 每次新增时的模板,默认会获取tableItem里面的prop, 如果有其他参数，需要自己定义这个。
 *   2. 可以维护默认值通过template
 *
 *  其他table属性是都支持的；
 *
 *  @methods { getTableData } 返回当前table的data；
 *  @methods { addRow } 新增一行数据
 *  @methods { getTable } 获取table的ref对象
 *  @methods { removeRow } 支持删除单行和多行
 *  @methods { changeEditTableData } 修改当前的editTable，主要为了兼容children这种数据格式；
 *  @methods { changeFormModel } 修改数据
 *

 *
    功能：
 *       1.  代理MTable数据的Slot
 *       2.  代理传送的事件，mEditTableEditRow/mEditTableEditRow
 *       3.  代理Mtable的tableItem, 和 extendConfig
 *           formData数据同MForm传的一样；
 *       4.  只允许同时编辑一行
 *       5.  不适合实时更新table
 *       6.  所有form表单的change事件，都会代理一下，并且需要主动调用个callback方法
 *
 *  注意：
 *
 *     changeFormModel：开放一个修改formModel的接口，
 *        [{
           name:'',
           index:'', // 当前行
           value:'', // 修改的值
          }]
 *      比较推荐的还是使用在formData,里面加一个mchange方法，只要调用callback即可
 *
 *  数据：
 *
 *   formData [
 *              {
 *                  name:'',
 *                  label:'',
 *                  value: '',
 *                  component:'',
 *                  mchange:'',
 *                  slotName: { [自己要传入MForm的slotName]: [] },
 *                  data:[],
 *                  condition:function({ editForm, $index}){}
 *                  ...                                       // 任何element支持的属性都可以代理
 *               }
 *            ]
 *  tableData [
 *              {
 *                 prop:"",
 *                 label:"",
 *                 formatter:,
 *              }
 *            ]
 *
 */

import MTable from "../MTable/MTable";
import dayjs from 'dayjs';

export default {
  name: "MEditTable",
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    tableItem: {
      type: Array,
      required: true,
    },
    formData: {
      type: Array,
      required: true,
    },
    extendConfig: Object,
    addRowTemplate: Object,
    afterSaveRowFn: Function,
    beforeSaveRowFn: Function,
    beforeEditRowFn: Function,
  },
  data() {
    return {
      formModel: {}, // 当前form数据
      editTableData: [], // 当前tableData;
      editTableItem: [], // 当前整理后的item
    };
  },
  watch: {
    tableData(newVal) {
      //  增加监听
      this.setFormModel();
      //  每次改变tableDate
      this.setInitTableData(newVal);
    },
    formData: {
      handler(newVal) {
        this.setEditTableItem();
      },
      deep: true,
    },
    formModel(newVal) {
      console.log(`新值`, newVal);
    },
  },
  created() {
    // 先设置formModel;
    this.setFormModel();
    //  每次改变tableDate
    this.setInitTableData(this.tableData);
    //  设置tableItem
    this.setEditTableItem();
  },
  computed: {
    // 注入相关配置
    editExtendConfig() {
      let { operate = [], ...others } = this.extendConfig || {};
      if (operate) {
        operate = operate.map(item => {
          const { condition } = item;
          const newCondition = ({ row }) => {
            if (!row.isEdit) {
              return condition ? condition({ row }) : true;
            }
            return false;
          };
          return { ...item, condition: newCondition };
        });
      }
      const addOperate = [
        // 增加编辑按钮
        // {
        //   event: "mEditTableEditRow",
        //   label: this.$t("libsSz.key40"),
        //   condition({ row }) {
        //     return !row.isEdit;
        //   },
        // },
        // 增加保存
        {
          event: "mEditTableSaveRow",
          label: this.$t("libsSz.key39"),
          condition({ row }) {
            return row.isEdit;
          },
        },
        // 增加取消操作
        {
          event: "mEditTableCancelRow",
          label: this.$t("libsSz.key14"),
          confirm: true,
          confirmMessage: this.$t("libsSz.key42"),
          condition({ row }) {
            return row.isEdit;
          },
        },
      ];
      return { ...others, operate: [...addOperate, ...operate] };
    },
  },
  methods: {
    // 设置tableItem
    setEditTableItem() {
      const getEditTableItem = this.editTableItem;
      this.editTableItem.splice(0, getEditTableItem.length);
      this.tableItem.map(item => {
        const itemFormData = this.formData.find(c => String(c.name) === String(item.prop)) || {};
        let result = {
          ...item,
          isEdit: false,
          formData: itemFormData,
        };
        if (JSON.stringify(itemFormData) !== "{}") {
          result = { ...result, slotName: `myEditTable${item.prop}` };
        }
        this.editTableItem.push(result);
      });
    },
    // 设置初始table
    setInitTableData(data) {
      this.editTableData = (data || []).map(item => ({
        ...item,
        isEdit: false,
        isNew: false,
      }));
    },
    // 设置formModel()
    setFormModel() {
      const length = this.tableData.length;
      const newFormModel = {};
      if (length) {
        for (let i = 0; i < length; i++) {
          this.formData.forEach(item => {
            newFormModel[`${item.name}_${i}`] = "";
          });
        }
      }
      this.formModel = Object.assign({}, this.formModel, newFormModel);
    },
    //
    showComponents({ row, $index }, { formData }) {
      const { isEdit } = row;
      const { condition } = formData;
      const params = this.getCurrentRowFormModelKeys($index);
      const editForm = {};
      params.forEach(item => {
        editForm[item.replace(`_${$index}`, "")] = this.formModel[item];
      });
      return condition ? isEdit && condition({ editForm, $index }) : isEdit;
    },
    getComponents(config) {
      const value = JSON.parse(JSON.stringify(config));
      const defaultConfig = {
        size: "small",
        autocomplete: "off",
        ...value,
      };
      return defaultConfig;
    },
    // format当前字段
    formatterCellValue({ formatter = null, prop }, { row, $index }) {
      if (formatter) {
        // 存在formatter
        if (typeof formatter === "function") {
          return formatter(row, prop, row[prop], $index);
        } else if (typeof formatter === "object") {
          // Object的时候
          if (formatter.type === "time") {
            if (row[prop]) {
              return dayjs(row[prop]).format("YYYY-MM-DD HH:mm:ss");
            }
            return row[prop] || "";
          }
        }
      }
      return row[prop] || "";
    },
    // 编辑操作
    mEditTableEditRow({ row, $index }) {
      // 编辑当前行
      if (row) {
        // 赋编辑初始值；
        this.formData.forEach(({ name }) => {
          this.formModel[`${name}_${$index}`] = String(row[name] || "");
        });
      }
      // 标记当前非新增记录
      row.isNew = false;
      // 点击编辑按钮之前的hook;
      if (this.beforeEditRowFn) {
        this.beforeEditRowFn({
          row,
          $index,
          cb() {
            row.isEdit = true;
          },
        });
      } else {
        // 改变当前行的状态
        row.isEdit = true;
      }
    },
    /**
     *  保存操作逻辑
     *
     *   1. 本意想通过validateField，验证本行的数据，但是有问题，转而验证全局的validate，因为现在不允许同时编辑多行。
     */
    mEditTableSaveRow({ row, $index }) {
      // if (this.editTableData.length - 1) {
      //   this.$message.error(this.$t("libsSz.key41"));
      //   return;
      // }
      const currentKeys = this.getCurrentRowFormModelKeys($index);
      this.$refs.myForm_c.validate(valid => {
        if (valid) {
          const next = () => {
            row.isEdit = false;
            // 每次row保存以后的
            this.afterSaveRowFn && this.afterSaveRowFn({ row, $index });
          };

          currentKeys.forEach(key => {
            const [prop] = key.split("_");
            row[prop] = this.formModel[key];
          });
          if (this.beforeSaveRowFn) {
            this.beforeSaveRowFn(JSON.parse(JSON.stringify(row)), next);
          } else {
            next();
          }
        }
      });

      // 废弃
      //  this.$refs.myForm_c.validateField(currentKeys, cb)
    },
    // 取消操作
    mEditTableCancelRow({ row, $index }) {
      row.isNew ? this.editTableData.splice($index, 1) : (row.isEdit = false);
    },
    // 获取当前行FormModel的key
    getCurrentRowFormModelKeys(index) {
      return Object.keys(this.formModel).filter(item => {
        const num = (item || "").split("_")[1];
        return String(num) === String(index);
      });
    },
    // 改变当前的editTable
    changeEditTableData(cb) {
      cb && cb(this.editTableData);
    },
    // 判断当前是否存编辑未保存数据
    getNotSaveTableData() {
      return !!this.editTableData.filter(item => item.isEdit).length;
    },
    // 获取处理完的table
    getTableData() {
      const data = JSON.parse(JSON.stringify(this.editTableData)).filter(i => !i.isEdit);
      return data.map(item => {
        const newItem = item;
        delete newItem.isEdit;
        delete newItem.isNew;
        return newItem;
      });
    },
    // 获取当前的Table
    getTable() {
      return this.$refs.myForm_medittable.getTable();
    },
    /**
     *  新增一行数据
     *
     *  1. 判断是否存在传入的 rowTemplate
     *  2. 存在就用rowTemplate
     *  3. 不存在则默认根据tableItem获取
     */
    addRow() {
      if (this.getNotSaveTableData()) {
        this.$message.error(this.$t("libsSz.key41"));
        return;
      }
      let rowTemplate = this.addRowTemplate;
      const notHasRowTemplate = !rowTemplate || JSON.stringify(rowTemplate) === "{}";
      if (notHasRowTemplate) {
        rowTemplate = {};
        this.tableItem.forEach(item => {
          rowTemplate[String(item.prop)] = "";
        });
      }
      this.editTableData.push({ ...rowTemplate, isEdit: true, isNew: true });

      if (!notHasRowTemplate) {
        // 增加默认值功能
        const length = this.editTableData.length - 1;
        const newFormValue = {};
        Object.keys(rowTemplate).forEach(c => {
          newFormValue[`${c}_${length}`] = rowTemplate[c];
        });
        this.formModel = Object.assign({}, this.formModel, newFormValue);
      }
    },
    /**
     *  删除逻辑
     *  1. 支持单行删除，传入当前的row对象
     *  2. 支持多行删除
     */
    removeRow(row) {
      const data = [];
      if (Array.isArray(row)) {
        data.concat(row);
      } else if (typeof row === "object") {
        data.push(row);
      }
      data.forEach(item => {
        const findIndex = this.editTableData.findIndex(
          c => JSON.stringify(c) === JSON.stringify(item),
        );
        // 清楚当前行所在的缓存；
        this.getCurrentRowFormModelKeys(findIndex).forEach(item => {
          this.formModel[item] = "";
        });
        // 清楚当前的tableData
        this.editTableData.splice(findIndex, 1);
      });
    },
    /**
     *  主动改变当前编辑行的form状态
     *  必须传，当前form的prop, 和当前行， 以及修改后的值
     *  需要传个数组
     *  {
     *    name:'',
     *    index:'', // 当前行
     *    value:'', // 修改的值
     *  }
     */
    changeFormModel(params) {
      if (Array.isArray(params)) {
        params.forEach(item => {
          if (
            Object.prototype.toString.call(this.formModel) === "[object Object]" &&
            this.formModel.hasOwnProperty(`${item.name}_${item.index}`)
          ) {
            this.formModel[`${item.name}_${item.index}`] = item.newVal;
          }
        });
      }
    },
    /**
     *  代理change方法
     */
    formChange(val, { prop, formData } = {}, index, row) {
      const { change, mchange } = formData;
      if (change && !mchange) return;
      mchange && mchange(val, index, row, this.changeFormModel);
    },

    getComponentName(item, { $index }) {
      const { formData, formModel } = this;
      const data = {};
      formData.forEach(formItem => {
        data[formItem.name] = formModel[`${formItem.name}_${$index}`] || "";
      });
      return typeof item === "function" ? item(data) : item || "";
    },

    // 获取 item.formData.data
    itemData(item) {
      return typeof item.formData.data === "function" ? item.formData.data(this.formModel) : item.formData.data || [];
    },
  },
  components: {
    MTable,
  },
};
</script>
<style lang="scss">
.myEditTableForm {
  .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
