import axios from 'axios'
export function getByteLen(val) {
  var len = 0
  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i)
    if (a.match(/[^\x00-\xff]/gi) != null) {
      len += 2
    } else {
      len += 1
    }
  }
  return len
}

/**
 * 获取数据类型
 * @param {*} params
 * @returns
 */
export function getDataType(params) {
  return Object.prototype.toString.call(params).slice(8, -1)
}
/**
 * 删除值null字段
 * 1、值不为null
 * 2、key不包含Time
 * @param {*} params
 * @returns
 */
export function delNullField(params) {
  let ret
  if (getDataType(params) === 'Array') {
    ret = params.map((item) => {
      if (getDataType(item) === 'Object') {
        const mapRet = {}
        Object.keys(item).forEach((k) => {
          if (item[k] !== null && !k.includes('Time')) {
            mapRet[k] = item[k]
          }
        })
        return mapRet
      }
      return item
    })
  } else if (getDataType(params) === 'Object') {
    ret = {}
    Object.keys(params).forEach((k) => {
      if (params[k] && !k.includes('Time')) {
        ret[k] = params[k]
      }
    })
  } else {
    ret = params
  }
  return ret
}
/**
 * 删除Time字段
 * @param {*} params
 * @returns
 */
export function deleteTimeField(params) {
  if (params === null) return params
  if (typeof params !== 'object') return params
  if (params instanceof FormData) return params
  let ret = new params.constructor()
  if (getDataType(params) === 'Array') {
    ret = params.map((item) => {
      return getDataType(item) === 'Object' ? deleteTimeField(item) : item
    })
  } else if (getDataType(params) === 'Object') {
    Object.keys(params).forEach((k) => {
      if (typeof params[k] === 'object') {
        ret[k] = deleteTimeField(params[k])
      } else if (k !== 'createTime' && k !== 'updateTime') {
        ret[k] = params[k]
      }
    })
  }
  return ret
}
// 删除对象中值为空的属性(不改变原对象)
export function filterParams(obj) {
  const _newObj = {}
  for (const key in obj) {
    if (obj[key] && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
      _newObj[key] = obj[key]
    }
  }
  return _newObj
}

export function downloadFn(url, params) {
  axios({
    url: url,
    method: 'get',
    params,
    responseType: 'blob'
  }).then((res) => {
    // data就是接口返回的文件流
    const data = res.data
    if (data) {
      // 处理文件名
      let fileName = ''
      const attrs = res.headers['content-disposition'].split(';')
      for (let i = 0, l = attrs.length; i < l; i++) {
        const temp = attrs[i].split('=')
        if (temp.length > 1 && temp[0] === 'filename') {
          fileName = temp[1]
          break
        }
      }
      console.log('fileName:', fileName)
      fileName = decodeURIComponent(fileName)
      console.log('fileName:', fileName)

      // 获取数据类型
      const type = res.headers['content-type'].split(';')[0] // type就是文件的mime类型，一般接口会返回。csv文件的mime一般采用text/csv
      const blob = new Blob([res.data], { type: type })
      const a = document.createElement('a')

      // 创建URL
      const blobUrl = window.URL.createObjectURL(blob)
      a.download = fileName
      a.href = blobUrl
      document.body.appendChild(a)

      // 下载文件
      a.click()
      // 释放内存
      URL.revokeObjectURL(blobUrl)
      document.body.removeChild(a)
    } else {
      console.log('error', data)
    }
  })
}

