<template>
  <el-input ref="INPUT" v-bind="$attrs" :placeholder="$t(placeholder)" autofocus v-on="$listeners">
    <!-- slot 透传 -->
    <template v-for="(slotData, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </el-input>
</template>

<script>
import { Input } from 'element-ui'
// import { t } from '@/i18n/i18n'

const methodFns = {}
Object.keys(Input.methods).forEach(methodFnName => {
  methodFns[methodFnName] = function(...fn) {
    this.getRef()[methodFnName](...fn)
  }
})

export default {
  name: Input.name,
  components: { 'el-input': Input },
  props: {
    // 默认值/自动翻译
    placeholder: {
      type: String,
      default() {
        return 'lang.gles.common.pleaseInput'
      }
    }
  },
  computed: {
    placeholderContent() {
      const { placeholder } = this
      return placeholder.startsWith('lang.') ? this.$t(placeholder) : placeholder
    }
  },
  methods: {
    getRef() {
      return this.$refs.INPUT
    },
    ...methodFns
  }
}
</script>

<style scoped lang="scss">

</style>
