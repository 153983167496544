import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'
/**
 * 单据管理：入库单、出库单、上下料单、库存调整单
 */
const apis = {
  // 入库单（内部单据）
  listStoreInByPage: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreIn/listStoreInByPage`,
    describe: '入库单列表',
    isNeedLoading: false
  },
  createStoreIn: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreIn/createStoreIn`,
    describe: '创建入库单',
    isNeedLoading: false
  },
  getStoreInDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreIn/getStoreInDetail`,
    describe: '入库单详情',
    isNeedLoading: false
  },
  // getStoreInTaskDetail: {
  //   method: 'post',
  //   url: `${glesRoot}/workflow/receiptStoreIn/getStoreInTaskDetail`,
  //   describe: '入库单任务详情',
  //   isNeedLoading: false
  // },
  // listLogDetail: {
  //   method: 'post',
  //   url: `${glesRoot}/workflow/receiptStoreIn/listLogDetail`,
  //   describe: '日志详情',
  //   isNeedLoading: false
  // },
  // 入库单（外部单据）
  listExternalStoreInByPage: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreIn/listExternalStoreInByPage`,
    describe: '入库单列表',
    isNeedLoading: false
  },
  createExternalStoreIn: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreIn/createExternalStoreIn`,
    describe: '创建入库单',
    isNeedLoading: false
  },
  getExternalStoreInDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreIn/getExternalStoreInDetail`,
    describe: '入库单详情',
    isNeedLoading: false
  },
  // 出库单（内部单据）
  listStoreOutByPage: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreOut/listStoreOutByPage`,
    describe: '出库单列表',
    isNeedLoading: false
  },
  createStoreOut: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreOut/createStoreOut`,
    describe: '创建出库单',
    isNeedLoading: false
  },
  getStoreOutDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreOut/getStoreOutDetail`,
    describe: '出库单详情',
    isNeedLoading: false
  },
  // getOutTaskDetail: {
  //   method: 'post',
  //   url: `${glesRoot}/workflow/receiptStoreOut/getStoreOutTaskDetail`,
  //   describe: '出库单任务详情',
  //   isNeedLoading: false
  // },
  // outListLogDetail: {
  //   method: 'post',
  //   url: `${glesRoot}/workflow/receiptStoreOut/listLogDetail`,
  //   describe: '出库单日志详情',
  //   isNeedLoading: false
  // },
  // 出库单（外部单据）
  listExternalStoreOutByPage: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreOut/listExternalStoreOutByPage`,
    describe: '出库单列表',
    isNeedLoading: false
  },
  createExternalStoreOut: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreOut/createExternalStoreOut`,
    describe: '创建出库单',
    isNeedLoading: false
  },
  getExternalStoreOutDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptStoreOut/getExternalStoreOutDetail`,
    describe: '出库单详情',
    isNeedLoading: false
  },
  // getStoreOutTaskDetail: {
  //   method: 'post',
  //   url: `${glesRoot}/workflow/receiptStoreOut/getStoreOutTaskDetail`,
  //   describe: '出库单任务详情',
  //   isNeedLoading: false
  // },
  // listStoreOutLogDetail: {
  //   method: 'post',
  //   url: `${glesRoot}/workflow/receiptStoreOut/listStoreOutLogDetail`,
  //   describe: '出库单日志详情',
  //   isNeedLoading: false
  // },
  // 上下料单（外部单据 + 内部单据）
  queryUpDownMaterialsBillByPage: {
    method: 'post',
    url: `${glesRoot}/workflow/upDownMaterialsBill/queryUpDownMaterialsBillByPage`,
    describe: '上下料单列表',
    isNeedLoading: false
  },
  queryUpDownMaterialsBillDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/upDownMaterialsBill/queryUpDownMaterialsBillDetail`,
    describe: '详情',
    isNeedLoading: false
  },
  addUpDownMaterialsBill: {
    method: 'post',
    url: `${glesRoot}/workflow/upDownMaterialsBill/addUpDownMaterialsBill`,
    describe: '新增',
    isNeedLoading: false
  },
  updateUpDownMaterialsBill: {
    method: 'post',
    url: `${glesRoot}/workflow/upDownMaterialsBill/updateUpDownMaterialsBill`,
    describe: '修改',
    isNeedLoading: false
  },
  deleteUpDownMaterialsBillById: {
    method: 'post',
    url: `${glesRoot}/workflow/upDownMaterialsBill/deleteUpDownMaterialsBillById`,
    describe: '删除',
    isNeedLoading: false
  },
  listTaskDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/monitorTask/listTaskDetail`,
    describe: '任务详情',
    isNeedLoading: false
  },
  listLogDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/monitorLog/listLogDetail`,
    describe: '日志详情',
    isNeedLoading: false
  },
  // 库存调整单
  getStockAdjustOrder: {
    method: 'post',
    url: `${glesRoot}/workflow/stockAdjustOrder/list/page/byCondition`,
    describe: '库存调整单列表 - 按单号查询',
    isNeedLoading: false
  },
  getContainerArchivesList: {
    method: 'post',
    url: `${glesRoot}/stock/queryContainerOption`,
    describe: '查询容器编码下拉(在库/不在库)',
    isNeedLoading: true
  },
  getContainerArchivesAllList: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/getCodeAndName`,
    describe: '查询所有容器编码',
    isNeedLoading: true
  },
  queryMaterialDetail: {
    method: 'post',
    url: `${glesRoot}/base/material/queryMaterialDetail`,
    describe: '物料id查询批属性列表',
    isNeedLoading: true
  },
  queryGoodsPositionOption: {
    method: 'post',
    url: `${glesRoot}/stock/queryGoodsPositionOption`,
    describe: '查询货位编码下拉（在库/不在库）',
    isNeedLoading: true
  },
  getStockAdjustOrderDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/stockAdjustOrder/list/orderDetail`,
    describe: '库存调整单明细',
    isNeedLoading: false
  },
  queryStockDetail: {
    method: 'post',
    url: `${glesRoot}/stock/queryStockDetail/byCondition`,
    describe: '库存信息',
    isNeedLoading: false
  },
  addOrderDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/stockAdjustOrder/addOrderDetail`,
    describe: '保存库存调整明细',
    isNeedLoading: false
  },
  confirmExecution: {
    method: 'post',
    url: `${glesRoot}/workflow/stockAdjustOrder/confirmExecution`,
    describe: '确认执行调整单',
    isNeedLoading: false
  },
  deleteOrder: {
    method: 'post',
    url: `${glesRoot}/workflow/stockAdjustOrder/deleteOrder`,
    describe: '删除调整单',
    isNeedLoading: false
  },
  deleteOrderDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/stockAdjustOrder/deleteOrderDetail`,
    describe: '删除调整单明细',
    isNeedLoading: false
  },
  // 理货单
  getTallyList: {
    method: 'post',
    url: `${glesRoot}/workflow/tally/listByCondition`,
    describe: '理货单列表',
    isNeedLoading: false
  },
  getTallyDetails: {
    method: 'post',
    url: `${glesRoot}/workflow/tally/getDetails`,
    describe: '理货单详情',
    isNeedLoading: false
  },
  postCancel: {
    method: 'post',
    url: `${glesRoot}/workflow/tally/cancel`,
    describe: '取消',
    isNeedLoading: false
  },
  getExpandDetails: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/expand`,
    describe: '获取拓展字段',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
