export default {
  // 递归数据, 把children嵌套改成平铺
  //
  recursion({
    data,
    childrenKey = "children",
    pid = "-1",
    formatter = row => row,
  }) {
    let newArr = [];
    if (!data) return;
    data.map((item, index) => {
      const newItem = item;
      const expandItems = {
        id: pid === "-1" ? String(index) : `${pid}-${index}`,
        pid,
      };
      const { [childrenKey]: child, ...others } = newItem;

      // 设置父类
      newArr.push({ ...expandItems, ...formatter(others) });

      // 再次循环child
      if (child && child.length > 0) {
        newArr = newArr.concat(
          this.recursion({
            data: child,
            childrenKey,
            pid: expandItems.id,
            formatter,
          }),
        );
      }
    });
    return newArr;
  },
  // 把数组转成key/value形式
  arrToObject(data, key, value) {
    if (!data || !data.length) return {};
    const myObj = {};
    data.forEach(item => (myObj[item[String(key)]] = item[value]));
    return myObj;
  },
  // 把数组转成options的数组
  arrToOptions(data, labelKey, valueKey) {
    if (!data || !data.length) return [];
    return data.map(item => ({ label: item[labelKey], value: item[valueKey] }));
  },
  // 把数组转成options的数组
  arrToStringOptions(data, labelKey, valueKey) {
    if (!data || !data.length) return [];
    return data.map(item => ({
      label: item[labelKey],
      value: String(item[valueKey]),
    }));
  },
  // 把Object，转成数组
  objectToArr(obj, keyLable, valueLabel) {
    const keys = Object.keys(obj);
    if (!keys.length) return [];
    return keys.map(item => ({ [keyLable]: item, [valueLabel]: obj[item] }));
  },
};
