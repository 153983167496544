import { t } from '@/libs_sz/locale'
export default {
  namespaced: true,
  state: {
    // 机器人类型
    robotTypeList: [],
    // 执行模式 标准/搬运
    executeModeList: [],
    // 货位分配机制
    allocStrategyList: [],
    // 作业货位
    workGoodsPositionPropList: [],
    // 料类型属性
    materialTypePropList: [],
    // 作业动作属性
    workActionPropList: [],
    // 动作执行属性
    actionExecPropList: [],
    // 货位状态
    goodsPositionStatusPropList: [],
    // 移出容属性
    shiftOutContainerPropList: [],
    // 移入库位属性
    shiftInFactoryPositionPropList: [],
    // 作业类型
    robotWorkTypeList: [],
    // 通知时机
    noticeTimingDictList: [],
    // 作业模板列表
    workTemplateList: [],
    // 货位类型列表
    workPositionTypeList: [
      {
        label: t('lang.gles.workflow.deviceGoodsPosition'),
        value: 1
      },
      {
        label: t('lang.gles.workflow.produceFactoryPosition'),
        value: 2
      }
    ],
    // 接口类型
    interfaceTypeList: [],
    // 任务类型
    planTaskTypeList: [
      {
        label: '理货',
        value: 1
      }
    ],
    // 执行方式
    execWayList: [
      {
        label: '重复执行',
        value: 1
      },
      {
        label: '单次执行',
        value: 2
      }
    ],
    // 执行频率
    execFreqList: [
      {
        label: '每天',
        value: 1
      },
      {
        label: '每周',
        value: 2
      },
      {
        label: '每月',
        value: 3
      }
    ],
    // 执行间隔
    execIntervalList: [
      {
        label: '执行一次',
        value: 1
      },
      {
        label: '重复执行',
        value: 2
      }
    ],
    unitList: [
      {
        label: '小时',
        value: 'h'
      },
      {
        label: '分钟',
        value: 'm'
      },
      {
        label: '秒',
        value: 's'
      }
    ],
    planTaskStateList: [],
    resultStateList: [
      {
        label: t('成功'),
        value: 1
      },
      {
        label: t('失败'),
        value: 0
      }
    ],
    // PDA 触发操作
    pdaTriggerPositionList: [],
    // PDA 触发操作
    pdaTriggerOperateList: [],
    // PDA 触发任务
    pdaTriggerTaskList: [],
    // 通知信息
    noticeInfoDictList: [],
    // 取料位置
    takeMaterialTypeList: [],
    // 放料位置
    putMaterialTypeList: [],
    // 搬运库位
    carryPositionList: []
  },
  mutations: {
    changeState(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map((item) => {
        state[item] = payLoad[item]
        return true
      })
    }
  },
  actions: {
    /**
     * 获取机器人类型列表
     * @param {*} params
     */
    async getRobotTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'robotType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          robotTypeList: list
        })
      })
    },
    /**
     * 货位分配机制
     * @param {*} param
     * @param {*} payLoad
     */
    async getAllocStrategyList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'goodsAllocStrategy' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          allocStrategyList: list
        })
      })
    },
    /**
     * 货位分配机制
     * @param {*} param
     * @param {*} payLoad
     */
    async getExecTemplateList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'execTemplate' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          execTemplateList: list
        })
      })
    },
    /**
     * 作业货位
     * @param {*} param
     * @param {*} payLoad
     */
    async getWorkGoodsPositionPropList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'workGoodsPositionProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          workGoodsPositionPropList: list
        })
      })
    },
    /**
     * 料类型
     * @param {*} param
     * @param {*} payLoad
     */
    async getMaterialTypePropList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'materialTypeProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          materialTypePropList: list
        })
      })
    },
    /**
     * 作业动作
     * @param {*} param
     * @param {*} payLoad
     */
    async getWorkActionPropList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'workActionProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          workActionPropList: list
        })
      })
    },
    /**
     * 动作执行
     * @param {*} param
     * @param {*} payLoad
     */
    async getActionExecPropList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'actionExecProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          actionExecPropList: list
        })
      })
    },
    /**
     * 货位状态
     * @param {*} param
     * @param {*} payLoad
     */
    async getGoodsPositionStatusPropList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'goodsPositionStatusProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          goodsPositionStatusPropList: list
        })
      })
    },
    /**
     * 移出容器属性
     * @param {*} param
     * @param {*} payLoad
     */
    async getShiftOutContainerPropList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'shiftOutContainerProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          shiftOutContainerPropList: list
        })
      })
    },
    /**
     * 移出库位属性
     * @param {*} param
     * @param {*} payLoad
     */
    async getShiftInFactoryPositionPropList(
      { dispatch, commit, state },
      params
    ) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'shiftInFactoryPositionProp' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          shiftInFactoryPositionPropList: list
        })
      })
    },
    /**
     * 作业类型
     * 上满、上空
     * @param {*} param
     * @param {*} payLoad
     */
    async getRobotWorkTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'robotWorkType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          robotWorkTypeList: list
        })
      })
    },
    /**
     * 货位类型
     * 1 设备货位
     * 2 生产库位
     * @param {*} param
     * @param {*} payLoad
     */
    async getWorkPositionTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'workPositionType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          workPositionTypeList: list
        })
      })
    },
    /**
     * 任务类型
     * @param {*} param
     * @param {*} payLoad
     */
    async getPlanTaskTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'planTaskType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          planTaskTypeList: list
        })
      })
    },
    /**
     * 计划任务状态
     * @param {*} param
     * @param {*} payLoad
     */
    async getPlanTaskStateList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'planTaskState' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          planTaskStateList: list
        })
      })
    },
    /**
     * 执行方式
     * 1 重复执行
     * 2 单次执行
     * @param {*} param
     * @param {*} payLoad
     */
    async getExecWayList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'planTaskExecWay' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          execWayList: list
        })
      })
    },
    /**
     * 执行频率
     * 1 每天
     * 2 每周
     * 3 每月
     * @param {*} param
     * @param {*} payLoad
     */
    async getExecFreqList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'planTaskExecFrequency' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          execFreqList: list
        })
      })
    },
    /**
     * 执行间隔
     * 1 执行一次
     * 2 重复执行
     * @param {*} param
     * @param {*} payLoad
     */
    async getExecIntervalList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'planTaskExecInterval' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          execIntervalList: list
        })
      })
    },
    /**
     * 执行间隔-单位
     * h 小时
     * m 分钟
     * s 秒
     * @param {*} param
     * @param {*} payLoad
     */
    async getExecIntervalUnitList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'planTaskTimeUnit' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          unitList: list
        })
      })
    },
    /**
     * 触发位置
     * @param {*} param
     * @param {*} payLoad
     */
    async getPdaTriggerPosition({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'pdaTriggerPosition' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          pdaTriggerPositionList: list
        })
      })
    },
    /**
     * 触发操作
     * @param {*} param
     * @param {*} payLoad
     */
    async getPdaTriggerOperate({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'pdaTriggerOperate' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          pdaTriggerOperateList: list
        })
      })
    },
    /**
     * 触发任务
     * @param {*} param
     * @param {*} payLoad
     */
    async getPdaTriggerTask({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'pdaTriggerTask' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          pdaTriggerTaskList: list
        })
      })
    },
    /**
     * 通知信息
     * @param {*} param
     * @param {*} payLoad
     */
    async getNoticeInfo({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'informOrder' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          noticeInfoDictList: list
        })
      })
    },
    /**
     * 通知时机
     * @param {*} param
     * @param {*} payLoad
     */
    async getNoticeTimingList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'placeNoticeTiming' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          noticeTimingDictList: list
        })
      })
    },
    /**
     * 执行模式
     * @param {*} param
     * @param {*} payLoad
     */
    async getExecuteModeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'executeMode' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          executeModeList: list
        })
      })
    },
    /**
     * 取料位置
     * @param {*} param
     * @param {*} payLoad
     */
    async getTakeMaterialTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'takeMaterialType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          takeMaterialTypeList: list
        })
      })
    },
    /**
     * 放料位置
     * @param {*} param
     * @param {*} payLoad
     */
    async getPutMaterialTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'putMaterialType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          putMaterialTypeList: list
        })
      })
    },
    /**
     * 放料位置
     * @param {*} param
     * @param {*} payLoad
     */
    async getCarryPositionList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'carryPosition' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          carryPositionList: list
        })
      })
    }
  }
}
