/**  使用规则
 *   key必须用所在链接的最后一层
 *   只能保存两级目录；
 */
import config from '@/utils/config'

const { arkRoot, athenaRoot = '/athena', authUrl } = config

export default {
  getLanguages: {
    method: 'get',
    url: `${arkRoot}/api/coreresource/i18n/getLanguages/v1`,
    describe: '获取语言类型',
    isNeedLoading: false
  },
  getLangItems: {
    method: 'get',
    url: `${arkRoot}/api/coreresource/i18n/getLangItems/v1`,
    describe: '获取翻译字段',
    isNeedLoading: false
  },
  queryDetails: {
    method: 'post', // 结构请求的方式
    url: `${arkRoot}/api_v1/dict/batchQuery`, // 接口URL
    describe: '获取字典', // 标注
    isNeedLoading: false // 是否需要loading
  },
  athenaGetLangItems: {
    method: 'post', // 结构请求的方式
    url: `${athenaRoot}/api/coreresource/i18n/getLangItems/v1`, // 接口URL
    describe: '获取字典', // 标注
    isNeedLoading: false // 是否需要loading
  },
  getAuthManageLangItems: {
    method: 'post', // 结构请求的方式
    url: `${authUrl}/api/coreresource/i18n/getLangItems/v1`, // 接口URL
    describe: '获取权限字典', // 标注
    isNeedLoading: false // 是否需要loading
  },
  queryWorkstationList: {
    method: 'get', // 结构请求的方式
    url: `${arkRoot}/api/workstation/queryWorkstationList`, // 接口URL
    describe: '获取可用的用户列表', // 标注
    isNeedLoading: false // 是否需要loading
  },
  refeshCellcodeList: {
    method: 'get', // 结构请求的方式
    url: `${arkRoot}/api/cellcode/refeshCellcodeList`, // 接口URL
    describe: '刷新缓存', // 标注
    isNeedLoading: false // 是否需要loading
  }
}
