// 取当前设备属性
export default function getOs() {
  const ua = navigator.userAgent
  const isWindowsPhone = /(?:Windows Phone)/.test(ua)
  const isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
  const isAndroid = /(?:Android)/.test(ua)
  const isFireFox = /(?:Firefox)/.test(ua)
  const isChrome = /(?:Chrome|CriOS)/.test(ua)
  const isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua))
  const isPhone = /(?:iPhone)/.test(ua) && !isTablet
  const isPc = !isPhone && !isAndroid && !isSymbian
  return { isTablet, isPhone, isAndroid, isPc, isChrome }
}

// 获取当前屏幕尺寸
// > 1920 medium
// 1920 < * > 1366  small
// < 1366 mini
export function getViewSize() {
  const width = document.body.offsetWidth

  switch (true) {
    case width >= 1920:
      return 'medium'
    case width > 1366:
      return 'small'
    default:
      return 'mini'
  }
}
