// ⚡ 常用IP以及分支信息, 及时维护 ~
const COMMONLY_IP = [
  { index: '1', name: '开发环境', ip: '172.26.16.133', edition: '*' },
  { index: '2', name: 'panni', ip: '172.26.17.120', edition: '*' }
]

// 代理方式
// ✨ 代理IP, 如 172.16.3.38
// ✨ 支持OPS 2.0 格式域名, 直接写入OPS标识, 如 test-3388
// ✨ 快速代理到 COMMONLY_IP 中的某个IP, 直接输入index, 如 0
// panni
// const DEV_SERVER_IP = '172.26.23.63:8601'
// const DEV_SERVER_IP = '172.16.44.4:8601'
// const DEV_SERVER_IP = '172.16.19.45:8601'
// zhousheng
// const DEV_SERVER_IP = '172.26.40.24:8601'
// const DEV_SERVER_IP = '172.16.44.128:8601'
// const DEV_SERVER_IP = '172.16.44.28:8601'
//钱胜
// const DEV_SERVER_IP = '172.26.17.151:8601'
// const DEV_SERVER_IP = '100.0.0.87:8601'
//大博
// const DEV_SERVER_IP = '172.26.22.217:8601'
// const DEV_SERVER_IP = '172.26.14.103:8601'
// 延新docker
// const DEV_SERVER_IP = 'dev-7018'
// const DEV_SERVER_IP = 'dev-7427'
// const DEV_SERVER_IP = 'dev-7104'
// 测试docker
// const DEV_SERVER_IP = 'test-7051'
// const DEV_SERVER_IP = 'test-8114'
const DEV_SERVER_IP = 'test-9516'
// const DEV_SERVER_IP = 'test-9481'
// 国帅压测
// const DEV_SERVER_IP = '172.16.8.228'
// const DEV_SERVER_IP = '172.26.40.53:8601'
// const DEV_SERVER_IP = '172.26.22.217:8601'
// 何强
// const DEV_SERVER_IP = 'dev-7427'
// const DEV_SERVER_IP = '172.26.40.59:8601'

// 是否跳过权限校验
const IGNORE_ROUTING_GUARD = true

// 编译目录
const CUR_BUILD_FILE = 'build-gles'

// 静态auth-manage 文件根路径
const AUTHMANAGE_ROOT_STATUS = '/static/auth-manage'
// 静态rms文件根路径
const RMS_ROOT_STATUS = '/static/rms'
// authManage项目, 接口根路径
const AUTHMANAGE_ROOT = '/auth-manage'
// athena项目, 接口根路径
const ATHENA_ROOT = '/athena'
// mars项目, 接口根路径
const MARS_ROOT = '/mars'
//
const GLES_ROOT = '/gles-server'

// 解析支持OPS 1.0 / 2.0
let devServerIp = DEV_SERVER_IP

// 代理模式
// 如果不是一个ip, 则按照ops 2.0 的格式进行解析
switch (true) {
  // IP模式
  case /^(\d{1,3}\.){3}\d{1,3}(:\d+)?$/.test(DEV_SERVER_IP):
    console.log('代理模式: IP')
    break

  case /^\d+$/.test(DEV_SERVER_IP):
    const ipInfo = COMMONLY_IP.find(
      (item) => String(item.index) === String(DEV_SERVER_IP)
    )
    devServerIp = ipInfo.ip
    console.log('代理模式: 快速')
    console.log('代理索引: ', ipInfo.index)
    console.log('代理用户: ', ipInfo.name)
    console.log('代理版本: ', ipInfo.edition)
    break

  default:
    // devServerIp = `${DEV_SERVER_IP}.local.k8s.ops.geekplus.cc`
    devServerIp = `gles.qysofti.com `;
    console.log('代理模式: OPS 2.0')
    break
}

console.log('代理地址: ', devServerIp)

module.exports = {
  COMMONLY_IP,
  DEV_SERVER_IP,
  CUR_BUILD_FILE,
  AUTHMANAGE_ROOT_STATUS,
  IGNORE_ROUTING_GUARD,
  RMS_ROOT_STATUS,
  AUTHMANAGE_ROOT,
  ATHENA_ROOT,
  MARS_ROOT,
  GLES_ROOT,
  devServerIp
}
