<template>
  <el-submenu
    v-if="childrenList.length"
    :index="String(parentMenu.path || parentMenu.id)"
    class="ui-el-submenu"
  >
    <!-- 菜单   -->
    <template slot="title">
      <i v-if="showIcon" :class="parentMenu.icon" />
      <span>{{ parentMenu.title }}</span>
    </template>
    <!-- 递归菜单 -->
    <m-menu-item
      v-for="item in childrenList"
      :key="String(item.id)"
      :parent-menu="item"
      :menu="menu"
      :show-icon="false"
    />
  </el-submenu>
  <el-menu-item v-else :title="parentMenu.title" :index="parentMenu.path">
    <i v-if="showIcon" :class="parentMenu.icon" />
    <span slot="title">{{ parentMenu.title }}</span>
  </el-menu-item>
</template>
<script>
/**
 *  生成当前菜单的item
 *  @props { menu } 全部菜单
 *  @props { parentMenu } 父菜单
 */
export default {
  name: "MMenuItem",
  props: {
    menu: {
      // 总的菜单数据；
      type: Array,
      default() {
        return [];
      },
    },
    parentMenu: {
      type: Object,
      default() {
        return {};
      },
    },
    showIcon: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    // 寻找当前菜单的所有子菜单
    childrenList() {
      return this.menu.filter(item => item.pid === this.parentMenu.id);
    },
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">

@import '../../theme/modules/comomon/variable';

.ui-el-submenu .el-menu {
  background-color: $menu-open-bg;
}
.ui-el-submenu .el-menu--inline {
  // max-height: 300px;
  // overflow-x: hidden;
  // overflow-y: auto;
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: $menu-open-bg;
  }
}
</style>
