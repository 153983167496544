import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'

/**
 * 库存列表：在库库存、在途库存
 */
const apis = {
  // 固定货位库存 增删改查
  getFixedLocationInventoryListByCode: {
    method: 'post',
    url: `${glesRoot}/stock/list/page/byCode`,
    describe: '查询固定货位库存列表',
    isNeedLoading: false
  },
  getFixedLocationInventoryList: {
    method: 'post',
    url: `${glesRoot}/stock/list/page/byCondition`,
    describe: '查询固定货位库存列表-更多查询',
    isNeedLoading: false
  },
  freezeOrThaw: {
    method: 'post',
    url: `${glesRoot}/stock/freezeStock`,
    describe: '冻结/解冻操作',
    isNeedLoading: false
  },
  queryStockDetailByContainer: {
    method: 'post',
    url: `${glesRoot}/stock/queryStockDetail/byContainer`,
    describe: '库存详情 - 按照容器查看',
    isNeedLoading: false
  },
  queryStockDetailByMaterial: {
    method: 'post',
    url: `${glesRoot}/stock/queryStockDetail/byMaterial`,
    describe: '库存详情 - 按照物料查看',
    isNeedLoading: false
  },

  // 在途库存--查看
  getTransitationByCode: {
    method: 'post',
    url: `${glesRoot}/stock/transit/list/page/byCode`,
    describe: '查询在途库存列表',
    isNeedLoading: false
  },
  getTransitationByCondition: {
    method: 'post',
    url: `${glesRoot}/stock/transit/list/page/byCondition`,
    describe: '更多查询在途库存列表',
    isNeedLoading: false
  },
  queryDetailByContainer: {
    method: 'post',
    url: `${glesRoot}/stock/transit/queryStockDetail/byContainer`,
    describe: '在途库存详情 - 按照容器查看',
    isNeedLoading: false
  },
  queryDetailByMaterial: {
    method: 'post',
    url: `${glesRoot}/stock/transit/queryStockDetail/byMaterial`,
    describe: '在途库存详情 - 按照物料查看',
    isNeedLoading: false
  },
  queryStockListByCondition: {
    method: 'post',
    url: `${glesRoot}/stock/queryStockList/byCondition`,
    describe: '',
    isNeedLoading: false
  },
  queryRobotGoodsPositionCode: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/queryGoodsPositionOption`,
    describe: '根据容器形态查询货位编码下拉',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
