<template>
  <el-tabs v-model="activeName" v-on="$listeners">
    <el-tab-pane v-for="item in myTabs" :key="item.name" :name="item.name">
      <span slot="label" class="p20">{{ item.label }}</span>
      <div class="ui-mauthtabs__content">
        <slot :name="item.slotName" v-bind="item" />
      </div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
/**
 *  重新封装下tab
 *
 *  @param { tabs } 描述tabs
 *
 *  数据格式如下
 *
 *   [
 *     {
 *        name:"", //必填
 *        label:"", // 必填
 *     }
 *   ]
 *
 */
export default {
  props:{
    tabs:{
      type:Array,
    },
  },
  data(){
    return {
      activeName: this.tabs[0].name, // 当前激活的tab
    }
  },
  computed:{
    myTabs(){
      return this.tabs;
    },
  },
  methods:{
    getActiveName(){
      return this.activeName;
    },
  },
}
</script>

