import enLocale from "element-ui/lib/locale/lang/en";

const en = {
  ...enLocale,
  libsSz: {
    key1: "Account",
    key2: "Password",
    key3: "Login",
    key4: "Logout",
    key5: "Sorry, you are not authorized to visit this page",
    key6: "Previous",
    key7: "Sorry, the page you visited does not exist",
    key8: "Unfold",
    key9: "Fold",
    key10: "Query",
    key11: "Reset",
    key12: "SN",
    key13: "Operation",
    key14: "Cancel",
    key15: "Confirm",
    key16: "Loading...",
    key17: "Change Password",
    key18: "Role name",
    key19: "Role description",
    key20: "Permission types",
    key21: "Authorization subsystem",
    key22: "Please enter ",
    key23: "Please select",
    key24: "Page permissions",
    key25: "Associated users",
    key26: "Please enter keywords to filter",
    key27: "Name",
    key28: "Tel",
    key29: "Enable the permissions",
    key30: "Disable the permissions",
    key31: "Please enter required fields!",
    key32: "Select the subsystem for which authorization is required",
    key33: "assignment",
    key34: "Special characters are not allowed",
    key35: "Please enter required fields!",
    key36: "Maximum limit input {0} characters",
    key37: "Please enter a valid IP address",
    key38: "Please enter a positive integer",
    key39: "Save",
    key40: "Edit",
    key41: "There is an unsaved edit item, please save it in edit first!",
    key42: "Make sure to cancel the line edit",
    key43: "Please enter a natural number",
    key44: "Please enter the correct phone number",
    key45: "No Data",
    key46: "Please enter the {0} character",
    key47: "No choice",
    key48: "Have chosen",
    key49: "Feedback",
    key50: "Download",
    key51: "Image failed to load",
    key52: "Navigator",
    key53: "Undo",
    key54: "Redo",
    key55: "Delete",
    key56: "ZOOM In",
    key57: "Zoom Out",
    key58: "Zoom Auto",
    key59: "Actual size",
    key60: "Post-level",
    key61: "Pre-level",
    key62: "Multiple",
    key63: "Canvas",
    key64: "Grid alignment",
    key65: "Only 1-65535 positive integers",
    key66: "Only English letters or numbers",
  },
};

export default en;
