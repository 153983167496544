<template>
  <div>
    <!-- 增加formData   -->
    <div class="ui-myformBox bgwh p20">
      <m-form ref="myForm" :form-data="formData" :extend-config="extendFormConfig" />
    </div>
    <div class="ui-myTabsBox bgwh mt20 mb20 p20">
      <div class="ui-myTabsBox__status tr">
        <el-switch
          v-model="hasPermission"
          size="small"
          :active-value="1"
          :inactive-value="0"
        />
        <a class="ml10">{{ hasPermission ? $t("libsSz.key29") : $t("libsSz.key30") }}</a>
      </div>
      <!-- 公共tabs -->
      <m-auth-tabs :tabs="tabs">
        <template #MAuthTree="scope">
          <!-- tree模块 -->
          <m-auth-tree ref="myAuthTree" :sub-system="scope.data" />
        </template>
        <template #MAuthTable="scope">
          <!-- 用户模块 -->
          <m-auth-table
            ref="myAuthTable"
            :table-data="scope.data"
            :table-item="tableItem"
            :user-check-val="userData.userCheckVal"
          />
        </template>
      </m-auth-tabs>
    </div>
  </div>
</template>
<script>
/**
 *  Auth组件，主要解决角色新增(未有产品规划，只是出于节省开发时间开发)
 *  目前不支持数据权限；
 *
 *  @props { typeOptions } 表示权限类型，下拉框，一般不要配置，默认数据类型；
 *  格式类型 [{label:"", value:""}]
 *  @props { formInitValue } 表示角色名称， 角色说明，权限类型， 授权子系统的默认值；
 *  @props { subsystemOptions } 描述子系统的相关信息，关联到子系统的tree和 多选子系统；
 *
 *  {
 *
 *        label: item.sysName, // 下拉菜单标签
          value: item.id, // 下拉菜单唯一key
          data: this.transformKey(subsyslist[String(item.id)]), // 子系统tree
          props: {
            children: "children",
            label: "name"
          }, // tree的props
          nodeKey: "permissionId", // tree的唯一key
          defaultCheckedKey // 默认选中项
 *  }
 *
 *  @props { userData } 描述用户信息
 *
 *     {
            tableData:[],// table数据
            userCheckVal:[] // 默认选择userCheck
         }

 *  @refEvent { getPermissionData } 通过方法的方式调用权限数据
 */

import MForm from "../MForm/MForm";
import MAuthTabs from "./components/MAuthTabs";
import MAuthTree from "./components/MAuthTree";
import MAuthTable from "./components/MAuthTable";
import { getFormData, defaultTypeOptions, getTabsData, getUserData } from "./dict";

export default {
  name: "MAuth",
  props: {
    // 权限类型下拉框 这个数据和tab页有关系
    typeOptions: {
      type: Array,
      default() {
        return defaultTypeOptions(this);
      },
    },
    // 默认的form表单
    formInitValue: {
      type: Object,
      default() {
        return {
          status: 1,
        };
      },
    },
    // 子系统包含子系统下拉框/
    subsystemOptions: {
      type: Array,
      required: true,
    },
    // 用户数据
    userData: {
      type: Object,
      default: () => ({
        tableData: [],// table数据
        userCheckVal: [], // user默认
      }),
    },
  },
  data() {
    return {
      extendFormConfig: {
        collapse: false,
        isNeedBtn: false,
      },// 去掉btn和是否展开
      tableItem: getUserData(this),
      hasPermission: this.formInitValue.status,// 是否启用权限
      currentSubSystem: [], // 当前选择的子系统
    };
  },
  created() {

  },
  watch: {
    formInitValue(newVal, oldVal) {
      // 如果formInitValue改变的话，修改MForm参数；
      this.$nextTick(() => {
        this.$refs.myForm.changeFormValue(newVal);
      });
      // 当前选择的子系统
      this.currentSubSystem = newVal.subsystem;
      if (typeof this.currentSubSystem !== "object") {
        this.currentSubSystem = [this.currentSubSystem];
      }
    },
  },
  computed: {
    formData() {
      // 整理formData数据
      const subsystemOptions = this.subsystemOptions.map(item => ({ label: item.label, value: item.value }));
      const editData = {
        typeOptions: this.typeOptions,
        subsystemOptions, ...this.formInitValue,
      };
      return getFormData(this, editData);
    },
    tabs() {
      // 获取tabs数据
      const dataList = { userData: this.userData.tableData || [], webData: this.subsystemOptions.filter(item => [].concat(this.currentSubSystem).includes(item.value)) };
      return getTabsData(this, dataList);
    },
  },

  methods: {
    subsystemChange(value) {
      this.currentSubSystem = value;
    },
    // 获取当前数据
    async getPermissionData() {
      try {
        const formData = await this.$refs.myForm.getValidateFormModel();
        if (!formData) return Promise.resolve(false);
        // 获取选择的用户ID
        const userId = this.$refs.myAuthTable.getAllSelect();
        // 获取权限树的key
        const getTreeObject = this.$refs.myAuthTree.getCheckKey();
        // 整理permission参数
        const permList = JSON.stringify(getTreeObject) !== "{}" ? Object.keys(getTreeObject).map(item => ({
          subsysId: item,
          permissionId: getTreeObject[String(item)] || [],
        })) : [];
        return Promise.resolve({ ...this.formInitValue, ...formData, status: this.hasPermission, userId, permList });
      } catch (e) {
        return Promise.resolve(false);
      }

    },
  },
  components: {
    MForm,
    MAuthTree,
    MAuthTabs,
    MAuthTable,
  },
};
</script>
<style lang="scss" scoped>
.ui-myTabsBox__status {
  z-index: 3;
  width: 200px;
  position: relative;
  left: calc(100% - 200px);
  margin-bottom: -28px;
}
</style>

