import { t } from "../locale/index";

// === 建议配置formItem 放到computed， 不然无法响应 vue-i18n;

const formValidate = {
  required() {
    return {
      required: true,
      message: t("libsSz.key35")
    };
  },
  inputLen6() {
    return {
      min: 6,
      max: 6,
      message: t("libsSz.key46", [6])
    };
  },
  inputLen7() {
    return {
      min: 7,
      max: 7,
      message: t("libsSz.key46", [7])
    };
  },
  inputLen11() {
    return {
      min: 0,
      max: 11,
      message: t("libsSz.key36", [11])
    };
  },
  inputMaxLen6() {
    return {
      min: 0,
      max: 6,
      message: t("libsSz.key36", [6])
    };
  },
  inputLen15() {
    return {
      min: 0,
      max: 15,
      message: t("libsSz.key36", [15])
    };
  },
  inputLen32() {
    return {
      min: 0,
      max: 32,
      message: t("libsSz.key36", [32])
    };
  },
  inputLen64() {
    return {
      min: 0,
      max: 64,
      message: t("libsSz.key36", [64])
    };
  },
  inputLen255() {
    return {
      min: 0,
      max: 255,
      message: t("libsSz.key36", [255])
    };
  },
  ipRules() {
    return {
      pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/,
      message: t("libsSz.key37")
    };
  },
  // 子网掩码
  maskRules() {
    return {
      pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/,
      message: t("libsSz.key67")
    };
  },
  // 网关地址
  gateWayRules() {
    return {
      pattern: /((?:(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d))\.){3}(?:25[0-5]|2[0-4]\d|(?:1\d{2}|[1-9]?\d)))/,
      message: t("libsSz.key68")
    };
  },
  portRules() {
    return {
      pattern: /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
      message: t("libsSz.key66")
    };
  },
  intRules() {
    return {
      pattern: /^[0-9]\d{0,}$/,
      message: t("libsSz.key43")
    };
  },
  positiveInt() {
    return {
      pattern: /^[1-9]\d{0,}$/,
      message: t("libsSz.key38")
    };
  },
  telephone() {
    return {
      pattern: /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/,
      message: t("libsSz.key44")
    };
  },
  specialCharacters() {
    return {
      pattern: /^(a-z|A-Z|0-9)*[^~!@#$%^&*()']*$/,
      message: t("libsSz.key34")
    };
  },
  numbersOrWords() {
    return {
      pattern: /^[0-9a-zA-Z]*$/,
      message: t("libsSz.key65")
    };
  },
  numbers() {
    return {
      pattern: /^[+]{0,1}(\d+)$/,
      message: t("libsSz.key67")
    };
  },
  supportNumbers() {
    return {
      pattern: /^(\-|\+)?\d+$/,
      message: t("libsSz.key94")
    };
  },
  inputMaxLen5() {
    return {
      min: 0,
      max: 5,
      message: t("libsSz.key36", [5])
    };
  },
  numberAndComma() {
    return {
      pattern: /^[-,0-9]+$/,
      message: t("libsSz.key95")
    };
  }
};

export default function(rules) {
  if (Array.isArray(rules)) {
    return rules.map(g => formValidate[g]());
  } else if (typeof rules === "string") {
    return [formValidate[rules]()];
  } else {
    return rules;
  }
}
