/* eslint-disable */
// import * as constants from '../constants/commonConstants'

export default {
  glesRoot: '/gles-server',
  authUrl: '/auth-manage',
  athenaRoot: '/athena',
  marsRoot: '/mars',
  arkRoot: '/ark-web',
  loginUrl: '/static/auth-manage/#/login',
  changepwUrl: '/static/auth-manage/#/changepw',
  authNoPerUrl: '/static/auth-manage/#/403',
  ROOT: '',
  authId: 4,
  authCode: 'AVALON',
  showExceptionTab: false,
  language: 'CN',
  clientId: '9528',
  RMSURL: 'ws://127.0.0.1:8890'
}
