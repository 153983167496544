import Vue from 'vue'
import apiStore from '@/apis/index'
import Promise from 'es6-promise'
import httpService from '@/libs_sz/plugins/httpService'
import { Message } from 'element-ui'
import { linkAuthMange, noPermission } from '@/utils/getSessionId'
import myConfig from '@/utils/config'
import { deleteTimeField } from '@/utils/utils'

Promise.polyfill()

Vue.use(httpService, {
  // 默认错误
  messageFn(type, msg) {
    if (type !== 'error') return
    Message.error(msg)
  },
  apiStore,
  authentication: true, // 是否需要开启鉴权；
  // 请求拦截
  requestInterceptor(request) {
    const data = request.data
    request.data = deleteTimeField(data) ?? {}
    return request
  },
  // 开启鉴权后的默认处理，必填;
  authenticationRespone({ code }, next) {
    if (String(code) === '2') {
      Message.error(`${window.vm.$t('lang.gles.common.leavingSoon')}...`)
      setTimeout(linkAuthMange, 500)
    } else if (String(code) === '3') {
      noPermission()
    }
    next()
  }
})

// 增加axios的默认配置项baseUrl
httpService.gAxios.changeAxiosAttribute({ baseURL: myConfig.ROOT })
