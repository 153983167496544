import { t } from '@/libs_sz/locale'
const tool = {}
function trim(str) {
  const curStr = `${str}`
  return curStr.replace(/(^\s*)|(\s*$)/g, '')
}

tool.trim = trim

tool.empty = (v) => {
  switch (typeof v) {
    case 'undefined':
      return true
    case 'string':
      if (trim(v).length === 0) return true
      break
    case 'boolean':
      if (!v) return true
      break
    case 'number':
      if (v === 0) return true
      break
    case 'object':
      if (v === null) return true
      if (undefined !== v.length && v.length === 0) return true
      // eslint-disable-next-line
      for (const k in v) {
        return false
      }
      return true
      // eslint-disable-next-line
      break
    default:
      break
  }
  return false
}
tool.contains = (a, obj) => {
  const i = a.length
  // eslint-disable-next-line no-unmodified-loop-condition
  while (i - 1 >= 0) {
    if (a[i] === obj) {
      return true
    }
  }
  return false
}

tool.getByClass = (sClass) => {
  const aResult = []

  const aEle = document.getElementsByTagName('*')

  for (let i = 0; i < aEle.length; i += 1) {
    /* 当className相等时添加到数组中 */

    if (aEle[i].className === sClass) {
      aResult.push(aEle[i])
    }
  }

  return aResult
}

tool.SeqAscSort = (a, b) => a.get('seq') - b.get('seq')

tool.getByteLen = (val) => {
  var len = 0
  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i)
    if (a.match(/[^\x00-\xff]/gi) != null) {
      len += 2
    } else {
      len += 1
    }
  }
  return len
}

tool.pxCountWidth = function (
  list_s,
  TableItemCountProps,
  TableItemList,
  font
) {
  const conversionObj = {} // 国际化后的中文字段
  if (!font) {
    const fontFmaily = navigator?.userAgent?.includes('Mac') ? 'Hiragino Sans GB' : 'sans-serif'
    font = `normal 14px ${fontFmaily}`
  }
  const list = list_s
  TableItemCountProps.forEach((prop) => {
    const tableProp = TableItemList.find((t) => t.prop === prop)
    tableProp && (conversionObj[prop] = tableProp.label)
  })

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  font && (context.font = font)

  for (const item of list) {
    Object.keys(conversionObj).forEach((prop) => {
      const titleMaxWidth = context.measureText(conversionObj[prop]).width + 30
      const maxWidth = list[0][`${prop}MaxWid`]
      const newMaxWidth = context.measureText(item[prop]).width + 20
      list[0][`${prop}MaxWid`] = maxWidth
        ? Math.max(maxWidth, newMaxWidth)
        : Math.max(titleMaxWidth, newMaxWidth)
    })
  }
  return list
}

export default tool
