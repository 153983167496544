import { Loading } from 'element-ui'
import axios from 'axios' // 在引用组件
import myConfig from '@/utils/config'
import store from '@/store'
import { getLang } from '@/libs_sz/plugins/loadLang'

/**
 *  登录逻辑说明
 *
 *  1. 过滤所有的页面切换，都要请求sessionId, 如果成功可以打开下一个页面，如果不成功，是否异常，是异常，则不带returnUrl, 不是异常则跳转到登录带returnUrl,
 *
 *  2. 如果sessionId接口判断没有权限，则跳转403，并且修改退出，不带returnUrl;
 *
 */

// 登录操作
const toLogin = (cred) => store.dispatch('toLogin', { cred })

// 跳转到登录
const toLoginPage = (isReturn = true) => {
  const [host, argument] = location.href.split('?')
  if (isReturn) {
    let url = host
    if (argument) {
      url += '?'
      // 过滤cred
      const params = [].concat(argument.split('&'))
      const validParams = params.filter(
        (item) => !~item.indexOf('cred') || !~item.indexOf('system')
      )
      url += validParams.join('&')
    }
    location.href = `${myConfig.loginUrl}?systemCode=${
      store.state.systemCode
    }&r=${encodeURIComponent(url)}`
  } else {
    location.href = myConfig.loginUrl
  }
}

// 跳转到403页面
const to403 = () => {
  location.href = `${myConfig.authNoPerUrl}?systemCode=${store.state.systemCode}`
}

export const linkAuthMange = toLoginPage
export const isGetSessionId = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${myConfig.authUrl}/api/coreresource/auth/token/getSession/v1`, {
        headers: {
          'Accept-Language': getLang()
        },
        withCredentials: true
      })
      .then(({ data, code }) => {
        const SYSTEM = store.state.systemCode
        // 如果发生错误直接跳转登录页
        if (code) {
          toLoginPage()
          return
        }
        const { subsystemList, sessionId, isAuthenticated, userId } = data
        if (!sessionId || !isAuthenticated) {
          toLoginPage()
          return
        }
        const hasPermission = (subsystemList || []).find(
          (item) => item.code === SYSTEM
        )
        // 如果没有权限则直接跳转403页面；
        if (!hasPermission) {
          to403()
          return
        }
        // 其他则判断当前是否在登录状态；
        const old = store.state.cred

        // 纪录userId
        localStorage.setItem('avalonUserId', userId)
        // 如果旧的cred不存在， 或者 旧的cred不等于新的cred
        if (!old || old !== sessionId) {
          const loading = Loading.service({
            fullscreen: true,
            lock: true,
            text: 'logining...'
          })
          toLogin(sessionId)
            .then(() => {
              loading.close()
              resolve()
            })
            .catch(() => toLoginPage(false))
        } else {
          resolve()
        }
      })
      .catch(() => toLoginPage(false))
  })
export const noPermission = to403
export const getNoAuthSessionId = () => store.dispatch('noAuthToLogin')
