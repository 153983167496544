<template>
  <div>
    <div class="cropper-content">
      <div class="cropper-box">
        <div class="cropper">
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.outputSize"
            :output-type="option.outputType"
            :info="option.info"
            :can-scale="option.canScale"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :fixed="option.fixed"
            :fixed-number="option.fixedNumber"
            :full="option.full"
            :fixed-box="option.fixedBox"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :original="option.original"
            :center-box="option.centerBox"
            :height="option.height"
            :info-true="option.infoTrue"
            :max-img-size="option.maxImgSize"
            :enlarge="option.enlarge"
            :mode="option.mode"
            @realTime="realTime"
            @imgLoad="imgLoad"
          />
        </div>
      </div>
      <!--预览效果图-->
      <div class="show-preview">
        <div :style="previews.div" class="preview">
          <img :src="previews.url" :style="previews.img">
        </div>
      </div>
    </div>
    <!--底部操作工具按钮-->
    <div class="footer-btn">
      <div class="scope-btn">
        <!-- 选择图片 -->
        <label class="btn" for="uploads">
          {{ $t("libsSz.key85") }}
        </label>
        <input
          id="uploads"
          type="file"
          style="position:absolute; clip:rect(0 0 0 0);"
          accept="image/png, image/jpeg, image/gif, image/jpg"
          @change="selectImg($event)"
        >
        <!-- 放大 -->
        <el-button
          size="mini"
          type="danger"
          plain
          icon="el-icon-zoom-in"
          @click="changeScale(1)"
        >
          {{ $t("libsSz.key88") }}
        </el-button>
        <!-- 缩小 -->
        <el-button
          size="mini"
          type="danger"
          plain
          icon="el-icon-zoom-out"
          @click="changeScale(-1)"
        >
          {{ $t("libsSz.key89") }}
        </el-button>
        <!-- 左旋转 -->
        <el-button
          size="mini"
          type="danger"
          plain
          @click="rotateLeft"
        >
          ↺{{ $t("libsSz.key86") }}
        </el-button>
        <!-- 右旋转 -->
        <el-button
          size="mini"
          type="danger"
          plain
          @click="rotateRight"
        >
          ↻{{ $t("libsSz.key87") }}
        </el-button>
        <!-- <el-button size="mini" type="danger" plain @click="clearCrop">
          清除图片
        </el-button> -->
        <el-button
          size="mini"
          type="primary"
          class="w100 r"
          @click="handleSure"
        >
          {{ $t("libsSz.key15") }}
        </el-button>
      </div>
      <!-- <div class="upload-btn">
          <el-button size="mini" type="success" @click="uploadImg('blob')">
            上传图片
            <i class="el-icon-upload"></i>
          </el-button>
        </div> -->
    </div>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";

export default {
  name: "MImageCropper",
  components: {
    VueCropper,
  },
  props: ["Name"],
  data() {
    return {
      name: this.Name,
      previews: {},
      option: {
        img: "", // 裁剪图片的地址
        outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", // 裁剪生成图片的格式（jpeg || png || webp）
        info: true, // 图片大小信息
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 230, // 默认生成截图框宽度
        autoCropHeight: 150, // 默认生成截图框高度
        fixed: false, // 是否开启截图框宽高固定比例
        fixedNumber: [1.53, 1], // 截图框的宽高比例
        full: false, // false按原比例裁切图片，不失真
        fixedBox: false, // 固定截图框大小，不允许改变
        canMove: false, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        height: true, // 是否按照设备的dpr 输出等比例图片
        infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, // 限制图片最大宽度和高度
        enlarge: 1, // 图片根据截图框输出比例倍数
        mode: "230px 150px", // 图片默认渲染方式
      },
    };
  },
  methods: {
    // 初始化函数
    imgLoad(msg) {
      console.warn(`工具初始化函数=====${msg}`);
    },
    // 图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    clearCrop() {
      this.$refs.cropper.clearCrop();
    },
    // 选择图片
    selectImg(e) {
      const file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: this.$t("lang.ark.fed.imageTypeJudge"),
          type: "error",
        });
        return false;
      }
      // 转化为blob
      const reader = new FileReader();
      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      // 转化为base64
      reader.readAsDataURL(file);
    },
    // 确定剪切完成
    handleSure() {
      // 获取截图的blob数据
      this.$refs.cropper.getCropBlob(data => {
        const files = new window.File([data], "cutImage", { type: data.type });
        this.$emit("cutDown", files);
      });
    },
    // 上传图片
    uploadImg(type) {
      const _this = this;
      if (type === "blob") {
        // 获取截图的blob数据
        this.$refs.cropper.getCropBlob(async data => {
          const formData = new FormData();
          formData.append("file", data, "DX.jpg");
          // 调用axios上传
          const { data: res } = await _this.$http.post("/api/file/imgUpload", formData);
          if (res.code === 200) {
            _this.$message({
              message: res.msg,
              type: "success",
            });
            const data = res.data
              .replace("[", "")
              .replace("]", "")
              .split(",");
            const imgInfo = {
              name: _this.Name,
              url: data[0],
            };
            _this.$emit("uploadImgSuccess", imgInfo);
          } else {
            console.warn("error");
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 300px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}
.footer-btn {
  margin-top: 30px;
  // display: flex;
  // display: -webkit-flex;
  // justify-content: flex-end;
  .scope-btn {
    // display: flex;
    // display: -webkit-flex;
    // justify-content: space-between;
    // padding-right: 10px;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
  }
}
</style>
