export const GET_STORE = 'ROBOT/GET_STORE'

// 更新标题
export const ROUTE_INFO = 'ROUTE_INFO'

// 更新标题
export const IS_LOADING = 'IS_LOADING'

/* 获取工作站配置 */
export const GET_ADMINCONFIG = 'ADMIN/GET_ADMINCONFIG'

// 当前ShowMenu
export const SHOW_MENU = 'SHOW_MENU'

// 用于控制菜单的扩展和收缩；
export const MENU_COLLAPSE = 'MENU_COLLAPSE'

// 更新菜单
export const UPDATE_MENU = 'UPDATE_MENU'

// 更新用户名
export const UPDATE_USERNAME = 'UPDATE_USERNAME'

// 更新用户名
export const UPDATE_CRED = 'UPDATE_CRED'

// 是否全屏
export const SET_ISFULLSCREEN = 'SET_ISFULLSCREEN'

// 是否正在编辑状态
export const UPDATE_EDITING_STATUS = 'UPDATE_EDITING_STATUS'
