<template>
  <div class="ui-mySpin">
    <div v-if="loading" class="fullSceen">
      <div
        v-loading="loading"
        class="ui-mySpin__full"
        :element-loading-text="title"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0,0,0,0)"
      />
    </div>

    <div class="ui-mySpin__content" :class="{ 'spin-blur': loading }">
      <slot />
    </div>
  </div>
</template>
<script>
/**
 *  主要防止element-ui loading ,有人反映比较容易晃眼，所以开发新的loading
 *
 *  @prop { loading: Boolean } 改变loading;
 *  @prop { title: String } 标题
 *
 */
export default {
  name:"MSpin",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return this.$t("libsSz.key16");
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.ui-mySpin {
  position: relative;
  height: 100%;
}
.fullSceen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.ui-mySpin__full {
  height: 100%;
  width: 100%;
}
.ui-mySpin__content {
  position: relative;
  transition: opacity 0.3s;
  height: 100%;
}
.spin-blur {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  opacity: 0.5;
  color: rgba(0, 0, 0, 0.65);
}
</style>
