import mapVerify from "../../utils/formValidateV2";


// 获取默认的权限类型
export const defaultTypeOptions = that => [
  { label: that.$t("libsSz.key24"), value: 1 },
];

// formData的默认数据
export const getFormData = (
  that,
  {
    name = "",
    descr = "",
    type = "",
    subsystem = "",
    typeOptions = [],
    subsystemOptions = [],
    disabledIndex = 0,
  },
) => [
  {
    name: "name",
    label: that.$t("libsSz.key18"),
    value: name,
    component: "elInput",
    span: 6,
    disabled: disabledIndex === 1,
    placeholder: that.$t("libsSz.key22"),
    rules: mapVerify("required"),
  },
  {
    name: "descr",
    label: that.$t("libsSz.key19"),
    value: descr,
    component: "elInput",
    span: 6,
    disabled: disabledIndex === 2,
    placeholder: that.$t("libsSz.key22"),
    rules:mapVerify("required"),
  },
  {
    name: "type",
    label: that.$t("libsSz.key20"),
    value: type,
    component: "elSelect",
    span: 6,
    disabled: typeOptions && typeOptions.length < 2 || disabledIndex === 3,
    placeholder: that.$t("libsSz.key23"),
    data: typeOptions,
    rules: mapVerify("required"),
  },
  {
    name: "subsystem",
    label: that.$t("libsSz.key21"),
    value: subsystem || [],
    multiple: true,
    component: "elSelect",
    span: 6,
    change(value) {
      that.subsystemChange(value);
    },
    disabled: disabledIndex === 4,
    placeholder: that.$t("libsSz.key23"),
    data: subsystemOptions,
    rules: mapVerify("required"),
  },
];

export const getUserData = that => [
  {
    prop: "userName",
    label: that.$t("libsSz.key1"),
  },
  {
    prop: "realName",
    label: that.$t("libsSz.key27"),
  },
  {
    prop: "phone",
    label: that.$t("libsSz.key28"),
  },
];

// 获取tabs数据
export const getTabsData = (that, { webData = [], userData = [] }) => [
  {
    label: `${that.$t("libsSz.key33")}${that.$t("libsSz.key24")}`,
    name: "web",
    slotName: "MAuthTree",
    data: webData,
  },
  {
    label: that.$t("libsSz.key25"),
    name: "user",
    slotName: "MAuthTable",
    data: userData,
  },
];
