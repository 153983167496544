import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'
// gles/base/batchProperty/listbatchPropertyByPage?currentPage=2&pageSize=3

/**
 * 物料管理：批属性，物料档案,物料分类相关接口
 */
const apis = {
  // 批属性 增删改查
  getBatchPropertyList: {
    method: 'post',
    url: `${glesRoot}/base/batchProperty/list/page`,
    describe: '查询批属性列表',
    isNeedLoading: false
  },
  queryBatchPropertyDetail: {
    method: 'post',
    url: `${glesRoot}/base/batchProperty/queryBatchPropertyDetail`,
    describe: '查询批属性详情',
    isNeedLoading: false
  },
  addBatchProperty: {
    method: 'post',
    url: `${glesRoot}/base/batchProperty/addBatchProperty`,
    describe: '新增批属性',
    isNeedLoading: false
  },
  deleteBatchProperty: {
    method: 'post',
    url: `${glesRoot}/base/batchProperty/deleteBatchProperty`,
    describe: '删除批属性',
    isNeedLoading: false
  },
  updateBatchProperty: {
    method: 'post',
    url: `${glesRoot}/base/batchProperty/updateBatchProperty`,
    describe: '编辑批属性',
    isNeedLoading: false
  },
  // 物料分类
  queryClasifyByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/materialClassify/list/classify`,
    describe: '根据类型或者编码查询分类列表',
    isNeedLoading: false
  },
  querySecondClasifyById: {
    method: 'post',
    url: `${glesRoot}/base/materialClassify/list/page/classify`,
    describe: '根据一级分类id查询二级分类列表',
    isNeedLoading: false
  },
  addClassify: {
    method: 'post',
    url: `${glesRoot}/base/materialClassify/addClassify`,
    describe: '新增分类',
    isNeedLoading: false
  },
  updateClassify: {
    method: 'post',
    url: `${glesRoot}/base/materialClassify/updateClassify`,
    describe: '编辑分类',
    isNeedLoading: false
  },
  delClassify: {
    method: 'post',
    url: `${glesRoot}/base/materialClassify/deleteClassify`,
    describe: '物料分类删除',
    isNeedLoading: false
  },
  // 物料档案 增删改查
  getMaterialListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/material/list/page/byCodeOrName`,
    describe: '查询物料档案列表',
    isNeedLoading: false
  },
  getMaterialList: {
    method: 'post',
    url: `${glesRoot}/base/material/list/page/byCondition`,
    describe: '更多查询物料档案列表',
    isNeedLoading: false
  },
  addMaterial: {
    method: 'post',
    url: `${glesRoot}/base/material/addMaterial`,
    // config: {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // },
    describe: '新增物料档案',
    isNeedLoading: false
  },
  queryMaterialDetail: {
    method: 'post',
    url: `${glesRoot}/base/material/queryMaterialDetail`,
    describe: '物料档案详情',
    isNeedLoading: false
  },
  deleteMaterial: {
    method: 'post',
    url: `${glesRoot}/base/material/deleteMaterial`,
    describe: '删除物料档案',
    isNeedLoading: false
  },
  updateMaterial: {
    method: 'post',
    url: `${glesRoot}/base/material/updateMaterial`,
    // config: {
    //   headers: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // },
    describe: '编辑物料档案',
    isNeedLoading: false
  },
  getContainerType: {
    method: 'post',
    url: `${glesRoot}/base/container/type/queryContainerTypeList`,
    describe: '获取容器类型',
    isNeedLoading: false
  },
  getContainerCode: {
    method: 'post',
    url: `${glesRoot}/base/container/archives/queryContainerOptionByCond`,
    describe: '获取容器编码',
    isNeedLoading: false
  },
  getBatchProperty_dict: {
    method: 'post',
    url: `${glesRoot}/base/batchProperty/list/option`,
    describe: '获取批属性下拉',
    isNeedLoading: false
  },
  queryMaterialOption: {
    method: 'post',
    url: `${glesRoot}/base/material/queryMaterialOption`,
    describe: '获取物料编码下拉',
    isNeedLoading: false
  },
  // 查询工位 列表
  getMaterialConfigListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/base/workPositionMaterial/list/page/byCodeOrName`,
    describe: '查询工位列表',
    isNeedLoading: true
  },
  // 更多查询根据类型，查询工位位置
  getMaterialConfigMoreList: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/getWorkPositionByType`,
    describe: '查询工位列表',
    isNeedLoading: true
  },
  // 更多查询 确定
  getMoreList: {
    method: 'post',
    url: `${glesRoot}/base/workPositionMaterial/list/page/byCond`,
    describe: '查询工位列表',
    isNeedLoading: true
  },
  // 新增工位配置
  addMaterialConfig: {
    method: 'post',
    url: `${glesRoot}/base/workPositionMaterial/add`,
    describe: '新增工位配置',
    isNeedLoading: true
  },
  // // 编辑工位配置
  // EditMaterialConfig: {
  //   method: 'post',
  //   url: `${glesRoot}/base/workPositionMaterial/updateWorkPosition`,
  //   describe: '编辑工位配置',
  //   isNeedLoading: true
  // },
  // 修改工位配置
  UpdateMaterialConfig: {
    method: 'post',
    url: `${glesRoot}/base/workPositionMaterial/update`,
    describe: '编辑工位配置',
    isNeedLoading: true
  },
  // 查询编辑当前的工位配置物料
  searchEditMaterialConfig: {
    method: 'post',
    url: `${glesRoot}/base/workPositionMaterial/detail`,
    describe: '编辑工位配置',
    isNeedLoading: true
  },
  // 删除工位配置
  delectMaterialConfig: {
    method: 'post',
    url: `${glesRoot}/base/workPositionMaterial/delete`,
    describe: '删除工位配置',
    isNeedLoading: true
  },
  // 更多查询-- 工位位置下拉
  getMaterialConfigPlace: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/getWorkPositionByType`,
    describe: '工位位置',
    isNeedLoading: true
  },
  // 更多查询-- 物料编码下拉
  getMaterialConfigCodeOptions: {
    method: 'post',
    url: `${glesRoot}/base/material/queryMaterialOption`,
    describe: '物料编码',
    isNeedLoading: true
  }
}

export default function (apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
