<template>
  <el-popover v-model="popverVisible" placement="top" popper-class="w-auto">
    <p class="nowrap mt5 mb10">
      <i class="el-icon-warning warn p5" />
      {{ message }}
    </p>
    <div class="tr">
      <el-button size="mini" type="text" @click="popverVisible = false">
        {{
          $t("libsSz.key14")
        }}
      </el-button>
      <el-button
        type="primary"
        size="mini"
        :loading="loading"
        @click="confirmSubmit"
      >
        {{
          $t("libsSz.key15")
        }}
      </el-button>
    </div>
    <el-button
      v-bind="$attrs"
      slot="reference"
      :class="confirmClass"
      @click.stop
    >
      {{ label }}
    </el-button>
  </el-popover>
</template>
<script>
/**
 *  确认按钮，主要代理需要确认的按钮，比如删除，
 *
 *  @props { label } 按钮文案
 *  @props { message } 确认文案
 *  @props { confirmClass } 描述当前按钮的样式；
 *
 *  @event { sureClick } 确定点击事件
 *
 */
export default {
  name: "MConfirmBtn",
  props: {
    label: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    confirmClass: {
      type: String,
    },
  },
  data() {
    return {
      popverVisible: false,
      loading:false,
    };
  },
  methods: {
    confirmSubmit() {
      this.loading=true;
      this.popverVisible = false;
      this.$emit("sureClick");
      // 增加点击延迟
      setTimeout(() => {
        this.loading= false;
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.el-popover.w-auto {
  width: auto !important;
  padding: 12px 16px !important;
}
.warn {
  color: #faad14;
}
</style>
