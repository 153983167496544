import { httpService } from '@/libs_sz/plugins/httpService'
import apiBase from '@/apis/index'
import { t } from '@/libs_sz/locale'

export const getSelectOptions = (apiName, params) => {
  return new Promise((resolve, reject) => {
    httpService(apiBase.base(apiName), params)
      .then(({ data, code }) => {
        if (code !== 0) {
          reject()
          return
        }
        let { baseDictDetails: recordList = [] } = data
        if (Array.isArray(data)) {
          recordList = data
        }
        const list = recordList.map((item) => {
          if (item.containerTypeCode) {
            return {
              label: t(item.factoryPositionName),
              value: item.factoryPositionCode,
              factoryPositionType: item.factoryPositionType
            }
          }
          if (item.factoryPositionCode) {
            return {
              label: item.factoryPositionName,
              value: item.factoryPositionCode,
              factoryPositionType: item.factoryPositionType
            }
          }
          // detailCodeType 字典code类型  1 int 0 string
          return {
            label: t(item.detailName),
            value: item.detailCodeType ? parseInt(item.detailCode) : item.detailCode
          }
        })
        resolve(list)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getMaterialOptions = (apiName, params) => {
  return new Promise((resolve, reject) => {
    httpService(apiBase.materialManage(apiName), params)
      .then(({ data, code }) => {
        if (code !== 0) {
          reject()
          return
        }
        const list = data.map((item) => {
          return {
            label: item.classifyName,
            value: item.id
          }
        })
        resolve(list)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// 物料编码
export const getMaterialCodeOptions = (apiName, params) => {
  return new Promise((resolve, reject) => {
    httpService(apiBase.materialManage(apiName), params)
      .then(({ data, code }) => {
        if (code !== 0) {
          reject()
          return
        }
        const list = data.map((item) => {
          return {
            label: item.materialCode,
            value: item.id,
            name: item.materialName
          }
        })
        resolve(list)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * 获取自增编码
 * @param {*} bizType 业务类型
 * @param {*} codeKey code对应字段名
 */
export const getSerialBizCode = async (bizType = '', codeKey) => {
  return new Promise((resolve, reject) => {
    httpService(apiBase.base('getSerialBizCode'), { bizType })
      .then(({ data, code }) => {
        if (code !== 0) {
          reject()
          return
        }
        resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
