// 辅助函数，获取CSS相关属性
const getAttr = (obj, key) =>
  obj.currentStyle ? obj.currentStyle[key] : window.getComputedStyle(obj, false)[key];

export default {
  inserted(el) {
    const dialogTarget = el.children[0];
    const header = dialogTarget.children[0];

    header.style.cursor = "move";

    // ===  计算边界 ====

    header.onmousedown = e => {
      const currentX = e.clientX;
      const currentY = e.clientY;

      const left = parseInt(getAttr(dialogTarget, "left"));
      const top = parseInt(getAttr(dialogTarget, "top"));

      const minLeft = dialogTarget.offsetLeft + parseInt(getAttr(dialogTarget, "width")) - 50;
      const maxLeft = parseInt(getAttr(document.body, "width")) - dialogTarget.offsetLeft - 50;
      const minTop = dialogTarget.offsetTop;
      const maxTop =
        parseInt(getAttr(document.body, "height")) -
        dialogTarget.offsetTop -
        parseInt(getAttr(header, "height"));

      document.onmousemove = event => {
        const diffX = event.clientX - currentX;
        const diffY = event.clientY - currentY;

        // 判断左、右边界
        if (diffX < 0 && diffX <= -minLeft) {
          dialogTarget.style.left = `${left - minLeft}px`;
        } else if (diffX > 0 && diffX >= maxLeft) {
          dialogTarget.style.left = `${left + maxLeft}px`;
        } else {
          dialogTarget.style.left = `${left + diffX}px`;
        }

        // 判断上、下边界
        if (diffY < 0 && diffY <= -minTop) {
          dialogTarget.style.top = `${top - minTop}px`;
        } else if (diffY > 0 && diffY >= maxTop) {
          dialogTarget.style.top = `${top + maxTop}px`;
        } else {
          dialogTarget.style.top = `${top + diffY}px`;
        }
        return false;
      };

      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
  update(el) {
    // const target = el.children[0];
    // target.style.left = '';
    // target.style.top='';
  },
  unbind(el) {
    const header = el.children[0].children[0];
    header.onmousedown = null;
  },
};
