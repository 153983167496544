/**
 *  代理处理国际化相关
 *
 *  1. 代处理libs语言包导入和i18n桥接
 *  2. 代处理elementUi语言包导入和i18n桥接
 *  3. 代管理语言处理逻辑。（增加，删除， 修改， 查询）
 *  4. 代处理语言转换， （需要配置是否增加区域）
 *
 *  @param { String } lang; // 初始化语言，不传有默认；
 *  @param { Function } mappingLanguage;
 *     1. 如果传方法，则必须返回语言项， 实际场景会存在en_us,en,en_CA,可以统一修改为en
 *     2. 如果不传，默认不支持地区性语言，即 en_us,en,en_CA为en；
 *     3. 建议不覆盖默认的Function
 *  @param { [Array<Promise> | Promise]Function } loadExtendLibs;
 *     1.如果不为空，则必须有返回值；
 *  @param { [Object] Function } loadProject;
 *     1. 如果不为空，则必须返回值；
 *
 *  备注，
 *    1.  默认后台接入的是架构组那边的国际化后台接口， 因为架构组那边的请求参数，是由 [语言]_[地区构成]和前端略微有写不一样。
 *    2.  限制于当前element不支持地区，所以前端只能设置语言；
 */

import VueI18n from "vue-i18n";
import Vue from "vue";
import element_locale from "element-ui/lib/locale";
import libs_local from "../locale";
import axios from "axios";
import de from "../locale/lang/de";
import en from "../locale/lang/en";
import ja from "../locale/lang/ja";
import zhCN from "../locale/lang/zh-CN";

Vue.use(VueI18n);

// 默认配置项；
const defaultOptions = {
  lang: localStorage.getItem("language") || window.navigator.language,
  // 建议不覆盖；
  mappingLanguage(lang) {
    let curLang = lang;
    switch (lang) {
      case "zh-CN":
      case "zh-TW":
      case "zh":
        curLang = "zh-CN";
        break;
      case "en_us":
      case "en":
      case "en-US":
      case "en-GB":
      case "en-CA":
        curLang = "en";
        break;
      case "ja-jp":
      case "ja":
        curLang = "ja";
        break;
      case "de":
      case "de-DE":
      case "de_DE":
        curLang = "de";
        break;
      default:
        break;
    }
    return curLang;
  },
  loadExtendLibs: null,
  loadProject: null,
};

// 映射语言和语言地区
const mapLanguageToLocal = {
  zh: "zh-CN",
  en: "en-US",
  ja: "ja-JP",
  de: "de-DE",
};

// 映射语言国际化文件
const localeMap = {
  de: de,
  en: en,
  ja: ja,
  "zh-CN": zhCN,
};

const i18n = new VueI18n();

const loadLang = {
  i18n,
};

class Language {
  constructor(options) {
    this.i18n = options.i18n;
    this.lang = options.lang;
    this.extendLibs = options.loadExtendLibs;
    this.loadProject = options.loadProject;
    this.mappingLanguage = options.mappingLanguage;
    this.enableLocalLanguage = options.hasOwnProperty("enableLocalLanguage") ? options.enableLocalLanguage : true; // 判断是否启用本地缓存语言，默认为true
    this.loadedLanguages = [];
  }

  // 加载所有的libs包；
  _loadLibsLanguages(lang) {
    return new Promise((resolve, reject) => {
      let libs = [localeMap[lang]];
      if (
        this.extendLibs &&
        Object.prototype.toString.call(this.extendLibs) === "[object Function]"
      ) {
        const result = this.extendLibs(lang);
        libs = libs.concat(Array.isArray(result) ? result : [result]);
      }
      Promise.all(libs)
        .then(values => {
          this._mergeLanguage(
            lang,
            values.reduce((preValue, curValue) => ({ ...preValue, ...(curValue.default || curValue) }), {}),
          );
          resolve();
        })
        .catch(error => {
          reject();
          return Promise.reject(new Error(`加载libs库报错 \n ${error}`));
        });
    });
  }

  // 加载用户请求包
  _loadProjectLanguages(lang) {
    return new Promise((resolve, reject) => {
      if (typeof this.loadProject === "function") {
        this.loadProject(this._getRequestLanguage(lang), data => {
          this._mergeLanguage(lang, data);
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  // 转换后台需要的language
  _getRequestLanguage(lang) {
    let res = this.mappingLanguage(lang);
    if (!~lang.indexOf("-")) {
      res = mapLanguageToLocal[lang] || lang;
    }
    return String(res)
      .replace("-", "_")
      .toLocaleLowerCase();
  }

  // 设置当前语言；
  _setI18nLanguage(lang) {
    this.i18n.locale = lang;
    this.lang = lang;
    localStorage.setItem("language", lang);
    if (!this.loadedLanguages.includes(lang)) {
      this.loadedLanguages.push(lang);
      axios.defaults.headers.common["Accept-Language"] = this._getRequestLanguage(lang);
    }
    return lang;
  }

  // 合并值
  _mergeLanguage(lang, data) {
    this.i18n.mergeLocaleMessage(lang, data);
    const languageData = this.i18n.getLocaleMessage(lang);
    if (this.enableLocalLanguage) {
      // 保存到本地
      localStorage.setItem("languageData", JSON.stringify(languageData));
    }
  }

  // 语言改变
  changeLanguage(lang, successCb, failCb) {
    const myLang = this.mappingLanguage(lang);
    if (this.enableLocalLanguage) {
      // 先读取localstorage有没有存
      try {
        const languageData = JSON.parse(localStorage.getItem("languageData"));
        if (languageData && typeof languageData === "object") {
          this.i18n.setLocaleMessage(myLang, languageData);
          this.i18n.locale = lang;
          this.lang = lang;
        }
      } catch (e) {
        // 没有拉倒
      }
    }

    if (!this.loadedLanguages.includes(myLang)) {
      Promise.all([this._loadLibsLanguages(myLang), this._loadProjectLanguages(myLang)])
        .then(() => {
          successCb && successCb();
          this._setI18nLanguage(myLang);
        })
        .catch(error => {
          failCb && failCb();
          return Promise.reject("加载语言报错");
        });
    } else {
      this._setI18nLanguage(myLang);
    }
  }

  _main() {
    this.changeLanguage(this.lang);
  }
}

let getLang = null;
let setLangs = null;

loadLang.install = (vue, options) => {
  const moptions = Object.assign(defaultOptions, options, { i18n });
  // 使用VueI18n
  // 注册公共的i18n代理；
  element_locale.i18n((key, value) => i18n.t(key, value));
  libs_local.i18n((key, value) => i18n.t(key, value));
  // 新建语言对象
  const languageItem = new Language(moptions);
  languageItem._main();
  // 更新属性
  vue.prototype.$getLang = getLang = () => languageItem.lang;
  // 更改属性
  vue.prototype.$setLangs = setLangs = function () {
    languageItem.changeLanguage(...arguments);
  };
};

export { i18n, getLang, setLangs };
export default loadLang;
