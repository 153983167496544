import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'
/**
 * 物流管理：相关接口
 */
const apis = {
  // 模板 增删改查
  getWorkTemplateListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/list/page`,
    describe: '根据名称或编码查询模板列表',
    isNeedLoading: false
  },
  getWorkTemplateList: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/list/pageByCondition`,
    describe: '查询模板列表',
    isNeedLoading: false
  },
  queryTemplateDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/getTemplateDetails`,
    describe: '查询模板详情',
    isNeedLoading: false
  },
  addWorkTemplate: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/addWorkTemplate`,
    describe: '新增模板',
    isNeedLoading: false
  },
  deleteWorkTemplate: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/deleteTemplate`,
    describe: '删除模板',
    isNeedLoading: false
  },
  updateWorkTemplate: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/editWorkTemplate`,
    describe: '编辑模板',
    isNeedLoading: false
  },
  changeTemplateStatus: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/changeTemplateStatus`,
    describe: '模板状态变更',
    isNeedLoading: false
  },
  getWorkTemplateByRobotType: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/getWorkTemplateByRobotType`,
    describe: '获取模板列表',
    isNeedLoading: false
  },
  getExecTemplateList: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/getExecTemplate`,
    describe: '获取机器人执行模板列表',
    isNeedLoading: false
  },
  // 节点编排
  getStandardTemplates: {
    method: 'post',
    url: `${glesRoot}/workflow/node-layout/getStandardTemplates`,
    describe: '通过机器人类型获取标准模板',
    isNeedLoading: false
  },
  getTemplateNodeList: {
    method: 'post',
    url: `${glesRoot}/workflow/node-layout/listStandardTemplateNodesPropsInfo`,
    describe: '获取模板对应节点列表',
    isNeedLoading: false
  },
  saveNode: {
    method: 'post',
    url: `${glesRoot}/workflow/node-layout/save`,
    describe: '保存节点',
    isNeedLoading: false
  },
  saveAndEnableNode: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/saveAndEnable`,
    describe: '保存并启用节点',
    isNeedLoading: false
  },
  getWorkTemplateNodesPropsInfo: {
    method: 'post',
    url: `${glesRoot}/workflow/node-layout/getWorkTemplateNodesPropsInfo`,
    describe: '通过作业模板编码获取作业模板节点信息',
    isNeedLoading: false
  },
  // 工位配置
  getWorkPositionConfigList: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/listByCondition`,
    describe: '查询工位列表',
    isNeedLoading: false
  },
  getWorkPositionConfigListByCodeOrName: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/list`,
    describe: '查询工位列表',
    isNeedLoading: false
  },
  queryPositionConfigDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/workPositionConfigDetails`,
    describe: '工位配置详情',
    isNeedLoading: false
  },
  addWorkPositionConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/addWorkPositionConfig`,
    describe: '添加工位',
    isNeedLoading: false
  },
  updateWorkPositionConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/editWorkPositionConfig`,
    describe: '编辑工位',
    isNeedLoading: false
  },
  deleteWorkPositionConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/delWorkPositionConfig`,
    describe: '删除工位',
    isNeedLoading: false
  },
  getWorkPositionByType: {
    method: 'post',
    url: `${glesRoot}/workflow/workPositionConfig/getWorkPositionByType`,
    describe: '根据工位类型获取工位列表',
    isNeedLoading: false
  },
  getInterfaceConfigList: {
    method: 'post',
    url: `${glesRoot}/workflow/interfaceConfig/list`,
    describe: '获取接口配置列表',
    isNeedLoading: false
  },
  deleteInterfaceConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/interfaceConfig/delInterfaceConfig`,
    describe: '删除接口配置',
    isNeedLoading: false
  },
  addInterfaceConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/interfaceConfig/addInterfaceConfig`,
    describe: '添加接口配置',
    isNeedLoading: false
  },
  changeInterfaceStatus: {
    method: 'post',
    url: `${glesRoot}/workflow/interfaceConfig/changeStatus`,
    describe: '接口启用/停用',
    isNeedLoading: false
  },
  getInterfaceLogList: {
    method: 'post',
    url: `${glesRoot}/workflow/interfaceLog/list`,
    describe: '接口日志列表',
    isNeedLoading: false
  },
  getInterfaceLogDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/interfaceLog/details`,
    describe: '接口日志详情',
    isNeedLoading: false
  },
  // 理货物流配置
  addTallyLogisticsConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/add`,
    describe: '新增理货物流配置',
    isNeedLoading: false
  },
  enableTallyLogisticsConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/enable`,
    describe: '启用接口',
    isNeedLoading: false
  },
  disableTallyLogisticsConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/disable`,
    describe: '停用接口',
    isNeedLoading: false
  },
  queryTallyLogisticsConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/listByPage`,
    describe: '查询配置接口',
    isNeedLoading: false
  },
  updateTallyLogisticsConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/update`,
    describe: '更新',
    isNeedLoading: false
  },
  deleteTallyLogisticsConfig: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/delete`,
    describe: '删除',
    isNeedLoading: false
  },
  queryTallyLogisticsConfigDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/tallyLogisticsConfig/details`,
    describe: '详情',
    isNeedLoading: false
  },
  // 计划任务
  getPlanTaskList: {
    method: 'post',
    url: `${glesRoot}/workflow/planTask/queryPage`,
    describe: '计划任务列表',
    isNeedLoading: false
  },
  queryPlanTaskDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/planTask/queryDetailById`,
    describe: '计划任务详情',
    isNeedLoading: false
  },
  saveOrUpdatePlanTask: {
    method: 'post',
    url: `${glesRoot}/workflow/planTask/saveOrUpdate`,
    describe: '新增/修改计划任务',
    isNeedLoading: false
  },
  operatePlanTaskById: {
    method: 'post',
    url: `${glesRoot}/workflow/planTask/operateById`,
    describe: '运行/终止/删除计划任务',
    isNeedLoading: false
  },
  queryPlanTaskRecords: {
    method: 'post',
    url: `${glesRoot}/workflow/planTask/queryPageRecords`,
    describe: '计划任务-运行记录',
    isNeedLoading: false
  },
  getUsedExecActionList: {
    method: 'post',
    url: `${glesRoot}/workflow/planTask/queryAll`,
    describe: '已关联计划任务的执行动作',
    isNeedLoading: false
  },
  addOrEditDmpNode: {
    method: 'post',
    url: `${glesRoot}/workflow/templatePdaConfig/addOrEdit`,
    describe: '添加/修改Dmp节点',
    isNeedLoading: false
  },
  deleteDmpNode: {
    method: 'post',
    url: `${glesRoot}/workflow/templatePdaConfig/delete`,
    describe: '删除Dmp节点',
    isNeedLoading: false
  },
  getDmpNodeList: {
    method: 'post',
    url: `${glesRoot}/workflow/templatePdaConfig/list`,
    describe: '获取Dmp节点列表',
    isNeedLoading: false
  },
  emptyDmpNode: {
    method: 'post',
    url: `${glesRoot}/workflow/templatePdaConfig/empty`,
    describe: '清空Dmp节点',
    isNeedLoading: false
  },
  getPositionNoticeList: {
    method: 'post',
    url: `${glesRoot}/workflow/positionNoticeConfig/list`,
    describe: '获取作业通知列表',
    isNeedLoading: false
  },
  deletePositionNotice: {
    method: 'post',
    url: `${glesRoot}/workflow/positionNoticeConfig/delete`,
    describe: '删除作业通知',
    isNeedLoading: false
  },
  addOrEditPositionNotice: {
    method: 'post',
    url: `${glesRoot}/workflow/positionNoticeConfig/addOrEdit`,
    describe: '添加/编辑作业通知',
    isNeedLoading: false
  },
  getWorkflowTemplate: {
    method: 'post',
    url: `${glesRoot}/workflow/workTemplate/getWorkflowTemplate`,
    describe: '获取模板信息',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
