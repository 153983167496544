export default {
  namespaced: true,
  state: {
    // 校验状态
    checkStatusList: [],
    // 入库单据类型
    storeInTypeList: [],
    // 出库单据类型
    storeOutTypeList: [],
    // 上下料单据类型
    materialsBillReceiptTypeList: [],
    // 合并后的单据类型
    receiptTypeList: [],
    // 作业类型
    operatingModeList: []
  },
  mutations: {
    changeState(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map((item) => {
        state[item] = payLoad[item]
        return true
      })
    }
  },
  actions: {
    /**
     * 获取入库单据类型
     * @param {*} params
     */
    getStoreInTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'storeInType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          storeInTypeList: list
        })
      })
    },
    /**
     * 获取出库单据类型
     * @param {*} params
     */
    getStoreOutTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'storeOutType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          storeOutTypeList: list
        })
      })
    },
    /**
     * 获取上下料单单据类型
     * @param {*} params
     */
    getMaterialsBillReceiptTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'materialsBillReceiptType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          materialsBillReceiptTypeList: list
        })
      })
    },
    /**
     * 获取合并后单据类型
     * @param {*} params
     */
    getReceiptTypeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'receiptType'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          receiptTypeList: list
        })
      })
    },
    /**
     * 获取作业类型
     * @param {*} params
     */
    getOperatingModeList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: {
            dictCode: 'operatingMode'
          }
        },
        {
          root: true
        }
      ).then((list) => {
        commit('changeState', {
          operatingModeList: list
        })
      })
    },
    /**
     * 校验状态
     * @param {*} params
     */
    getCheckStatusList({ dispatch, commit, state }, params) {
      dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'checkStatus' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          checkStatusList: list
        })
      })
    }
  }
}
