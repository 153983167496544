import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/** 维护router信息 */
export const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
    component: () => import('@/views/layout/Layout'),
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: {
          title: '首页'
        }
      },
      // 工厂
      {
        path: '/factory',
        name: 'factory',
        component: () => import('@/views/base/factory/index'),
        meta: {
          title: '工厂'
        }
      },
      // 车间
      {
        path: '/workshop',
        name: 'workshop',
        component: () => import('@/views/base/workshop/index'),
        meta: {
          title: '车间'
        }
      },
      // 仓库
      {
        path: '/warehouse',
        name: 'warehouse',
        component: () => import('@/views/base/warehouse/index'),
        meta: {
          title: '仓库'
        }
      },
      // 库区
      {
        path: '/warehouseArea',
        name: 'warehouseArea',
        component: () => import('@/views/base/warehouseArea/index'),
        meta: {
          title: '库区'
        }
      },
      // 区域
      {
        path: '/area',
        name: 'area',
        component: () => import('@/views/base/area/index'),
        meta: {
          title: '区域'
        }
      },
      // 产线
      {
        path: '/productionLine',
        name: 'productionLine',
        component: () => import('@/views/base/productionLine/index'),
        meta: {
          title: '产线'
        }
      },
      // 设备
      {
        path: '/device',
        name: 'device',
        component: () => import('@/views/base/device/index'),
        meta: {
          title: '设备'
        }
      },
      // 库位
      {
        path: '/factoryPosition',
        name: 'factoryPosition',
        component: () => import('@/views/base/factoryPosition/index'),
        meta: {
          title: '库位'
        }
      },
      // 货位
      {
        path: '/goodsPosition',
        name: 'goodsPosition',
        component: () => import('@/views/base/goodsPosition/index'),
        meta: {
          title: '货位'
        }
      },
      // 容器类型
      {
        path: '/containerType',
        name: 'containerType',
        component: () => import('@/views/containerManage/containerType/index'),
        meta: {
          title: '容器类型'
        }
      },
      // 容器档案
      {
        path: '/containerArchives',
        name: 'containerArchives',
        component: () => import('@/views/containerManage/containerFile/index'),
        meta: {
          title: '容器档案'
        }
      },
      // 物料--批属性
      {
        path: '/batchProperty',
        name: 'batchProperty',
        component: () => import('@/views/materialManage/batchProperty/index'),
        meta: {
          title: '批属性'
        }
      },
      // 物料--物料分类
      {
        path: '/materialClassify',
        name: 'materialClassify',
        component: () => import('@/views/materialManage/materialClassify/index'),
        meta: {
          title: '物料分类'
        }
      },
      // 物料档案
      {
        path: '/materialFile',
        name: 'materialFile',
        component: () => import('@/views/materialManage/materialFile/index'),
        meta: {
          title: '物料档案'
        }
      },
      // 物料 -- 工位物料配置
      {
        path: '/materialConfig',
        name: 'materialConfig',
        component: () => import('@/views/materialManage/materialConfig/index'),
        meta: {
          title: '工位物料配置'
        }
      },
      // 库存列表--在库库存-固定货位库存
      {
        path: '/fixedLocationInventory',
        name: 'fixedLocationInventory',
        component: () => import('@/views/inventoryList/stockInventory/fixedLocationInventory/index'),
        meta: {
          title: '固定货位库存'
        }
      },
      // 库存列表--在库库存-库位库存
      {
        path: '/locationInventory',
        name: 'locationInventory',
        component: () => import('@/views/inventoryList/stockInventory/locationInventory/index'),
        meta: {
          title: '库位库存'
        }
      },
      // 库存列表--在途库存
      {
        path: '/transportationInventory',
        name: 'transportationInventory',
        component: () => import('@/views/inventoryList/transportationInventory/index'),
        meta: {
          title: '在途库存'
        }
      },
      // 策略管理--上架策略
      {
        path: '/shelfStrategy',
        name: 'shelfStrategy',
        component: () => import('@/views/strategyManage/shelvesStrategy/index'),
        meta: {
          title: '上架策略'
        }
      },
      // 策略管理--命中策略
      {
        path: '/hitStrategy',
        name: 'hitStrategy',
        component: () => import('@/views/strategyManage/hitStrategy/index'),
        meta: {
          title: '命中策略'
        }
      },
      // 策略管理--机器人货位策略
      {
        path: '/robotGoodsPositionStrategy',
        name: 'robotGoodsPositionStrategy',
        component: () => import('@/views/strategyManage/robotStorageStrategy/index'),
        meta: {
          title: '机器人货位策略'
        }
      },
      // 策略管理--理货策略
      {
        path: '/tallyStrategy',
        name: 'tallyStrategy',
        component: () => import('@/views/strategyManage/tallyStrategy/index'),
        meta: {
          title: '理货策略'
        }
      },
      // 物流中心
      {
        path: '/workTemplate',
        name: 'workTemplate',
        component: () => import('@/views/workflow/workTemplate/index'),
        meta: {
          title: '作业模板'
        }
      },
      {
        path: '/workPosition',
        name: 'workPosition',
        component: () => import('@/views/workflow/workPositionConfig/index'),
        meta: {
          title: '工位配置'
        }
      },
      {
        path: '/tallyConfig',
        name: 'tallyConfig',
        component: () => import('@/views/workflow/tallyConfig/index'),
        meta: {
          title: '理货物流配置'
        }
      },
      {
        path: '/planTask',
        name: 'planTask',
        component: () => import('@/views/workflow/planTask/index'),
        meta: {
          title: '计划任务'
        }
      },
      // 单据管理-入库单-入库单
      {
        path: '/warehousingOrder',
        name: 'WarehousingOrder',
        component: () => import('@/views/orderManage/warehousingOrder/index'),
        meta: {
          title: '入库单'
        }
      },
      // 单据管理-入库单-外部单据
      {
        path: '/warehousingExternalOrder',
        name: 'WarehousingExternalOrder',
        component: () => import('@/views/orderManage/warehousingExternalOrder/index'),
        meta: {
          title: '外部入库单'
        }
      },
      // 单据管理-出库单-出库单
      {
        path: '/outWarehouseOrder',
        name: 'OutWarehouseOrder',
        component: () => import('@/views/orderManage/outWarehouseOrder/index'),
        meta: {
          title: '出库单'
        }
      },
      // 单据管理-出库单-外部单据
      {
        path: '/outWarehouseExternalOrder',
        name: 'OutWarehouseExternalOrder',
        component: () => import('@/views/orderManage/outWarehouseExternalOrder/index'),
        meta: {
          title: '外部出库单'
        }
      },
      // 单据管理-上下料单-上下料单
      {
        path: '/upAndDownMaterialOrder',
        name: 'UpAndDownMaterialOrder',
        component: () => import('@/views/orderManage/upAndDownMaterialOrder/index'),
        meta: {
          title: '上下料单'
        }
      },
      // 单据管理-上下料单-外部单据
      {
        path: '/upAndDownMaterialExternalOrder',
        name: 'UpAndDownMaterialExternalOrder',
        component: () => import('@/views/orderManage/upAndDownMaterialExternalOrder/index'),
        meta: {
          title: '外部上下料单'
        }
      },
      // 单据管理-库存调整单
      {
        path: '/stockAdjustmentOrder',
        name: 'StockAdjustmentOrder',
        component: () => import('@/views/orderManage/stockAdjustmentOrder/index'),
        meta: {
          title: '库存调整单'
        }
      },
      // 单据管理-理货单
      {
        path: '/tallyList',
        name: 'TallyingOrder',
        component: () => import('@/views/orderManage/tallyingOrder/index'),
        meta: {
          title: '理货单'
        }
      },
      // 监控管理-单据监控
      {
        path: '/receiptMonitor',
        name: 'OrderMonitor',
        component: () => import('@/views/monitorManage/orderMonitor/index'),
        meta: {
          title: '单据监控'
        }
      },
      // 监控管理-单据监控
      {
        path: '/receiptMonitor1',
        name: 'OrderMonitor',
        component: () => import('@/views/monitorManage/orderMonitor1/index'),
        meta: {
          title: '单据监控'
        }
      },
      // 接口管理-接口配置
      {
        path: '/glesInterfaceConfig',
        name: 'glesInterfaceConfig',
        component: () => import('@/views/interfaceManage/interfaceConfig/index'),
        meta: {
          title: '接口配置'
        }
      },
      {
        path: '/glesInterfaceLog',
        name: 'glesInterfaceLog',
        component: () => import('@/views/interfaceManage/interfaceLog/index'),
        meta: {
          title: '接口日志'
        }
      },
      {
        path: '/baseDict',
        name: 'baseDict',
        component: () => import('@/views/systemManage/baseDict/index'),
        meta: {
          title: '数据字典'
        }
      },
      {
        path: '/systemParam',
        name: 'systemParam',
        component: () => import('@/views/systemManage/systemConfig/index'),
        meta: {
          title: '系统参数'
        }
      },
      {
        path: '/editBaseDemo',
        name: 'editBaseDemo',
        component: () => import('@/views/editBaseDemo'),
        meta: {
          title: 'demo'
        }
      }
    ]
  },
  {
    path: '/403',
    name: '403',
    meta: { isValiLogin: false },
    component: () => import('@/views/errorMiss/errorMiss')
  }
]

/** 获取Router实例 */
const routerEntity = new Router({
  routes
})

export default routerEntity
