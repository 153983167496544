import Vue from 'vue'
import axios from 'axios'
import apiStore from '@/apis/index'
import loadLang, { i18n } from '@/libs_sz/plugins/loadLang'

Vue.use(loadLang, {
  loadProject(languageCode, next) {
    document.body.setAttribute('loadLanguage', 'true')
    const params = { languageCode }
    const request = [
      apiStore.coreresource.getLangItems_auth.url,
      apiStore.coreresource.getLangItems_gles.url
    ].map((g) => axios.get(g, { params }))
    Promise.allSettled(request).then((values) => {
      const successVals = values.filter(valItem => valItem.status === 'fulfilled').map(valItem => valItem.value.data.data)
      const data = successVals.reduce(
        (result, value) => Object.assign(result, value || {}),
        {}
      )
      document.body.removeAttribute('loadLanguage')
      next(data)
    })
  }
})

export function transMsg(text) {
  if (!text) return text

  if (String(text).indexOf('lang.') === 0) {
    return i18n.t(text)
  }

  let item
  try {
    item = JSON.parse(text)
    if (Object.prototype.toString.call(item) !== '[object Object]') {
      return text
    }
  } catch (error) {
    return text
  }

  if (item.p) {
    return i18n.t(
      item.c,
      item.p.map(x => transMsg(x))
    )
  }
  return i18n.t(item.c)
}

export default i18n

