import config from '@/utils/config'
// eslint-disable-next-line no-unused-vars
const { arkRoot, glesRoot, athenaRoot = '/athena', authUrl } = config

export default {
  authLogin: {
    method: 'post',
    url: `${authUrl}/api/coreresource/auth/login/v1`,
    describe: '登入',
    isNeedLoading: false
  },
  glesLogin: {
    method: 'post',
    url: `${glesRoot}/api/coreresource/auth/login/v1`,
    describe: '登入',
    isNeedLoading: false
  },
  authLoginout: {
    method: 'post',
    url: `${authUrl}/api/coreresource/auth/loginout/v1`,
    describe: '登出',
    isNeedLoading: false
  },
  getLangItems_athena: {
    method: 'get', // 结构请求的方式
    url: `${athenaRoot}/api/coreresource/i18n/getLangItems/v1`, // 接口URL
    describe: '获取字典 rms', // 标注
    isNeedLoading: false // 是否需要loading
  },
  getLangItems_auth: {
    method: 'post', // 结构请求的方式
    url: `${authUrl}/api/coreresource/i18n/getLangItems/v1`, // 接口URL
    describe: '获取权限字典 auth', // 标注
    isNeedLoading: false // 是否需要loading
  },
  getLangItems_ark: {
    method: 'get',
    url: `${arkRoot}/api/coreresource/i18n/getLangItems/v1`,
    describe: '获取翻译字段 ark',
    isNeedLoading: false
  },
  getLangItems_gles: {
    method: 'get',
    url: `${glesRoot}/api/coreresource/i18n/getLangItems/v1`,
    describe: '获取翻译字段 ark',
    isNeedLoading: false
  }
}
