import * as types from '@/store/mutationTypes'

const mutations = {
  [types.GET_STORE]: (state, data) => {
    state.stores = data
  },
  [types.ROUTE_INFO]: (state, routeInfo) => {
    state.routeInfo = routeInfo
  },
  [types.IS_LOADING]: (state, payload) => {
    state.loading = payload
  },
  [types.SHOW_MENU]: (state, payload) => {
    state.showMenu = payload
  },
  [types.MENU_COLLAPSE]: (state, payload) => {
    state.menuExpand = !state.menuExpand
  },
  [types.UPDATE_MENU]: (state, payload) => {
    state.menuData = payload.menuData
  },
  [types.UPDATE_USERNAME]: (state, payload) => {
    state.userName = payload.userName
  },
  [types.UPDATE_EDITING_STATUS]: (state, status) => {
    state.isEditingContent = status
  },
  // 通用更新
  common(state, payLoad) {
    const keys = Object.keys(payLoad)
    keys.map((item) => {
      state[item] = payLoad[item]
      return true
    })
  },
  [types.UPDATE_CRED]: (state, payload) => {
    state.cred = payload.cred
  },
  [types.SET_ISFULLSCREEN]: (state, isFullScreen) => {
    state.isFullScreen = isFullScreen
  }
}

export default mutations
