<template>
  <div>
    <template v-if="mySubSystem && mySubSystem.length">
      <div class="ui-mauthtree__content">
        <!-- 过滤关键字 -->
        <div class="ui-mauthtree__filter">
          <el-input
            v-model="importantKey"
            :placeholder="$t('libsSz.key26')"
            size="small"
            class="mt20 mb20 pct33"
            prefix-icon="el-icon-search"
          />
        </div>
        <!-- tree -->
        <div class="ui-mauthtree__box tl">
          <el-row type="flex" justify="space-between">
            <el-col
              v-for="(item, index) in mySubSystem"
              :key="index"
              :span="Math.max(8, 24/mySubSystem.length)-1"
            >
              <el-button type="text" class="pl10 lh40">{{ `${item.label}${$t("libsSz.key24")}` }}</el-button>
              <el-tree
                ref="myTree"
                :data="item.data"
                class="ui-mauthtree__tree"
                show-checkbox
                :props="item.props"
                :node-key="item.nodeKey"
                :default-expanded-keys="item.defaultCheckedKey"
                :default-checked-keys="item.defaultCheckedKey"
                :filter-node-method="filterNode"
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
    <template v-else>
      <el-button class="tc pct100 db pt30 pb30" type="text">{{ $t("libsSz.key32") }}</el-button>
    </template>
  </div>
</template>
<script>
/**
 *  @prop { subSystem } 当前权限列表
 *
 *  data举例子
 *
 *  [
 *     {
 *       label:"",// 子系统名称
 *       value:"", // 子系统唯一标识
 *       data:[], // 当前tree数据要求是children嵌套
 *       props:{}, // 表示描述叶节点，数据；
 *       nodeKey:"", // 树的唯一标识
 *       defaultCheckedKey:[], // 默认勾选的key
 *     }
 *
 *  ]
 *
 */
export default {
  props:{
    subSystem:{
      type:Array,
      default(){
        return [];
      },
    },
  },
  data(){
    return {
      importantKey:"", // 过滤key
    }
  },
  computed:{
    mySubSystem(){
      return this.subSystem;
    },
  },
  watch:{
    importantKey(val){
      this.subSystem.map((item, index) => {
        const ref = this.$refs.myTree[index]
        ref && ref.filter(val);
      });
    },
  },
  methods:{
    filterNode(value, data) {
      if (!value) return true;
      return (data.name||"").indexOf(value) !== -1;
    },
    getCheckKey(){
      const permissionKey = {};
      this.mySubSystem.map((item,index)=> {
        const isAliveRef = this.$refs.myTree[index];
        if(isAliveRef){
          permissionKey[item.value] = [...isAliveRef.getHalfCheckedKeys(), ...isAliveRef.getCheckedKeys()]
        }
      });
      return permissionKey;
    },
  },
}
</script>
