/**
 * 支持对存入的数据进行分页查询
 * 目前在 avaSelect 中优化下拉数据量大的问题
 */
export default class MicroStorage {
  constructor(storageList, option) {
    this.reset(storageList, option)
  }

  /**
     * 查询 可传入数据
     * pageSize
     * pageNumber
     * isQueryHistory
     * queryText,
     */
  async query(queryOption = {}) {
    const { queryStorageList } = this
    const {
      pageSize = this.pageSize,
      pageNumber = this.pageNumber,
      isQueryHistory = this.isQueryHistory
    } = queryOption
    return await new Promise((resolve) => {
      const startIndex = isQueryHistory ? 0 : (pageSize - 1) * pageNumber
      const endIndex = pageSize * pageNumber
      const len = queryStorageList.length

      if (len > startIndex) {
        this.pageNumber = pageNumber
        this.pageSize = pageSize
      }

      resolve(this.queryStorageList.slice(startIndex, endIndex))
    })
  }

  reset(storageList, option = {}) {
    this.storageList = storageList
    this.pageSize = option.pageSize || 1
    this.pageNumber = option.pageNumber || 30
    this.isQueryHistory = option.isQueryHistory || false
    this.queryRule = option.queryRule
    this.length = storageList.length
    this.queryStorageList = [...storageList]
  }

  // 根据配置信息获取下一页数据
  async nextPage() {
    return await this.query({
      pageSize: this.pageSize + 1,
      pageNumber: this.pageNumber,
      isQueryHistory: this.isQueryHistory
    })
  }

  // 查询关键字
  async queryText(value) {
    const { queryRule, storageList } = this
    this.queryStorageList = [...storageList.filter(item => {
      return queryRule(value, item)
    })]

    return await this.query({
      pageSize: 1
    })
  }

  // 是否已经滚动到最底
  isEnd() {
    const { length, pageSize, pageNumber } = this
    return pageSize * pageNumber >= length
  }
}

