const moreData = {
  namespaced: true,
  state: {
    fpCodeList: [],
    usableFactoryPositionList: [], // 容器档案中使用
    goodsPositionList: [],
    moveGoodsPositionList: [],
    moveContainerCodeList: []
  },
  mutations: {
    ADD_FPCODE_LIST: (state, data) => {
      state.fpCodeList = data
    },
    ADD_USABLE_FACTORY_POSITION_LIST: (state, data) => {
      state.usableFactoryPositionList = data
    },
    ADD_GOODS_POSITION_LIST: (state, data) => {
      state.goodsPositionList = data
    },
    ADD_MOVE_GOODS_POSITION_LIST: (state, data) => {
      state.moveGoodsPositionList = data
    },
    ADD_MOVE_CONTAINER_CODE_LIST: (state, data) => {
      state.moveContainerCodeList = data
    }
  },
  actions: {
    addFpCodeList({ commit }, data) {
      commit('ADD_FPCODE_LIST', data)
    },
    addUsableFactoryPositionList({ commit }, data) {
      commit('ADD_USABLE_FACTORY_POSITION_LIST', data)
    },
    addGoodsPositionList({ commit }, data) {
      commit('ADD_GOODS_POSITION_LIST', data)
    },
    addMoveGoodsPositionList({ commit }, data) {
      commit('ADD_MOVE_GOODS_POSITION_LIST', data)
    },
    addMoveContainerCodeList({ commit }, data) {
      commit('ADD_MOVE_CONTAINER_CODE_LIST', data)
    }
  }
}

export default moreData

