import MicroStorage from '../MicroStorage'

export default {
  data() {
    return {
      microStorage: new MicroStorage([]),
      queryStorageList: [],
      isQueryLoading: false,
      filterValue: ''
    }
  },
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          /**
            * scrollHeight 获取元素内容高度(只读)
            * scrollTop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
            * clientHeight 读取元素的可见高度(只读)
            * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
            * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
            */
          const condition = this.scrollHeight - this.scrollTop - 100 <= this.clientHeight
          condition && binding.value()
        })
      }
    }
  },
  watch: {
    selPageList: {
      handler(list) {
        const { microStorage } = this
        microStorage.reset(list, {
          pageSize: 1,
          pageNumber: 50,
          isQueryHistory: true,
          queryRule: this.queryRule
        })

        microStorage.query().then((data) => {
          this.queryStorageList = data
        })
      }
    }
  },
  created() {
    const { microStorage, selPageList } = this

    if (selPageList && selPageList.length) {
      microStorage.reset(selPageList, {
        pageSize: 1,
        pageNumber: 50,
        isQueryHistory: true,
        queryRule: this.queryRule
      })

      microStorage.query().then((data) => {
        this.queryStorageList = data
      })
    }
  },
  computed: {
    // 回填数据的查询结果
    queryBackFillList() {
      const { backFillList, queryRule, filterValue } = this
      return backFillList.filter((backFillItem) => {
        return queryRule(filterValue, backFillItem)
      })
    }
  },
  methods: {
    // 查询后的匹配规则
    queryRule(queryText, queryData) {
      const label = queryData[this.labelText]
      if (!queryText || String(label).includes(queryText)) {
        return true
      }
      return false
    },

    // 查询
    filterMethod(value) {
      this.filterValue = value

      const { microStorage } = this;

      // 查询后默认滚动到第一页的位置, 事实上只会有一个下拉是展开的情况, 所以可以将所有的下拉都进行调整
      [...document.querySelectorAll('.hSelectOption .el-select-dropdown__wrap')].forEach(element => {
        element.scrollTop = 0
      })

      microStorage.queryText(value).then((data) => {
        this.queryStorageList = data
      })
    },

    // 数据到达之后的懒加载
    loadmore() {
      if (!this.isQueryLoading) {
        this.isQueryLoading = true
        const { microStorage } = this
        microStorage.nextPage().then((data) => {
          this.queryStorageList = data
          this.isQueryLoading = false
        })
      }
    }
  }
}
