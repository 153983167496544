const state = {
  // 路由方向
  routerDirection: 'backward',
  routeInfo: '',
  systemCode: 'G-LES',
  menuData: [],
  menuExpand: false, // 控制是展开菜单
  userName: 'admin', // 控制是展开菜单
  count: 0,
  cred: '', // 当前登录凭证；
  isFullScreen: false,
  showMenu: true,
  /*
    是否正在编辑内容, 如果为true 在切换页面时将会触发警告
  */
  isEditingContent: false
}

export default state
