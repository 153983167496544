<template>
  <div ref="myDragWrap" @dragenter.prevent @dragover.prevent>
    <slot />
  </div>
</template>
<script>
// 如果有list，会记录位置，
/** dragWrap
 *
 *   Attribute
 *   @param { Array } list; 如果传，则会改变顺序，支持sync
 *   @param { String } sortType; 支持两种模式，exchange/queue; 直接交换， 顺序交换
 *
 *   不可以外层包裹div, 只能修改其样式；
 */

export default {
  name: "MDragWrap",
  componentName: "MDragWrap",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    sortType: {
      type: String,
      default: "exchange",
    },
  },
  data() {
    return {
      toDom: "",
      fromDom: "",
      children: [],
    };
  },
  created() {
    this.$on("add:child", $el => this.children.push($el));
    this.$on("drag:start", this._handleDragStart);
    this.$on("drag:end", this._handleDragEnd);
    this.$on("drag:enter", this._handleDragEnter);
  },
  destroyed() {
    this.$off("add:child");
    this.$off("drag:start");
    this.$off("drag:end");
    this.$off("drag:enter");
  },
  methods: {
    _handleDragStart(fromDom) {
      this.fromDom = fromDom;
      this._boardCast("drag:start", fromDom);
    },
    _isPrevNode(from, to) {
      const fromIndex = this.children.findIndex(i => i === from);
      const toIndex = this.children.findIndex(i => i === to);
      return fromIndex < toIndex;
    },
    // 补充insertAfter方法
    _insertAfter(toDom, fromDom) {
      // 获取现有节点的父元素
      const parent = fromDom.parentNode;

      // 如果父元素中的最后一个子元素 等于 现有的节点
      if (parent.lastChild === fromDom) {
        // 把现有节点放入父元素子节点后面
        // appendChild在子节点后面追加一个元素
        parent.appendChild(toDom);
      } else {
        // .nextSibling 该属性返回指定节点后的第一个节点
        // insertBefore 第一个参数插入的节点对象，第二参数可选，在其之前插入子节点，如果不传，则在结尾插入。
        parent.insertBefore(toDom, fromDom.nextSibling);
      }
    },
    // 交换两个元素
    _exchangeElements(element1, element2) {
      const temp1 = document.createElement("div");
      const temp2 = document.createElement("div");
      element1.parentNode.insertBefore(temp1, element1);
      element2.parentNode.insertBefore(temp2, element2);
      element1.parentNode.replaceChild(element1, temp2);
      element2.parentNode.replaceChild(element2, temp1);
    },
    _insertDom(fromDom, toDom, mode) {
      if (mode === "queue") {
        const isPreNode = this._isPrevNode(fromDom, toDom);
        isPreNode
          ? this._insertAfter(fromDom, toDom)
          : this.$refs.myDragWrap.insertBefore(fromDom, toDom);
      } else if (mode === "exchange") {
        this._exchangeElements(fromDom, toDom);
      }
    },
    _boardCast(eventName) {
      const dict = { "drag:start": "dragStart", "drag:end": "dragEnd" };
      this.$children.forEach(i => i[dict[eventName]](this.fromDom, this.toDom));
    },
    _handleDragEnter(toDom) {
      this.toDom = toDom;
      if (this.toDom === this.fromDom) return;
      this._insertDom(this.fromDom, toDom, this.sortType);
    },
    _getDataOrder(realList, dragBefore) {
      const order = realList.map(i => dragBefore.findIndex(a => a === i));
      const newData = [];
      order.forEach((i, n) => {
        newData[n] = this.list[i];
      });
      this.$emit("update:list", newData);
      this.$emit("dragEnd", newData);
    },
    _handleDragEnd() {
      this._boardCast("drag:end");
      if (!this.list.length) return;
      const realDomOrder = [...this.$el.children].filter(i => i.classList.contains("js-mDragItem"));
      this._getDataOrder(realDomOrder, this.children);
      // 更新children列表；
      this.children = this.realDomOrder();
    },
  },
};
</script>
