// 这里进行一些预设值相关修改
import ElementUI from 'element-ui'

// 通常情况下 输入框默认可清空
ElementUI.Input.props.clearable.default = true

// 通常清空下, 选择框默认可输入检索
ElementUI.Select.props.filterable.default = true

// 为Table组件默认增加统一的row className以处理换行问题
// ElementUI.Select.props.rowClassName = {
//   type: [String, Function],
//   default() {
//     return 'ui-showellipsis'
//   }
// }

export const DEFAULT_SIZE = 'small'
