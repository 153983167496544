<template>
  <el-form ref="formBox" :model="formModel" :size="myFormSize" class="Mform" v-bind="extendAttribe">
    <el-row v-for="(group, num) in myFormData" :key="num" :gutter="24" type="flex" class="autoWrap">
      <el-col v-if="isGroup && group.title && (group.hasOwnProperty('condition') ? group.condition(formModel) : true)" :span="24">
        <slot name="groupTitle" :title="group.title">
          <div class="group-box">
            <div class="ui-myForm-group__title b">{{ group.title }}</div>
            <div v-if="!!group.describe" class="dib vt ml10">
              <el-tooltip class="item" effect="dark" :content="group.describe" placement="top">
                <el-button type="text"><i class="el-icon-question" /></el-button>
              </el-tooltip>
            </div>
          </div>
        </slot>
      </el-col>
      <template v-for="(item, index) in group.children" v-if="group.hasOwnProperty('condition') ? group.condition(formModel) : true">
        <el-col v-if="item.hasOwnProperty('condition') ? item.condition(formModel) : true" :key="item.name" :span="item.span">
          <el-form-item
            v-show="!collapse.status ? true : showMore || index <= collapse.num - 1"
            :prop="item.name"
            :rules="item.rules"
            :class="item.class"
            :label-width="item.labelWidth || extendAttribe.labelWidth"
          >
            <template v-if="item.label" #label>
              <!-- 显示提示信息 -->
              <el-tooltip v-if="item.describe" class="item" effect="dark" :content="item.describe" placement="top">
                <el-button type="text"><i class="el-icon-question" /></el-button>
              </el-tooltip>
              <span class="ell" :title="item.label">{{ item.label }}</span>
            </template>
            <template v-if="!!item.component">
              <component
                :is="item.component"
                v-model="formModel[item.name]"
                autocomplete="off"
                v-bind="item"
                :disabled="typeof item.disabled === 'function' ? item.disabled(formModel) : item.disabled"
                v-on="item"
                @blur="event => _formBlur(event, item)"
                @change="val => _formChange(val, item)"
                @keyup.native="val => _handleKeyUp(val, item)"
              >
                <!-- 针对select组件 -->
                <template v-if="item.component === 'elSelect'" #default>
                  <el-option v-for="(children, num) in itemData(item)" :key="num" v-bind="children" :label="children.label" :value="children.value" />
                </template>
                <!-- 针对checkboxgroup组件 -->
                <template v-else-if="item.component === 'elCheckboxGroup'" #default>
                  <el-checkbox v-for="(children, num) in itemData(item)" v-bind="getAttrs(children)" :key="num" :label="children.value">
                    {{ children.label }}
                  </el-checkbox>
                </template>
                <!-- 针对radiogroup组件 -->
                <template v-else-if="item.component === 'elRadioGroup'" #default>
                  <el-radio v-for="(children, num) in itemData(item)" v-bind="children" :key="num" :label="children.value">
                    {{ children.label }}
                  </el-radio>
                </template>
                <!-- 针对cascade组件 -->
                <!-- <template v-else-if="item.component === 'elCascader'" v-bind="item" :key="JSON.stringify(item.options)" #default>
                </template> -->
                <!-- 支持所有element的Form表单只能支持的slot而不支持slot-scope -->
                <template v-for="slotKey in Object.keys(item.slotName || {})" #[item.slotName[slotKey]]>
                  <slot v-if="slotKey" :name="slotKey" :item="item" :formModel="formModel | getFormModel" />
                </template>
              </component>
            </template>
            <template v-else-if="item.slotName">
              <slot :name="item.slotName" :formModel="formModel" :item="item"/>
            </template>
            <template v-else>
              <slot name="appendComponent" :item="item">
                <span>{{ formModel[item.name] }}</span>
              </slot>
            </template>
            <!-- 扩展添加 -->
            <slot v-if="item.appendSlotName" :name="item.appendSlotName" :formModel="formModel" :item="item"/>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
    <el-row v-if="config.isNeedBtn && !isGroup" :gutter="24" type="flex" :justify="config.justify">
      <el-col :span="6" class="tr">
        <el-form-item class="nowrap" :class="{ mytop: isToTop }" :label-width="config.labelWidth">
          <el-button class="w70" @click="resetForm">{{ submitButton.cancel }}</el-button>
          <el-button type="primary" class="ui-sureBtn pl10 pr10" :loading="submitLoading" @click="submitForm">
            {{ submitButton.ok }}
          </el-button>
          <template v-if="collapse.status">
            <el-button v-show="showMore" type="text" @click="showMore = false">
              {{ $t("libsSz.key9") }}
              <i class="el-icon-arrow-up ml5 mr10" />
            </el-button>
            <el-button v-show="!showMore" type="text" @click="showMore = true">
              {{ $t("libsSz.key8") }}
              <i class="el-icon-arrow-down ml5 mr10" />
            </el-button>
          </template>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
/**
 *  通用的form表单
 *  正常数据描述
 *  传入数据
 *  [
 *   {
 *       name:'',                 // 表单当前的form里面的唯一标识
 *       label:'',                // 当前label
 *       value: '',               // 默认初始值
 *       component:'',            // element-ui组件；
 *       span:6,                  // 24格栅栏布局
 *       slotName: { [调用方传入slotName]: [对应element-ui的slotName] },
 *       data:[                   // el-select/elCheckboxGroup/elRadioGroup
 *          label:'',
 *          value:'',
 *          ...
 *       ] 或者是返回值为上面格式数组的 function,
 *       condition(formModel){   // 来控制是否展示
 *         return true
 *       },
 *       mchange(val, formItem, cb){     // 提供cb修改formModel的值；
 *
 *       }
 *       ...           // 任何element支持的属性都可以代理
 *    }
 *  ]
 *
 *  分组数据描述
 *  [
 *    {
 *       title: 标题，  //  不传title就不显示，支持一个groupTitle scopedSlot~
 *       children:[
 *           ...正常表单描述
 *       ],
 *       condition(formModel){   // 来控制是否展示
 *         return true
 *       },
 *    }
 *  ]
 * collapse:{
 *   status: true, 表示启用
 *   num: 2, // 缩进后的第一行展示几个
 * }
 *  button数据类型
 *  @prop { labelWidth: number } labelWidth的固定宽度
 *  @prop { labelPosition: String } 当前form表单的对齐模式
 *  @prop { formData: Array } 传入的描述form表单的数据格式
 *  @prop { button: Object } 传入按钮文字
 *  @prop { extendConfig: Object } 传入的描述form表单的数据格式
 *          *  @prop { isGroup: Boolean } 是否需要分组，
 *  @prop { collapse: Object } 描述是否需要收起功能
 *
 *  @event {submit} 提交回调函数
 *  @event {reset} 回调函数
 *
 *  @method changeFormValue 用来动态修改form表单值
 *  @method getFormCellValue 获取form表单cell
 *  @method getValidateFormModel 获取form，符合定义的rules的formData;
 *  @method clearValidate 清楚表单验证
 *
 *  版本:
 *   1. 20190731 增加formItem的condition判断是否显示
 *   2. 20190821 mchange增加一个当前formModel传参；
 *   3. 20190823 form增加全局参数size
 *   4. 20190823 增加keyup事件支持
 *   5. 20191011 支持Mpage组件嵌套；
 *   6. 20200727 支持Group分组，
 *   7. 20201014 label旁添加tooltip，传参为toolTip；
 *
 *  备注：
 *    1. 背景色由外层描述
 *    2. 存在A的select改变会改变B的select,这种情况配置mchange，代码会代理处理，调用回调函数，即可修改对应的值；
 *    3. 增加一种类型MSelectTransfer, 是一种select+transfer结合，主要为了支持多选。
 *    4. 不支持传入单个checkbox,或者radio, 单个推荐switch按钮；
 *    5. elSelect/elCheckboxGroup/elRadioGroup 支持通过slotName覆盖原始的default slot;
 *    6. 如果是分组模式，不支持用自带按钮，只能通过methods方式，获取值，或者 rest
 */

import Vue from "vue";
import emitter from "element-ui/src/mixins/emitter";

// 增加一种新的select类型；
import MSelectTransfer from "../MSelectTransfer/MSelectTransfer";

Vue.component("MSelectTransfer", MSelectTransfer);

const defaultConfig = {
  isNeedBtn: true, // 是否需要button
  isGroup: false,
  justify: 'end'
};

export default {
  name: "MForm",
  props: {
    inline: Boolean,
    labelPosition: {
      type: String,
      default: "top",
    },
    labelWidth: {
      type: Number,
      default: 100,
    },
    formSize: String,
    formData: {
      type: [Array, Function],
      required: true,
      default() {
        return [];
      },
    },
    button: Object,
    extendConfig: {
      type: Object,
      default() {
        return defaultConfig;
      },
    },
    collapse: {
      type: Object,
      default() {
        return {
          status: false,
          num: 0,
        };
      },
    },
  },
  componentName: "MForm",
  mixins: [emitter],
  inject: {
    rootPage: {
      default: null,
    },
  },
  data() {
    return {
      formModel: {}, // 当前form数据
      showMore: false, // 查看更多
      submitLoading: false, // 查询增加loading锁防止点击多次
    };
  },
  created() {
    this._initFormValue();
  },
  filters: {
    getFormModel(val) {
      return JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
     config() {
        return { ...defaultConfig, ...this.extendConfig }
     }, // 当前form数据
    isGroup() {
      return this.extendConfig.isGroup;
    },
    isToTop() {
      let showTop = false;
      let spanCount = 0;
      (this.formData || []).forEach(item => {
        spanCount += Number(item.span || 0);
      });
      if (spanCount % 24 < 22) showTop = true;
      if (spanCount % 24 === 0) {
        if (!this.collapse.status) {
          showTop = false;
        } else {
          showTop = !this.showMore;
        }
      }
      return showTop;
    },
    myFormSize() {
      return this.formSize || this.$ELEMENT.size || "small";
    },
    submitButton() {
      return Object.assign(
        {},
        {
          ok: this.$t("libsSz.key10"),
          cancel: this.$t("libsSz.key11"),
        },
        this.button,
      );
    },
    extendAttribe() {
      const extendDatas =
        this.inline || this.$attrs.inline
          ? { inline: true }
          : {
              labelPosition: this.labelPosition,
              labelWidth: `${this.labelWidth}px`,
            };
      return {
        ...this.$attrs,
        ...extendDatas,
      };
    },
    myFormData() {
      if (!this.isGroup) {
        return [{ children: this.formData }];
      }
      return this.formData;
    },
  },
  methods: {
    // 赋值初始值
    _initFormValue() {
      const value = {};
      if (Array.isArray(this.formData)) {
        if (this.isGroup) {
          this.formData.map(item => {
            if (item.children) {
              item.children.map(i => (value[i.name] = i.value));
            }
          });
        } else {
          this.formData.map(item => (value[item.name] = item.value));
        }
        this.formModel = value;
      }
    },
    _filterFormModel(isFilter = true) {
      let newFormModel = {};
      Object.keys(this.formModel).forEach(item => {
        const val = this.formModel[item];
        if (String(val) && String(val) !== "null" && String(val) !== "undefined") {
          newFormModel[item] = val;
        }
      });
      return newFormModel;
    },
    // 获取 item.data
    itemData(item) {
      return typeof item.data === "function" ? item.data(this.formModel) : item.data || [];
    },

    getAttrs(item) {
      const { value, checked, ...checkOpt } = item
      return { ...checkOpt }
    },

    // 获取当前参数
    getFormModel(isFilter) {
      return isFilter ? this._filterFormModel() : this.formModel;
    },

    // 获取合法参数
    async getValidateFormModel(isFilter) {
      try {
        const valid = await this.$refs["formBox"].validate();
        return valid ? Promise.resolve(isFilter ? this._filterFormModel() : this.formModel) : Promise.reject();
      } catch (e) {
        return Promise.reject();
      }
    },
    // 用来支持动态改formItemValue
    // 如果rules不传则用新传的值替换新的值；
    changeFormValue(params, rules) {
      if (typeof params !== "object") {
        return;
      }
      if (rules && typeof rules === "function") {
        Object.keys(params).map(key => {
          if (this.formModel[String(key)]) {
            const newVal = rules(key, params[String(key)], this.formModel[String(key)]);
            newVal && (this.formModel[String(key)] = newVal);
          }
        });
      } else {
        this.formModel = Object.assign({}, this.formModel, params);
      }
    },
    // 清楚当前验证
    clearValidate(props) {
      this.$refs["formBox"][props ? 'clearValidate' : 'resetFields'](props);
    },
    getFormCellValue(params) {
      if (!params) return;
      return this.formModel[params];
    },
    // 重置表单
    resetForm() {
      this.clearValidate();
      this.$emit("reset", {});
      this.dispatch("MPage", "mpage:reset", {});
    },
    /**
     *  代理change方法
     */
    _formChange(val, formItem) {
      const { change, mchange } = formItem;
      if (change && !mchange) return;
      mchange && mchange(val, formItem, this.changeFormValue, this.formModel);
    },

    /** 代理blur事件 */
    _formBlur(event, formItem) {
      const { name, component } = formItem;
      component && ['elInput', 'el-input'].includes(String(component))&& (this.formModel[name] = event.target.value.trim());
      if ("blur" in formItem || "onBlur" in formItem) {
        formItem.blur && formItem.blur(event);
        formItem.onBlur && formItem.onBlur(event);
      }
    },

    /**
     *  代理change方法
     */
    _handleKeyUp(keyEvent, formItem) {
      const { keyup, name, type } = formItem;
      // textarea 需要处理分行功能，tirm会过滤分行
      if (keyEvent.keyCode === 13 && type === "textarea") {
        this.formModel[name] = this.formModel[name] + "\n";
      }
      keyup && keyup(keyEvent, formItem, this.changeFormValue, this.formModel);
    },
    // 提交表单
    submitForm() {
      this.submitLoading = true;
      this.$refs["formBox"].validate(valid => {
        if (valid) {
          this.$emit("submit", this._filterFormModel());
          this.dispatch("MPage", "mpage:submit", this._filterFormModel());
        }
        setTimeout(() => {
          this.submitLoading = false;
        }, 500);
      });
    },
  },
};
</script>
<style lang="scss">
.el-form--label-top .el-form-item__label {
  padding: 0;
  line-height: 35px;
}

.Mform .el-select,
.Mform .el-cascader {
  width: 100%;
}

.Mform .el-date-editor.el-input,
.Mform .el-date-editor.el-input__inner {
  width: 100%;
}

.mytop {
  margin-top: -52px;
}

.Mform .el-form-item label {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Mform .el-form-item label.el-radio {
  width: auto;
}

.Mform {
  &.el-form--inline {
    label {
      width: auto !important;
    }
  }
}

.ui-sureBtn {
  min-width: 70px;
}

.ui-myForm-group__title {
  &::before {
    content: "";
    display: inline-block;
    height: 21px;
    width: 4px;
    border-radius: 4px;
    background: #409eff;
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
.autoWrap {
  flex-wrap: wrap;
}
</style>
