import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'
/**
 * 监控管理：单据监控
 */
const apis = {
  upDownMaterialMonitorList: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/upDownMaterialMonitorList`,
    describe: '上下料单单据监控列表',
    isNeedLoading: false
  },
  tallyMonitorList: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/tallyMonitorList`,
    describe: '理货单单据监控列表',
    isNeedLoading: false
  },
  artificialMonitorList: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/artificialMonitorList`,
    describe: '人工出入库单据监控列表',
    isNeedLoading: false
  },
  listReceiptMonitorByPage: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/listReceiptMonitorByPage`,
    describe: '单据监控列表',
    isNeedLoading: false
  },
  listReceiptMonitorByNoOrType: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/listReceiptMonitorByNoOrType`,
    describe: '单据号或单据类型分页查询单据监控',
    isNeedLoading: false
  },
  getReceiptMonitorDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/getReceiptMonitorDetail`,
    describe: '查询单据监控详情',
    isNeedLoading: false
  },
  listMaterialDetail: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/listMaterialDetail`,
    describe: '查询物料详情',
    isNeedLoading: false
  },
  getGoodsPositionInfoById: {
    method: 'post',
    url: `${glesRoot}/base/goods/position/getGoodsPositionInfoById`,
    describe: '查询工位信息',
    isNeedLoading: false
  },
  getTaskRobotInfo: {
    method: 'post',
    url: `${glesRoot}/workflow/monitorTask/getTaskRobotInfo`,
    describe: '查询任务机器人信息',
    isNeedLoading: false
  },
  postRetry: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/retry`,
    describe: '重试',
    isNeedLoading: false
  },
  postFinish: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/finish`,
    describe: '人工完成',
    isNeedLoading: false
  },
  postSkip: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/skip`,
    describe: '自动跳过',
    isNeedLoading: false
  },
  postCancel: {
    method: 'post',
    url: `${glesRoot}/workflow/receiptMonitor/cancel`,
    describe: '取消',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
