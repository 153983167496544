export default {
  namespaced: true,
  state: {
    // tab页枚举
    orderMonitorLabelList: [],
    // 上下料单 单据状态
    materialsBillStatusList: [],
    // 上下料单 任务状态
    upDownMaterialBillSubTaskStatusList: [],
    // 上下料单 任务类型
    upDownMaterailBillSubTaskTypeList: [],
    // 执行模式
    executeModeList: [],
    robotReceiptTypeList: [],
    tallyReceiptTypeList: [],
    manualReceiptTypeList: []
  },
  mutations: {
    changeState(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map((item) => {
        state[item] = payLoad[item]
        return true
      })
    }
  },
  actions: {
    /**
     * tab 枚举
     * @param {*} params
     */
    async getOrderMonitorLabelList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'orderMonitorLabel' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          orderMonitorLabelList: list
        })
      })
    },
    /**
     * 上下料单 单据状态 枚举
     * @param {*} params
     */
    async getMaterialsBillStatusList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'materialsBillStatus' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          materialsBillStatusList: list
        })
      })
    },
    /**
     * 人工上下料 单据状态 枚举
     * @param {*} params
     */
    async getManualBillStatusList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'artificialBillStatus' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          manualBillStatusList: list
        })
      })
    },
    /**
     * 上下料单 任务状态 枚举
     * @param {*} params
     */
    async getUpDownMaterialBillSubTaskStatusList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'UpDownMaterialBillSubTaskStatus' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          UpDownMaterialBillSubTaskStatusList: list
        })
      })
    },
    /**
     * 人工 任务状态 枚举
     * @param {*} params
     */
    async getManualSubTaskStatusList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'artificialSubTaskStatus' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          manualSubTaskStatusList: list
        })
      })
    },
    /**
     * 上下料单 任务类型 枚举
     * @param {*} params
     */
    async getUpDownMaterailBillSubTaskTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'upDownMaterailBillSubTaskType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          upDownMaterailBillSubTaskTypeList: list
        })
      })
    },
    /**
     * 人工出入库 任务类型 枚举
     * @param {*} params
     */
    async getManualSubTaskType({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'artificialSubTaskType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          manualSubTaskTypeList: list
        })
      })
    },
    /**
     * 执行模式 枚举
     * @param {*} params
     */
    async getExecuteModeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'executeMode' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          executeModeList: list
        })
      })
    },
    /**
     * 机器人上下料单 单据类型 枚举
     * @param {*} params
     */
    async getRobotReceiptTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'upDownMaterialReceiptType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          robotReceiptTypeList: list
        })
      })
    },
    /**
     * 理货 单据类型 枚举
     * @param {*} params
     */
    async getTallyReceiptTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'tallyReceiptType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          tallyReceiptTypeList: list
        })
      })
    },
    /**
     * 人工上下料单 单据类型 枚举
     * @param {*} params
     */
    async getManualReceiptTypeList({ dispatch, commit, state }, params) {
      await dispatch(
        'getSelectOptions',
        {
          apiName: 'queryDictByCode',
          params: { dictCode: 'artificialReceiptType' }
        },
        { root: true }
      ).then((list) => {
        commit('changeState', {
          manualReceiptTypeList: list
        })
      })
    }
  }
}
