<template>
  <el-drawer
    :close-on-click-modal="false"
    v-bind="$attrs"
    :size="$attrs.size || '30%'"
    :visible="visible"
    center
    append-to-body
    @close="close"
  >
    <template #title>
      <!-- 标题 -->
      <slot name="title" />
    </template>
    <div class="dialog-content">
      <div class="content">
        <slot />
      </div>
      <span v-if="isNeedFooter" class="dialog-footer">
        <!-- 取消 -->
        <el-button
          class="w140"
          size="small"
          :loading="saveLoading"
          type="primary"
          @click="save"
        >{{ button.save }}</el-button>
        <el-button class="w100" size="small" @click="close">{{ button.cancel }}</el-button>
      </span>
    </div>
  </el-drawer>
</template>
<script>
/**
 *
 *  在原有功能上增加功能
 *  1. 按钮标题必须居中
 *  2. 增加取消 & 确定按钮， 确定按钮支持loading
 *
 *  @prop { button } 传入button文案
 *  @prop { visible } 控制打开
 *  @prop { isNeedFooter } 是否需要footer按钮
 *  @prop { beforeClose } 关闭前的回调， 必须要调用回调，否则不能关闭
 *  @prop { beforeSave } 如果调用beforeSave会先增加个按钮loading效果， 保存前的钩子/必须要返回一个
 *  支持 Promise 如果 resolve/reject
 *
 *  @event { closed } 传入closed；
 *  @event { save } 保存触发方法；
 *
 *  更新日志：
 *  1. 20200602 更新Modal层背景层不可点击关闭
 *
 */

import { isPromise } from "../../utils/help";

export default {
  name: "MDrawer",
  props:{
    visible:{
      type: Boolean,
      required:true,
    },
    isNeedFooter:{
      type:Boolean,
      default(){
        return true;
      },
    },
    button:{
      type:Object,
      default(){
        return {
          cancel: this.$t("libsSz.key14"),
          save: this.$t("libsSz.key39"),
        }
      },
    },
    beforeSave:{
      type:Function,
      validator(value) {
        return value && isPromise(value) || true;
      },
    },
    beforeClose: Function,
  },
  data(){
    return {
      saveLoading:false,
    }
  },
  methods:{
    // 关闭方法
    close(type="cancel"){
      const closeFn = () => {
        this.$emit("update:visible", false);
        this.$emit("closed", type);
      }
      if(this.beforeClose){
        this.beforeClose(closeFn);
      }else{
        closeFn();
      }
    },
    // 保存方法
    save(){
      this.saveLoading = true;
      const success = () => {
        this.saveLoading = false;
        this.$emit("save");
        this.close("success");
      }
      const fail = () =>{
        this.saveLoading=false;
      }
      this.beforeSave ?
        this.beforeSave().then(success).catch(fail) : success();

    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;
    padding: 0 20px;
  }

  .dialog-footer {
    text-align: center;
    height: 60px;
    padding-top: 15px;
    background: #FFF;
  }
}
</style>
