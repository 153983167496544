<template>
  <div class="mtp-box">
    <transition name="slide-fade" mode="out-in">
      <keep-alive :include="cache|| /others/">
        <component :is="currentView" v-bind="params" @changeView="changeView" />
      </keep-alive>
    </transition>
  </div>
</template>
<script>
/**
 *  适用场景：同一个页面切换两种组件， 适合弹框内容过多场景， 只适合整块切换展示，不支持数据传送
 *  @params { cache } 用于表示需要cache的模块
 *  @params { myComponents } 用于表示切换的模块
 *  @params { myComponents } 用于表示切换的模块
 *
 *  @event { changeView } 被调用的子模块需要出发这个方法，进行切换,
 *  并且可以结合activated，达到刷新目的
 *
 *  注意点： myComponents 默认首字母大写；
 *
 *  //!!!! 不推荐使用， 建议使用 MTransition!!!!!!!!!!!!!
 */
import Vue from "vue";
export default {
  name:"MTransformPage",
  props: {
    cache: String,
    myComponents: {
      type: Object,
      required: true,
      validator(value) {
        return JSON.stringify(value) !== "{}";
      },
    },
  },
  data() {
    return {
      currentView: "",
      params: {},
    };
  },
  provide(){
    return {
      pparams: () => this.params, // 提供给所有子包一个全局变量
    }
  },
  created() {
    const modules = Object.keys(this.myComponents);
    this.currentView = modules[0];
    // 注册全局的组件；
    modules.map(key => {
      Vue.component(key, this.myComponents[key]);
    });
  },
  methods: {
    changeView(changeName, ...params) {
      this.params = params || {};
      this.currentView = changeName;
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
