import { getSelectOptions, getMaterialOptions, getMaterialCodeOptions } from '@/utils/getDictPramas'
import { t } from '@/libs_sz/locale'
export default {
  namespaced: true,
  state: {
    batchPropertyLabelList: [],
    basicUnitList: [],
    measuringUnitList: [],
    materialClassifyFirstList: [],
    materialClassifySecondList: [],
    expirationUnitList: [],
    expirationTypeList: [],
    materialTowardList: [],
    // start ----- 工位物料配置页面
    materialPlaceList: [], // 工位位置
    // 工位类型
    materialTypsList: [
      {
        label: t('lang.gles.workflow.deviceGoodsPosition'),
        value: 1
      },
      {
        label: t('lang.gles.workflow.produceFactoryPosition'),
        value: 2
      }
    ],
    materialCodeList: [] //  物料编码
    // end -----
  },
  getters: {},
  actions: {
    /**
     * 获取批属性标签
     */
    async getBatchPropertyLabelList({ commit }, dictCode = 'batchPropertyLabel') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setOptions', { batchPropertyLabelList: list })
      )
    },
    /**
     * 获取物料基本单位
     */
    async getBasicUnitList({ commit }, dictCode = 'materialBasicUnit') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setOptions', { basicUnitList: list })
      )
    },
    /**
     * 获取物料计量单位
     */
    async getMeasuringUnitList({ commit }, dictCode = 'materialMeasuringUnit') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setOptions', { measuringUnitList: list })
      )
    },
    /**
     * 获取一级分类
     */
    async getClassifyFirst({ commit }) {
      getMaterialOptions('queryClasifyByCodeOrName', { level: 1 }).then((list) => {
        commit('setOptions', { materialClassifyFirstList: list })
      }
      )
    },
    /**
     * 获取二级分类
     */
    async getClassifySecond({ commit }, parentId) {
      const params = {
        level: 2
      }
      parentId ? params.parentId = parentId : ''
      getMaterialOptions('queryClasifyByCodeOrName', params).then((list) =>
        commit('setOptions', { materialClassifySecondList: list })
      )
    },
    /**
     * 获取效期类型
     */
    async getExpirationType({ commit }, dictCode = 'materialExpirationType') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setOptions', { expirationTypeList: list })
      )
    },
    /**
     * 获取效期单位
     */
    async getExpirationUnit({ commit }, dictCode = 'materialExpirationUnit') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setOptions', { expirationUnitList: list })
      )
    },
    /**
     * 获取物料朝向
     */
    async getMaterialToward({ commit }, dictCode = 'materialDirection') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setOptions', { materialTowardList: list })
      )
    },
    /**
     * 获取工位位置
     */
    // async getMaterialPlaceList({ commit }) {
    //   getMaterialCodeOptions('getMaterialConfigPlace', { type: 1 }).then((list) => {
    //     commit('setOptions', { materialPlaceList: list })
    //   })
    // },
    /**
     * 获取物料编码
     */
    async getMaterialmaterialCodeList({ commit }, dictCode = 'materialDirection') {
      getMaterialCodeOptions('getMaterialConfigCodeOptions', { dictCode }).then((list) => {
        commit('setOptions', { materialCodeList: list })
      })
    }
  },
  mutations: {
    setOptions(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map(item => {
        state[item] = payLoad[item]
        return true
      })
    }
  }
}

