<template>
  <el-select
    ref="mySelect"
    :value="selectValue"
    :data="false"
    multiple
    :size="size"
    collapse-tags
    @remove-tag="removeTag"
  >
    <template #empty>
      <div>
        <div
          v-if="transferData.length"
          class="ui-transferBox p20"
        >
          <el-transfer
            ref="myElTransfer"
            v-model="transferValue"
            filterable
            :data="transferData"
            :size="size"
            :titles="[$t('libsSz.key47'), $t('libsSz.key48')]"
          >
            <template #default="{ option }">
              <span>{{ option.label }}</span>
            </template>
          </el-transfer>
          <div class="tr">
            <el-button
              type="primary"
              size="mini"
              class="mt10"
              @click="addValue"
            >
              {{ $t("libsSz.key15") }}
            </el-button>
          </div>
        </div>
        <div v-else class="pt10 pb10 tc">
          <span class="g9 f14">{{ noDataText || $t("libsSz.key45") }}</span>
        </div>
      </div>
    </template>
  </el-select>
</template>
<script>
/**
 *   组合下拉框 + 穿梭框
 *
 *   data 必须处理成 [ {key:"", label:"", disabled:""} ]
 */
export default {
  name: "MSelectTransfer",
  props: {
    data: Array,
    value: Array,
    noDataText: String,
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      transferData: this.data || [],
      transferValue: this.value || [],
    };
  },
  watch: {
    data(newVal) {
      this.transferData = newVal;
    },
    value(newVal) {
      this.transferValue = newVal;
    },
  },
  computed: {
    selectValue() {
      const newVal = this.value || [];
      return newVal
        .map(c => {
          const item = this.transferData.find(a => a.key === c);
          return item ? item.label : item;
        })
        .filter(
          item =>
            String(item) &&
            String(item).toLowerCase() !== "null" &&
            String(item).toLowerCase() !== "undefined",
        );
    },
  },
  methods: {
    removeTag(value) {
      const resetValue = this.transferValue.filter(c => String(c) !== String(value));
      this.$emit("input", resetValue);
      this.$emit("change", resetValue);
    },
    addValue() {
      this.$emit("input", this.transferValue);
      this.$emit("change", this.transferValue);
      this.$refs.mySelect.blur();
    },
  },
};
</script>
<style lang="scss">
.ui-transferBox .el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
  font-size: 14px;
  color: #666;
}
</style>
