import ElementLocale from 'element-ui/lib/locale'
import ElementUI from 'element-ui'
import * as LibsSzLocale from '@/libs_sz/locale'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n()

ElementLocale.i18n((key, value) => i18n.t(key, value))
LibsSzLocale.i18n((key, value) => i18n.t(key, value))
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
export default i18n
