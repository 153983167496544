import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import * as actions from '@/store/actions'
import * as getters from '@/store/getters'

// 新增
import tagsView from './modules/tagsView'
import base from './modules/base'
import materialManage from './modules/materialManage'
import strategyManage from './modules/strategyManage'
import workflow from './modules/workflow'
import interfaces from './modules/interface'
import moreData from './modules/moreData'
import monitor from './modules/monitor'
import order from './modules/order'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    // 新增
    tagsView,
    base,
    materialManage,
    strategyManage,
    workflow,
    interfaces,
    moreData,
    monitor,
    order
  },
  actions,
  getters,
  state,
  mutations
})
export default store
