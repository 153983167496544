import * as types from '@/store/mutationTypes'
import { recursion } from '@/utils/commonTranform'
import myTransform from '@/libs_sz/utils/transform'
import apiStore from '@/apis/index'
import myConfig from '@/utils/config'
import { linkAuthMange } from '@/utils/getSessionId'
import axios from 'axios'
import apis from '@/apis/index'
import router from '@/router'

/** 获取场地 */
export const getStore = ({ commit }, data) => {
  commit(types.GET_STORE, data)
}

/** 设置全屏 */
export const setIsFullScreenTitle = ({ commit }, fullScreen) => {
  commit(types.SET_ISFULLSCREEN, fullScreen)
}

/** 获取 管理台配置 列表 */
export const setAdminConfig = ({ commit }, adminConfig) => {
  commit(types.GET_ADMINCONFIG, adminConfig)
}

export const toLogin = async({ commit, state }, payLoad) => {
  const language = localStorage.getItem('language') || window.navigator.language
  const defaultParams = {
    subsystemCode: state.systemCode,
    curLanguage: language,
    module: 'G-LES-PC'
  }
  const { code, data } = await axios.post(apis.coreresource.glesLogin.url, {
    ...defaultParams,
    ...payLoad
  })
  if (code) return Promise.reject()
  window.localStorage.setItem(`${state.systemCode}_cred`, data.sessionId)
  // 保存当前MenuList
  const newMenu = recursion({
    data: data.user.menuList,
    formatter(row) {
      return {
        title: row.name,
        icon: row.icon,
        path: row.url
      }
    }
  })

  // home不纳入权限管理
  const homeMenu = {
    id: 'home',
    pid: '-1',
    title: 'lang.gles.common.homepage',
    icon: 'icon-shouye',
    path: '/home'
  }

  const menuData = [homeMenu, ...newMenu]
  menuData.forEach(item => {
    if (item.path && !item.path.startsWith('/')) {
      item.path = `/${item.path}`
    }
  })
  commit(types.UPDATE_MENU, { menuData: menuData })
  commit(types.UPDATE_USERNAME, { userName: data.user.userName })
  commit(types.UPDATE_CRED, payLoad)
  return Promise.resolve(data)
}

export const noAuthToLogin = async({ commit, state }, payLoad) => {
  const { routes } = router.options
  const menuData = routes[0].children.map((item, index) => {
    return {
      id: index,
      pid: '-1',
      title: item.meta.title,
      path: item.path
    }
  })

  // home不纳入权限管理
  const homeMenu = {
    id: 'home',
    pid: '-1',
    title: '无权限菜单',
    icon: 'icon-shouye',
    path: '/home'
  }

  commit(types.UPDATE_MENU, { menuData: [homeMenu, ...menuData] })
  commit(types.UPDATE_USERNAME, { userName: '未登录' })
  return Promise.resolve()
}

const logoutArk = () => {
  return new Promise((resolve, reject) => {
    const vm = window.vm
    const lang = vm.$getLang()
    const xhr = new XMLHttpRequest()
    xhr.open('GET', `${myConfig.glesRoot}/api/coreresource/auth/logout/v1`, true)
    xhr.setRequestHeader('Accept-Language', lang)
    xhr.send()
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const res = JSON.parse(xhr.responseText)
          if (!res.code) {
            resolve()
          } else {
            reject()
            vm.$message(vm.$t(res.msg))
          }
        } else {
          reject()
        }
      }
    }
  })
}

// eslint-disable-next-line no-empty-pattern
export const toLogout = async({}, payload) => {
  await logoutArk()
  // 登出接口
  const headers = {
    'Accept-Language': window.vm.$getLang()
  }
  const { code } = await axios.get(`${myConfig.authUrl}/api/coreresource/auth/logout/v1`, {
    headers
  })
  if (code) return
  // 触发一下ark退出接口；
  const { isReturnUrl } = payload || { isReturnUrl: true }
  linkAuthMange(isReturnUrl)
}

export const getCommonDict = async({ commit }, payload = {}) => {
  const { objectCodes } = payload
  if (!objectCodes || !objectCodes.length) return Promise.reject()
  const { data, code } = await axios.post(apiStore.common.queryDetails.url, {
    enable: true,
    ...payload
  })
  if (code) return Promise.reject()
  objectCodes.forEach((item) => {
    const dict = data[item]
    let options = []
    try {
      options = myTransform.arrToOptions(dict.details, 'fieldCode', 'fieldValue')
    } catch (e) {
      console.log(`${item}缺少当前字典`)
    }
    commit('common', {
      [`${item}_dict`]: options.map((c) => ({
        label: window.vm.$t(c.label),
        value: c.value
      }))
    })
  })
  return Promise.resolve()
}
/**
 * 获取字典表
 * @param {*} apiName 接口名
 * @param {*} params 请求参数
 * @returns 选项列表
 */
export const getSelectOptions = ({ commit }, { apiName, params }) => {
  const vm = window.vm
  return new Promise((resolve, reject) => {
    vm.$httpService(vm.$apiStore.base(apiName), params)
      .then(({ data, code }) => {
        if (code !== 0) {
          reject()
          return
        }
        let { baseDictDetails: recordList = [] } = data
        if (Array.isArray(data)) {
          recordList = data
        }
        const list = recordList.map((item) => {
          if (item.containerTypeCode) {
            return {
              label: vm.$t(item.containerTypeName),
              value: item.id,
              containerTypeCode: item.containerTypeCode,
              containerTypePattern: item.containerTypePattern,
              containerTypePatternType: item.containerTypePatternType
            }
          }
          if (item.containerArchivesCode) {
            return {
              label: item.containerArchivesCode,
              value: item.id
            }
          }
          if (item.factoryPositionCode) {
            return {
              label: item.factoryPositionName,
              value: item.id,
              factoryPositionType: item.factoryPositionType
            }
          }
          if (item.templateCode) {
            return {
              label: vm.$t(item.templateName),
              value: item.id
            }
          }
          if (apiName === 'getGoodsPositionListByType') {
            return {
              label: item.code,
              value: item.id
            }
          }
          if (apiName === 'queryDictByCode') {
            if (item.dictCode === 'goodsPositionType') {
              return {
                label: vm.$t(item.detailName),
                value: item.detailCode
              }
            }
            // detailCodeType 字典code类型  0 string 1 int
            return {
              label: vm.$t(item.detailName),
              value: item.detailCodeType ? parseInt(item.detailCode) : item.detailCode
            }
          }
          return {
            id: item.id,
            label: vm.$t(item.name),
            value: item.id
          }
        })
        resolve(list)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const setEditingStatus = ({ commit }, status) => {
  commit(types.UPDATE_EDITING_STATUS, status)
}
