import config from '@/utils/config'
const { glesRoot = '/gles' } = config
import _ from 'lodash'
/**
 * 接口管理
 */
const apis = {
  getInterfaceConfigList: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceConfig/list`,
    describe: '获取接口配置列表',
    isNeedLoading: false
  },
  deleteInterfaceConfig: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceConfig/delInterfaceConfig`,
    describe: '删除接口配置',
    isNeedLoading: false
  },
  addInterfaceConfig: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceConfig/addInterfaceConfig`,
    describe: '添加接口配置',
    isNeedLoading: false
  },
  changeInterfaceStatus: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceConfig/changeStatus`,
    describe: '接口启用/停用',
    isNeedLoading: false
  },
  getInterfaceLogList: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceLog/list`,
    describe: '接口日志列表',
    isNeedLoading: false
  },
  getInterfaceLogDetail: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceLog/getMessage`,
    describe: '接口日志详情',
    isNeedLoading: false
  },
  getSelectOperation: {
    method: 'post',
    url: `${glesRoot}/interfaceManager/interfaceLog/getSelectOperation`,
    describe: '下拉列表',
    isNeedLoading: false
  }
}

export default function(apiName, params, extendedUrl) {
  const api = _.cloneDeep(apis[apiName])
  if (!api) return {}

  if (extendedUrl !== undefined && extendedUrl !== null) {
    api.url = `${api.url}/${extendedUrl}`
  }

  let queryStr = '?'
  params && Object.keys(params).forEach((key) => {
    queryStr += queryStr === '?' ? `${key}=${params[key]}` : `&${key}=${params[key]}`
  })
  if (queryStr.length > 1 && !api?.url?.includes(queryStr)) {
    api.url = `${api.url}${queryStr}`
  }
  return api
}
