<template>
  <div class="ui-mytable">
    <!-- 主table -->
    <el-table ref="myTable" 
      :data="tableData" 
      tooltip-effect="dark gles-table" 
      header-cell-class-name="ui-mytable__header" 
      v-bind="$attrs" 
      v-on="$listeners" 
      :border="border" 
      @cell-mouse-enter="showTooltip"
      @cell-mouse-leave="hiddenTooltip">
      <template #empty>
        <div class="tc mt30 mb30">
          <div class="mb5">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K"
            />
          </div>
          <p class="noData">{{ $t("libsSz.key45") }}</p>
        </div>
      </template>
      <!-- 选择框 -->
      <el-table-column v-if="extendConfig.checkBox" type="selection" :fixed="!extendConfig.isSelectionFixed" :selectable="_handleRowSelect" />
      <!-- 序列号 -->
      <el-table-column v-if="extendConfig.sortNum" type="index" align="center" :index="_calcNum" width="80px">
        <!-- v-bind="sourNumProp" -->
        <template #header>
          <el-popover v-if="extendConfig.openFilterItems" placement="right-start" trigger="click" popper-class="mTable__popover">
            <div class="el-table-filter__content">
              <el-scrollbar wrap-class="el-table-filter__wrap">
                <el-checkbox-group v-model="itemShowList" class="el-table-filter__checkbox-group">
                  <el-checkbox v-for="filter in tableItemOptions" :key="filter.value" :label="filter.value">
                    {{ filter.text }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-scrollbar>
              <!-- <el-divider class="divider"></el-divider> -->
              <!-- <div class="footer"> -->
              <!-- 确定 -->
              <!-- <el-button type="text" class="pt5 pb5" :disabled="!itemShowList.length" @click="handleTableItemSure">{{ $t('libsSz.key15') }}</el-button> -->
              <!-- 重置 -->
              <!-- <el-button type="text" class="pt5 pb5 fr" @click="handleTableItemReset">{{ $t('libsSz.key11') }}</el-button> -->
              <!-- </div> -->
            </div>
            <div slot="reference" class="el-button--text btn">
              {{ $t("libsSz.key12") }}
              <i class="el-icon-arrow-down f12" />
            </div>
          </el-popover>
          <div v-else>
            {{ $t("libsSz.key12") }}
          </div>
        </template>
      </el-table-column>
      <!-- 执行顺序, 注意 这里是对GLES的定制功能 -->
      <el-table-column v-if="extendConfig.executeSort" type="index" :label="$t('lang.gles.strategy.executeSort')" :index="_calcNum" width="90px">
      </el-table-column>
      <!-- 展开功能 -->
      <el-table-column v-if="extendConfig.expand" type="expand" :width="extendConfig.expandHidden ? 0 : '50px'">
        <template #default="scope">
          <div>
            <slot name="expand" v-bind="scope" />
          </div>
        </template>
      </el-table-column>
      <!-- 主题内容区 -->
      <el-table-column
        v-for="(item, index) in myTableItem"
        v-bind="item"
        :key="`${item.prop}-${index}`"
        :prop="item.prop"
        :label="item.label"
        :formatter="null"
        :render-header="_labelFunction"
      >
        <template slot-scope="scope">
          <template v-if="item.hasOwnProperty('slotName') && item.slotName">
            <!-- 支持自定义slot -->
            <slot :name="item.slotName" v-bind="scope" />
          </template>
          <template v-else>{{ _formatterCellValue(item, scope) }}</template>
        </template>
      </el-table-column>
      <!-- 操作按钮 -->
      <el-table-column v-if="extendConfig.operate && extendConfig.operate.length" :label="$t('libsSz.key13')" :width="extendConfig.operateWidth" fixed="right">
        <template slot-scope="scope">
          <template v-for="(item, index) in extendConfig.operate">
            <template v-if="item.slotName">
              <slot :name="item.slotName" v-bind="scope" :item="item"/>
            </template>
            <template v-else>
              <template v-if="item.confirm">
                <m-confirm-btn
                  v-if="!item.hasOwnProperty('condition') || item.condition(scope)"
                  :key="index"
                  :label="item.label || item.getLabel && item.getLabel(scope.row)"
                  :message="item.confirmMessage || item.getConfirmMessage && item.getConfirmMessage(scope.row)"
                  type="text"
                  :confirm-class="getConfirmClass(item, index)"
                  :disabled="item.getDisabled && item.getDisabled(scope.row)"
                  @sureClick="() => _operateClick(item.event, scope)"
                />
              </template>
              <template v-else>
                <el-button
                  v-if="!item.hasOwnProperty('condition') || item.condition(scope)"
                  :key="index"
                  type="text"
                  :class="getConfirmClass(item, index)"
                  :disabled="item.getDisabled && item.getDisabled(scope.row)"
                  @click="() => _operateClick(item.event, scope)"
                >
                  {{ item.label || item.getLabel && item.getLabel(scope.row) }}
                </el-button>
              </template>
            </template>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 追加table和pagination之前的插槽 -->
    <div>
      <slot name="tableAppend" />
    </div>
    <!-- 分页 -->
    <el-pagination
      v-if="mtablePageData"
      class="tr mt20"
      background
      :current-page="mtablePageData.currentPage"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      :page-size="mtablePageData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="mtablePageData.recordCount"
      @size-change="_handleSizeChange"
      @current-change="_handleCurrentChange"
    />
    <!-- tooltip -->
    <table-tooltip :table-cell-mouse="tableCellMouse" :id="Math.floor(Math.random() * 10)"/>
  </div>
</template>
<script>
/**

 * @prop   { tableItem: Array } 传入表头
 * @prop   { tableData: Array } 传入当前tableData
 * @prop   { extendConfig: Object } 配置当前默认
 * @prop   { pageData: Object } 页码改变的时候
 *
 * @event   { pageChange: Object } 页码改变的时候触发方法；
 *
 * @method  { getTable } table的
 *
 * @slot { expand } 用于扩展行；
 * @slot { tableAppend } 追加在table和pagination之间插槽
 *
 * 数据格式Demo：
 *      tableItem:[
          {
            prop:'',                                  // table的字段
            label:'',                                 // cell的标题
            slotName:'',                              // 插槽
            formatter(row, column, cellValue, index){ // 格式化内容，必须在获取tableDate之前获取；
              return '';
            }
            formatter:{type:'time'}                   // 会自动格式化时间
            isSelection: false,                       // 表示是否可以选择到checkbox
            ...                                       // 以及其他列支持的属性
          }
        ]

        operate:[                                     // 如果配置操作按钮
            {
              event:'',                               // 事件Name
              label:'',                               // 按钮显示Name
              condition:"",                           // 显示条件
              confirm: true,                          // 是否需要在确认
              confirmMessage: '',                     // 再确认提示信息
            }
        ]
 *
 * 版本：
 *      20191011 支持MPage嵌套
 *      20200415 支持配置动态表头
 *      20200416 表头不允许分行
 *
 * 功能：
 *      1. 支持checkbox
        2. 支持序号
        3. 支持cell的slot
 *
 */

import dayjs from "dayjs";
import emitter from "element-ui/src/mixins/emitter";
import MConfirmBtn from "../MConfirmBtn/MConfirmBtn";
import { sortBy, cloneDeep, map } from "lodash";
import ElScrollbar from "element-ui/lib/scrollbar";
import TableTooltip from './table-tooltip.vue'
export default {
  mixins: [emitter],
  name: "MTable",
  componentName: "MTable",
  inject: {
    rootPage: {
      default: null,
    },
  },
  props: {
    tableItem: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    extendConfig: {
      type: Object,
      default() {
        return {
          checkBox: false, // 是否checkbox
          sortNum: false, // 是否排序
          expand: false, // 配置是否带有扩展项
          expandHidden: true, // 是否需要隐藏expand的cell，通过操作按钮控制
          operateWidth: "auto", // 操作列的宽度
          operate: false, // 需要操作按钮
          openFilterItems: false, // 开启过滤表头
          customClass: '',   // 用户自定义class
          isSelectionFixed: false, // false 固定  true 非固定
          columnMinWidth: null // 表格统一默认最小列宽
        };
      },
    },
    pageData: {
      type: Object,
      default() {
        return {
          currentPage: 1,
          pageSize: 10,
          recordCount: 0,
        };
      },
    },
    /**
     * 是否需要纵向边框
     */
    border: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      itemNum: 5, // 默认显示tableItem个数；
      itemShowList: [], // 显示的表头字段
      tableCellMouse: {
        cellDom: null, // 鼠标移入的cell-dom
        hidden: null, // 是否移除单元格
        row: null // 行数据
      }
    };
  },
  computed: {
    mtablePageData() {
      if (this.rootPage) {
        return (this.rootPage.isPagination && this.rootPage.pageData) || null;
      } else {
        return this.pageData;
      }
    },
    myTableItem() {
      if (this.extendConfig.openFilterItems) {
        return this.tableItem.filter(i => this.itemShowList.includes(i.prop));
      }
      return this.tableItem;
    },
    tableItemOptions() {
      return this.tableItem.map(i => ({ text: i.label, value: i.prop }));
    },
  },
  created() {
    if (this.extendConfig.openFilterItems) this._getItemShowList();
  },
  methods: {
    // 鼠标移入cell
    showTooltip(row, column, cell) {
      if (!column.showOverflowTooltip) {
        return
      }
      this.tableCellMouse.cellDom = cell
      this.tableCellMouse.row = row
      this.tableCellMouse.hidden = false
    },

    // 鼠标移出cell
    hiddenTooltip() {
      this.tableCellMouse.hidden = true
    },
    _getItemShowList() {
      const sort = cloneDeep(this.tableItem);
      if (sort.length > 5) {
        const num = this.itemNum;
        this.itemShowList = map(sort.splice(0, num), "prop");
      } else {
        this.itemShowList = map(sort, "prop");
      }
    },
    // pageSize改变
    _handleSizeChange(pageSize) {
      const data = { currentPage: 1, pageSize: pageSize };
      this.$emit("pageChange", data);
      this.dispatch("MPage", "mpage:pageChange", data);
    },
    _handleCurrentChange(currentPage) {
      const data = {
        currentPage: currentPage,
        pageSize: this.mtablePageData.pageSize,
      };
      this.$emit("pageChange", data);
      this.dispatch("MPage", "mpage:pageChange", data);
    },
    // 选择当前select
    _handleRowSelect(row) {
      return row.hasOwnProperty("isSelection") ? row.isSelection : true;
    },
    // 格式化当前
    _formatterCellValue({ formatter = null, prop }, { row, $index }) {
      if (formatter) {
        // 存在formatter
        if (typeof formatter === "function") {
          return formatter(row, prop, row[prop], $index);
        } else if (typeof formatter === "object") {
          // Object的时候
          if (formatter.type === "time") {
            if (row[prop]) {
              const time = typeof row[prop] === "string" ? row[prop].replace(/( \d+\:\d+\:\d+)\:(\d+)/, "$1.$2") : row[prop];
              return dayjs(time).format("YYYY-MM-DD HH:mm:ss");
            }
            return row[prop] || "";
          }
        }
      }
      return row[prop] || "";
    },
    _calcNum(index) {
      if (this.mtablePageData) {
        const { currentPage, pageSize } = this.mtablePageData;
        return this.mtablePageData ? (currentPage - 1) * Number(pageSize) + (index + 1) : index + 1;
      } else {
        return index + 1;
      }
    },
    getTable() {
      return this.$refs.myTable;
    },
    // 操作按钮
    _operateClick(event, scope) {
      const cb = ({ renderTable }) => {
        if (this.rootPage && renderTable) this.dispatch("MPage", "mpage:renderTable");
      };
      this.$emit(event, scope, cb);
    },
    _computeWidth(str) {
      let width = 0;
      for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 255) {
          // 中文
          width += 16;
        } else {
          // 英文
          width += 8;
        }
      }
      if (this.extendConfig.columnMinWidth && width < this.extendConfig.columnMinWidth) {
        width = this.extendConfig.columnMinWidth
      }
      return width;
    },
    /**
     * minWidth 80 是element默认的值
     * @param {*} h 
     * @param {*} param1 
     */
    _labelFunction(h, { column, $index }) {
      const bufferWidth = column.sortable ? 40 : 20
      const minWidth = column.minWidth !== 80 ? column.minWidth : null
      column.minWidth = minWidth || this._computeWidth(column.label) + bufferWidth;
      return h("div", { class: "table-head", style: { width: "100%" } }, [column.label]);
    },
    getConfirmClass(item, index) {
      const mr = index === this.extendConfig.operate?.length - 1 ? '' : 'mr10'
      return `${mr} ml0 f12 ${item.customClass || ''}`
    }
  },
  components: {
    MConfirmBtn,
    ElScrollbar,
    TableTooltip
  },
};
</script>
<style lang="scss" scoped>
.noData {
  color: rgba(0, 0, 0, 0.45);
}
.ml0 {
  margin-left: 0 !important;
}
.f12 {
  font-size: 12px;
}
.btn {
  cursor: pointer;
}
.footer {
  padding: 0 10px;
}
.pt5 {
  padding-top: 5px;
}
.pb5 {
  padding-bottom: 5px;
}
.fr {
  float: right;
}

.divider {
  margin: 5px 0 !important;
}

.ui-mytable .header {
  white-space: nowrap;
  min-width: 80px;
  > i {
    font-size: 12px;
  }
}
</style>
<style lang="scss">
.ui-mytable .ui-mytable__header {
  padding: 12px 0 !important;
  font-size: 14px !important;
  background: #fafafa;
}
.mTable__popover {
  padding: 10px 0;
}
// tooltip
body > .el-tooltip__popper.gles-table {
  display: none;
}
</style>
