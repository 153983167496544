<template>
  <m-dialog
    :visible="visible"
    :width="'40%'"
    :is-need-footer="false"
    @closed="closed"
  >
    <!-- 标题 -->
    <template #title>
      <span class="f16">{{ title || $t("libsSz.key69") }}</span>
    </template>
    <div class="ui-mexport">
      <m-transition mode="out-in">
        <div v-if="!isError && !isWarn" class="contentBox">
          <el-upload v-bind="extendAttribe" ref="myUpload">
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              {{ $t("libsSz.key70") }}
              <em>{{ $t("libsSz.key71") }}</em>
            </div>
            <div slot="tip" class="el-upload__tip">
              <slot name="tip">
                <p>{{ tips }}</p>
              </slot>
            </div>
          </el-upload>
          <div class="footer">
            <el-button
              class="w140"
              type="primary"
              :loading="uploadLoading"
              @click="_doSubmit"
            >
              {{ $t(`libsSz.${uploadLoading ? "key72" : "key73"}`) }}
            </el-button>
          </div>
        </div>
        <div v-else-if="isWarn">
          <div class="warnBox">
            <slot name="warn" />
          </div>
        </div>
        <div v-else>
          <div class="errorBox">
            <slot name="error">
              <div class="ui-mexport__error tc">
                <p class="content">
                  <i class="el-icon-error  f28 warn vm" />
                  <span>{{ errorMsg }}</span>
                </p>
                <div class="footer mt20 mb20">
                  <el-button class="w140" type="primary" @click="reset()">
                    {{ $t("libsSz.key74") }}
                  </el-button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </m-transition>
    </div>
  </m-dialog>
</template>
<script>
/**
 *  导入功能
 *
 *  1. 保持element uploader原有属性；
 *  2. 扩展了符合极智嘉上传的交互。
 *
 *  attribute
 * @props { String } title
 *  @props { Boolean } visible
 *  @props { String } tips
 *  @props { Number } maxLength  单位kb
 *  @props { Function } warnFn
 *
 *  methods
 *  @method {*}  closed 关闭弹框
 *  @method {*}  reset 关闭弹框
 *
 *  event
 *  @event {*} save
 *  @event {*} closed
 *
 *  slot
 *
 *  error
 *  warn
 *
 * */

import MDialog from "../MDialog/MDialog";
import MTransition from "../MTransition/MTransition";

export default {
  name: "MExportDialog",
  components: { MDialog, MTransition },
  props: {
    title: String,
    visible: Boolean,
    tips: String,
    maxLength: Number,
    warnFn: Function,
  },
  data() {
    return {
      isError: false,
      isWarn: false,
      errorMsg: "",
      uploadLoading: false,
    };
  },
  computed: {
    extendAttribe() {
      return {
        ...this.$attrs,
        drag: true,
        "before-upload": this._beforeUpload,
        "auto-upload": false,
        "on-success": this._handleUploadSuccess,
        "on-error": this._handleUploadError,
        "on-exceed": this._handleExceed,
      };
    },
  },
  methods: {
    // 关闭
    closed() {
      this.$emit("update:visible", false);
      this.$emit("closed");
    },
    reset() {
      this.isError = false;
      this.isWarn = false;
      this.$refs.myUpload && this.$refs.myUpload.clearFiles();
    },
    _beforeUpload(file) {
      this.uploadLoading = true;
      if (!file) {
        this.uploadLoading = false;
        this.$message.error(this.$t("libsSz.key75"));
        return Promise.reject();
      }
      if (this.maxLength) {
        const isLimit = file.size / 1024 / this.maxLength < 1;
        if (!isLimit) {
          const tips = this.maxLength < 1024 ? `${this.maxLength}kb` : `${this.maxLength / 1024}Mb`;
          this.$message.error(this.$t("libsSz.key76", [tips]));
          this.uploadLoading = false;
          return Promise.reject();
        }
      }
      return this.$attrs["before-upload"] ? this.$attrs["before-upload"](file) : true;
    },
    _doSubmit() {
      this.$refs.myUpload.submit();
    },
    _handleUploadSuccess(respone) {
      this.uploadLoading = false;
      if (!respone.code) {
        // eslint-disable-next-line prefer-rest-params
        this.$attrs["on-success"] && this.$attrs["on-success"](...arguments);
        if (this.warnFn) {
          this.isWarn = this.warnFn(respone);
          if (!this.isWarn) this.closed();
        } else {
          this.closed();
        }
        this.$emit("save");
      } else {
        // eslint-disable-next-line prefer-rest-params
        this._handleUploadError(...arguments);
      }
    },
    _handleUploadError(respone) {
      this.uploadLoading = false;
      this.isError = true;
      this.errorMsg = respone ? this.$t(respone.msg) : this.$t("libsSz.key77");
      // eslint-disable-next-line prefer-rest-params
      this.$attrs["on-error"] && this.$attrs["on-error"](...arguments);
    },
    _handleExceed() {
      this.$message.error(this.$t("libsSz.key77", [this.$attrs.limit]));
    },
  },
};
</script>
<style lang="scss" scoped>
.ui-mexport {
  .contentBox {
    width: 362px;
    margin: 0 auto;
  }
  .footer {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
