import { getSelectOptions } from '@/utils/getDictPramas'
export default {
  namespaced: true,
  state: {
    targetRangeList: [],
    targetTypeList: [],
    distributeRuleList: [],
    priorityRuleList: [],
    hitDistributeRuleList: [],
    turnoverRuleList: [],
    robotTypeList: [],
    robotModelList: [],
    taskTypeList: [],
    hitOnTheWayDistributeRuleList: [],
    locationTypeList: [],
    sourceConditionList: [],
    operatorList: [],
    // 等级开启
    enableMaterialGradeList: [],
    // 等级来源
    materialGradeSourceList: [
      { label: '手动设置', value: 1 },
      { label: '上游系统指定', value: 2 }
    ]
  },
  getters: {},
  actions: {
    /**
     * 获取目标范围
     */
    async getTargetRangeList({ commit }, dictCode = 'strategyTargetRange') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { targetRangeList: list })
      )
    },
    /**
     * 获取目标类型
     */
    async getTargetTypeList({ commit }, dictCode = 'strategyTargetType') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { targetTypeList: list })
      )
    },
    /**
     * 获取分配规则
     */
    async getDistributeRuleList({ commit }, dictCode = 'distributeRule') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { distributeRuleList: list })
      )
    },
    /**
     * 获取命中策略分配规则
     */
    async getHitDistributeRuleList({ commit }, dictCode = 'hitDistributeRule') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { hitDistributeRuleList: list })
      )
    },
    /**
     * 获取优先规则
     */
    async getPriorityRuleList({ commit }, dictCode = 'priorityRule') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { priorityRuleList: list })
      )
    },
    /**
     * 获取周转规则
     */
    async getTurnOverRuleList({ commit }, dictCode = 'turnoverRule') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { turnoverRuleList: list })
      )
    },
    /**
     * 获取机器人系列
     */
    async getRobotTypeList({ commit }, dictCode = 'robotType') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { robotTypeList: list })
      )
    },
    /**
     * 获取机器人型号
     */
    async getRobotModelList({ commit }, dictCode = 'robotModel') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { robotModelList: list })
      )
    },
    /**
     * 获取任务类型
     */
    async getTaskTypeList({ commit }, dictCode = 'subTaskType') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { taskTypeList: list })
      )
    },
    /**
     * 获取 ---在途库存 分配规则
     */
    async getOnTheWayDistributeRuleList({ commit }, dictCode = 'hitOnTheWayDistributeRule') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { hitOnTheWayDistributeRuleList: list })
      )
    },
    /**
     * 获取位置类型
     */
    async getLocationTypeList({ commit }, dictCode = 'locationType') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { locationTypeList: list })
      )
    },
    /**
     * 获取来源条件
     */
    async getSourceConditionList({ commit }, dictCode = 'sourceCondition') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { sourceConditionList: list })
      )
    },
    /**
   * 获取运算符列表
   */
    async getOperatorList({ commit }, dictCode = 'operator') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { operatorList: list })
      )
    },
    /**
     * 获取等级开启列表
     */
    async getEnableMaterialGradeList({ commit }, dictCode = 'enableMaterialGrade') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { enableMaterialGradeList: list })
      )
    },
    /**
     * 获取等级来源列表
     */
    async getMaterialGradeSourceList({ commit }, dictCode = 'materialGradeSource') {
      getSelectOptions('queryDictByCode', { dictCode }).then((list) =>
        commit('setCommonList', { materialGradeSourceList: list })
      )
    }
  },
  mutations: {
    setCommonList(state, payLoad) {
      const keys = Object.keys(payLoad)
      keys.map((item) => {
        state[item] = payLoad[item]
        return true
      })
    }
  }
}

